import { getBaseUrl } from '@/config/BaseUrl';

export default async function GetAllSeoDetailsApi(url: string) {
  try {
    const requestBody = {
      url: url,
    };
    const baseUrl = getBaseUrl();
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(`${baseUrl}web/get-by-url`, requestOptions);

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message);
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error(`Error getting SEO details for this ${url}:`, error);
    throw error;
  }
}
