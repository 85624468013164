import { Link } from 'react-router-dom';
import Email from '@/assets/icons/Email.png';
import Phone from '@/assets/icons/Phone.png';

interface MobileLocumOfferProps {
  description1?: string;
  description2?: string;
  description3?: string;
}

export default function MobileLocumOffer({
  description1,
  description2,
  description3,
}: MobileLocumOfferProps) {
  const GetTextColor = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return 'text-gold-gradient';
    } else {
      return 'text-silver-gradient';
    }
  };

  return (
    <div className="py-10">
      <h1 className="font-sans font-normal text-[26px] leading-[34px] text-blue-420 mb-4">
        Discover What <span className={`${GetTextColor()}`}>We Offer</span>
      </h1>
      <div className="font-sans font-normal text-base leading-[22px] text-blue-440">
        <p className="mb-4">{description1}</p>
        <p className="mb-4">{description2}</p>
        <p>{description3}</p>
      </div>
      <div className="flex justify-center mt-20 mb-8">
        <div className="flex items-center justify-around border-color rounded-3xl w-[376px] h-[254px] relative shadow">
          <div className="absolute top-[-19px] font-bold text-blue-200 left-6 text-[26px] leading-[33px] bg-white px-2">
            Reach Us
          </div>
          <div className="grid grid-cols-1 w-full ms-6 gap-y-10">
            <div>
              <h6 className="text-blue-30">Email Us</h6>
              <div className="flex items-center justify-normal mt-2">
                <img
                  src={Email}
                  className="w-[27px] h-[27px] object-cover mr-2"
                />
                <Link to={`mailto:premier@medfuture.com.au`}>
                  premier@medfuture.com.au
                </Link>
              </div>
            </div>
            <div>
              <h6 className="text-blue-30">Call Us</h6>
              <div className="flex items-center justify-normal mt-2">
                <img
                  src={Phone}
                  className="w-[27px] h-[27px] object-cover mr-2"
                />
                <Link to={`tel:1300 444 419`}>1300 444 419</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
