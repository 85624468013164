import { getBaseUrl } from '@/config/BaseUrl';
import { COUNTRY_CODE } from '@/constants/Constant';

export default async function GetMajorCitiesApi() {
  const baseUrl = getBaseUrl();
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  };

  const response = await fetch(
    `${baseUrl}web/getKeyCity/${COUNTRY_CODE}`,
    requestOptions
  );
  const responseData = await response.json();

  if (!response.ok) {
    throw { status: response.status, data: responseData };
  }

  return responseData;
}
