import GetAllSpecialtyByProfession from '@/api/GetSpecialtyProfessionWise';
import { Specialty } from '@/types/FetchDataTypes';
import { useEffect, useState } from 'react';

interface FetchAllSpecialty {
  specialtiesProfessionWise: Specialty[] | null;
  specialtyLoading: boolean;
  error: Error | null;
}
interface ParameterProps {
  profession_id: number | string;
}

export default function useFetchSpecialtyProfessionWise(
  profession_id: ParameterProps
): FetchAllSpecialty {
  const [specialtiesProfessionWise, setSpecialtiesProfessionWise] = useState<
    Specialty[] | null
  >(null);
  const [specialtyLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    if (isNaN(profession_id)) {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const response = await GetAllSpecialtyByProfession(profession_id);
      setSpecialtiesProfessionWise(response.data[0].specilities);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [profession_id]);

  return { specialtiesProfessionWise, specialtyLoading, error };
}
