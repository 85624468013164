import {
  CommonMobileHero,
  GratitudeComponent,
  HeroBackground,
  MainJobCard,
  MobileJobSection,
} from '@/components';
import { MobileLayout, PageLayout } from '@/layout';
import { useState } from 'react';
import useResponsive from '@/hooks/useResponsive';
import { useNavigate } from 'react-router-dom';
import useFetchAllProfessions from '@/components/forms/selectors/hook/useFetchAllProfessions';
import useFetchAllState from '@/components/forms/selectors/hook/useFetchAllStates';
import PermanentJobFilter from '@/components/filter/PermanentJobFilter';
import LocumJobFilter from '@/components/filter/LocumJobFilter';
import NoJobsCard from '@/components/noData/NoJobsCard';
import LoadingComponent from '@/components/noData/LoadComponent';
import { formatNameForURL } from '@/utils/formatNameForURL';
import useFetchAllStateDetailsByCountry from '@/hooks/useFetchAllStateDetailsByCountry';
import useFetchSpecialtyProfessionWise from '@/components/forms/selectors/hook/useFetchSpecialtyProfessionWise';
import useFetchSeniorityProfessionWise from '@/components/forms/selectors/hook/useFetchAllSeniorityProfessionWise';
import useFetchFilterData from '@/hooks/useFetchFilterMenuData';
import PageLoadingComponent from '@/components/noData/PageLoadingComponent';
import useFetchFilterAllJobsDataDetails from '@/hooks/useFetchFilterAllJobsData';
import ShareModal from '@/components/models/ShareModal';
import NewCardComponent from '@/components/home/newBanner/NewCardComponent';

export default function MegaFilterPage() {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { allProfessionsData, professionDataLoading } =
    useFetchAllProfessions();
  const { allStates } = useFetchAllState();
  const { allStatesDetails, allStateLoading } =
    useFetchAllStateDetailsByCountry();
  const [professionId, setProfessionId] = useState<number>();
  const [specialtyId, setSpecialtyId] = useState<number>();
  const [seniorityId, setSeniorityId] = useState<number>();
  const [stateId, setStateId] = useState<number>();
  const [suburbId, setSuburbId] = useState<number>();
  const [divisionId, setDivisionId] = useState<number>();
  const [regionId, setRegionId] = useState<number>();
  const { filterData, FilterDataLoading, FilterDataError } =
    useFetchFilterAllJobsDataDetails({
      divisionId,
      professionId,
      stateId,
      specialtyId,
      seniorityId,
      suburbId,
      regionId,
    });

  const [visibleJobs, setVisibleJobs] = useState<number>(6);
  const [selectedTopic, setSelectedTopic] = useState('Permanent');
  const { filterMenuData } = useFetchFilterData();
  const [jobUrl, setJobUrl] = useState<string>('');
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const { specialtiesProfessionWise, specialtyLoading } =
    useFetchSpecialtyProfessionWise(professionId);
  const { senioritiesProfessionWise, seniorityLoading } =
    useFetchSeniorityProfessionWise(professionId);
  const handleShareClick = (url: string) => {
    setJobUrl(url);
    setIsShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setIsShareModalOpen(false);
  };

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
  };

  const handleProfessionChange = (professionId) => {
    setProfessionId(professionId);
  };

  const handleStateChange = (stateId) => {
    setStateId(stateId);
  };

  const handleSpecialtyChange = (specialtyId) => {
    setSpecialtyId(specialtyId);
  };

  const handleSeniorityChange = (seniorityId) => {
    setSeniorityId(seniorityId);
  };

  const handleRegionChange = (regionId) => {
    setRegionId(regionId);
  };

  const handleSuburbChange = (suburbId) => {
    setSuburbId(suburbId);
  };

  const handleDivisionChange = (divisionId) => {
    setDivisionId(divisionId);
  };

  const specialtyName = specialtiesProfessionWise?.find(
    (specialty) => specialty.specialities_id === specialtyId
  )?.name;

  const seniorityName = senioritiesProfessionWise?.find(
    (seniority) => seniority.seniorities_id === seniorityId
  )?.name;

  const updateUrl = (
    professionId,
    stateId,
    specialtyId,
    seniorityId,
    topic
  ) => {
    const professionName = allProfessionsData?.find(
      (p) => p.profession_id === professionId
    )?.name;
    const stateName = allStates?.find((s) => s.state_id === stateId)?.name;
    let formattedName;
    let path = `/${topic.toLowerCase()}-jobs`;

    if (stateName) {
      path += `/${formatNameForURL(stateName)}`;
    }

    if (professionName) {
      formattedName = formatNameForURL(professionName);
    }

    if (specialtyName) {
      formattedName = formatNameForURL(specialtyName);
    }

    if (seniorityName) {
      formattedName = formatNameForURL(seniorityName);
    }

    if (formattedName) {
      path += `/${formattedName}-jobs`;
    }

    navigate(path);
  };

  const filteredData = filterData?.filter((job) => {
    if (selectedTopic === 'Permanent') {
      return job.is_permanent === '1';
    } else {
      return job.is_permanent !== '1';
    }
  });

  if (professionDataLoading || allStateLoading) {
    return <PageLoadingComponent />;
  }

  if (FilterDataError) {
    return <div>Error: {FilterDataError.message}</div>;
  }

  const handleLoadMore = () => {
    setVisibleJobs((prevCount) => prevCount + 3);
  };

  const displayedJobs = filteredData?.slice(0, visibleJobs) || [];

  return (
    <>
      {isMobile ? (
        <>
          <CommonMobileHero
            MainTitle={'Elevate your medical career to unprecedented levels.'}
            SubTitle={'All Jobs'}
            Paragraph={
              'Navigate your way to a fulfilling medical career with Medfuture. Our specialized recruitment approach connects you with opportunities in your preferred location across New Zealand. Embrace a career path that aligns with your aspirations, supported by our commitment to excellence in medical recruitment.'
            }
          />
          <MobileLayout>
            <MobileJobSection />
            {/* <div className="flex flex-col gap-y-6">
                {data.slice(0, visibleJobs).map((job, index) => (
                  <div key={index}>
                    {!screenSize.md ? (
                      <>
                        <MainMobileJobCard data={job} />
                      </>
                    ) : (
                      <>
                        <div className="grid grid-cols-2 gap-5">
                          <MainMobileJobCard data={job} />
                          {index < data.length - 1 && (
                            <MainMobileJobCard data={data[index + 1]} />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div> */}
            {/* <div className="text-center mt-10 mb-10">
                {data.length > visibleJobs && (
                  <button
                    onClick={handleLoadMore}
                    className="text-blue-390 text-center text-[20px] leading-[23px]"
                  >
                    Load More...
                  </button>
                )}
              </div> */}
          </MobileLayout>
        </>
      ) : (
        <>
          <HeroBackground
            title={'Elevate your medical career to unprecedented levels.'}
            description={`all jobs in New Zealand`}
            icon={''}
          />
          <PageLayout>
            <div className="px-28">
              <div className="text-center font-sans font-normal text-[33px] lg:text-[24px] leading-[48px] lg:leading-[36px] text-blue-350 padding-container">
                Navigate your way to a fulfilling medical career with Medfuture.
                Our specialized recruitment approach connects you with
                opportunities in your preferred location across New Zealand.
                Embrace a career path that aligns with your aspirations,
                supported by our commitment to excellence in medical
                recruitment.
              </div>
            </div>
            <div className="flex items-start justify-between gap-x-5 padding-container">
              <>
                <div className="">
                  <div className="">
                    <div className="w-[370px] lg:w-[480px] flex items-center shadow-md font-sans text-[20px] leading-[23px] lg:text-[26px] lg:leading-[32px] font-normal">
                      <h2
                        className={`w-1/2 text-center py-[14px] lg:py-5 ${
                          selectedTopic === 'Permanent'
                            ? 'bg-white'
                            : 'bg-gray-420'
                        } cursor-pointer`}
                        onClick={() => handleTopicClick('Permanent')}
                      >
                        Permanent
                      </h2>
                      <h2
                        className={`w-1/2 text-center py-[14px] lg:py-5 ${
                          selectedTopic === 'Locum' ? 'bg-white' : 'bg-gray-420'
                        } cursor-pointer`}
                        onClick={() => handleTopicClick('Locum')}
                      >
                        Locum
                      </h2>
                    </div>
                    <div className="flex items-center justify-start">
                      {selectedTopic === 'Permanent' ? (
                        <PermanentJobFilter
                          data={filterMenuData}
                          allStates={allStatesDetails}
                          professionId={professionId}
                          stateId={stateId}
                          specialtyId={specialtyId}
                          seniorityId={seniorityId}
                          onProfessionChange={handleProfessionChange}
                          onSpecialtyChange={handleSpecialtyChange}
                          onSeniorityChange={handleSeniorityChange}
                          onStateChange={handleStateChange}
                          onSuburbSelect={handleSuburbChange}
                          suburbId={suburbId}
                          regionId={regionId}
                          divisionId={divisionId}
                          onDivisionChange={handleDivisionChange}
                          onRegionChange={handleRegionChange}
                        />
                      ) : (
                        <LocumJobFilter
                          data={filterMenuData}
                          allStates={allStatesDetails}
                          professionId={professionId}
                          stateId={stateId}
                          specialtyId={specialtyId}
                          seniorityId={seniorityId}
                          onProfessionChange={handleProfessionChange}
                          onSpecialtyChange={handleSpecialtyChange}
                          onSeniorityChange={handleSeniorityChange}
                          onStateChange={handleStateChange}
                          onSuburbSelect={handleSuburbChange}
                          suburbId={suburbId}
                          regionId={regionId}
                          divisionId={divisionId}
                          onDivisionChange={handleDivisionChange}
                          onRegionChange={handleRegionChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
              <div>
                {FilterDataLoading ? (
                  <div className="flex items-center justify-center">
                    <LoadingComponent />
                  </div>
                ) : (
                  <div className="flex items-start justify-between gap-x-5">
                    <div>
                      <div className="">
                        {filteredData?.length === 0 ? (
                          <div className="">
                            <NoJobsCard />
                          </div>
                        ) : (
                          <div className="flex flex-col gap-y-6">
                            {displayedJobs.map((job, index) => (
                              <div key={index}>
                                <MainJobCard
                                  data={job}
                                  onShareClick={handleShareClick}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="text-center mt-10">
                        {filteredData?.length > visibleJobs && (
                          <button
                            onClick={handleLoadMore}
                            className="text-blue-390 text-center text-[20px] leading-[23px]"
                          >
                            Load More...
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <Register /> */}
            {/* <DropDownSection /> */}
          </PageLayout>
          <PageLayout>
            <GratitudeComponent
              mainTitle="Refer and Earn"
              subTitle="2500 AUD$"
            />
            <NewCardComponent />
          </PageLayout>
          {/* <PageLayout>
            <InsightComponent />
          </PageLayout> */}
        </>
      )}
      <div className={`modal ${isShareModalOpen ? 'modal-open' : ''}`}>
        <ShareModal
          isOpen={isShareModalOpen}
          onClose={handleCloseShareModal}
          jobUrl={jobUrl}
        />
      </div>
    </>
  );
}
