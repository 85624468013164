import CountUp from 'react-countup';
import HeroLogo from '/images/Rectangle_925.png';
import {
  CANDIDATE_COUNT,
  CANDIDATE_COUNT_TIME,
  CLIENT_COUNT,
  CLIENT_COUNT_TIME,
  JOBS_COUNT,
  JOBS_COUNT_TIME,
  YEAR_COUNT,
  YEAR_COUNT_TIME,
} from '@/constants/Constant';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';

export default function MobileDescription() {
  return (
    <section className="mobile-padding-container">
      <div className="flex items-center justify-around">
        <div className="grid grid-cols-2 gap-x-10 md:gap-x-40 gap-y-10 text-blue-500">
          <div className="mobile-des-animation">
            <h2 className="mobile-des-main">
              <CountUp end={YEAR_COUNT} duration={YEAR_COUNT_TIME} />+
            </h2>
            <h4 className="mobile-dec-sub">Years</h4>
          </div>
          <div className="mobile-des-animation">
            <h2 className="mobile-des-main">
              <CountUp end={CLIENT_COUNT} duration={CLIENT_COUNT_TIME} />
            </h2>
            <h4 className="mobile-dec-sub">Clients</h4>
          </div>
          <div className="mobile-des-animation">
            <h2 className="mobile-des-main">
              <CountUp end={JOBS_COUNT} duration={JOBS_COUNT_TIME} />
            </h2>
            <h4 className="mobile-dec-sub">Jobs</h4>
          </div>
          <div className="mobile-des-animation">
            <h2 className="mobile-des-main">
              <CountUp end={CANDIDATE_COUNT} duration={CANDIDATE_COUNT_TIME} />
            </h2>
            <h4 className="mobile-dec-sub">Candidates</h4>
          </div>
        </div>
        <div className="hero-img-container">
          <img
            src={`${AWS_BASE_URL_ASSETS}/award.png`}
            width={260}
            height={220}
            alt="hero"
            loading="eager"
            className="rounded-full object-cover object-center"
          />
        </div>
      </div>
    </section>
  );
}
