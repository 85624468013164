export default function JobTypePreferences({ data, selectedTopic }) {
  console.log(data);
  return (
    <div className="my-5">
      <div className="grid grid-cols-2 2xl:grid-cols-4 gap-2 2xl:gap-4 mt-10">
        <div className="p-2 2xl:p-4 rounded-lg cursor-pointer">
          <p className="preferences-text-main">Permanent</p>
          <p className="preferences-text-sub">{data?.permanent_jobs}</p>
        </div>
        <div className="p-2 2xl:p-4 rounded-lg cursor-pointer">
          <p className="preferences-text-main">Locum</p>
          <p className="preferences-text-sub">{data?.non_permanent_jobs}</p>
        </div>
      </div>
    </div>
  );
}
