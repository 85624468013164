import { MobileLayout, PageLayout } from '@/layout';
import {
  Banner,
  Blogs,
  GratitudeComponent,
  Hero,
  JobsByMajorCities,
  Locum,
  MajorCities,
  MobileBlogs,
  MobileDescription,
  MobileDropDown,
  MobileHero,
  MobileLocum,
  MobileOffer,
  MobilePreferences,
  MobileRegister,
  MobileSpecialty,
  Offer,
  Preferences,
  Register,
  Services,
  Specialty,
} from '@/components';
import useResponsive from '@/hooks/useResponsive';
import { useStateContext } from '@/context/ContextProvider';
import useFetchJobCountByState from '@/hooks/useFetchJobCountByState';
import PageLoadingComponent from '@/components/noData/PageLoadingComponent';
import useFetchJobCountBySpecialty from '@/hooks/useFetchJobsCountBySpecialty';
import useFetchBottomMenuData from '@/components/navbar/hook/useBottomNavBar';
import useFetchJobCountForHomeByState from '@/components/home/preferences/hook/GetJobCountByStateApi';
import useFetchJobCountByDivision from '@/components/home/preferences/hook/GetJobCountByDivision';
import useFetchJobCountByJobType from '@/components/home/preferences/hook/GetJobCountByJobType';
import { useEffect } from 'react';
import useFetchAllSeoDetails from '@/hooks/useFetchAllSeoDetails';
import { getProductionUrl } from '@/config/getProductionUrl';
import SeoOptimized from '@/components/reactHelmet/SeoOptimized';

export default function Home() {
  const { isMobile } = useResponsive();
  const { allJobData, loadingJobs } = useStateContext();
  const { jobCountBySuberbs, jobCountLoading } = useFetchJobCountByState();
  const { jobCountBySpecilaty, jobCountSpecialtyLoading } =
    useFetchJobCountBySpecialty();
  const { bottomMenuData } = useFetchBottomMenuData();
  const { jobCountByState } = useFetchJobCountForHomeByState();
  const { jobCountByDivision } = useFetchJobCountByDivision();
  const { jobCountByJobType } = useFetchJobCountByJobType();
  const sortedStates = jobCountBySuberbs?.sort(
    (a, b) => parseInt(a.state_position) - parseInt(b.state_position)
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (jobCountLoading || jobCountSpecialtyLoading || loadingJobs) {
    return <PageLoadingComponent />;
  }

  return (
    <>
      {isMobile ? (
        <>
          <MobileHero />
          <MobileLayout>
            <Services />
            <MobileDropDown bottomMenuData={bottomMenuData} />
            <MobileDescription />
            <MobileOffer data={allJobData?.data} />
            <JobsByMajorCities jobCountBySuberbs={jobCountBySuberbs} />
            <MobileSpecialty jobCountBySpecilaty={jobCountBySpecilaty} />
            <MobileRegister />
            <MobileLocum data={allJobData?.data} />
            <MobileBlogs />
            <MobilePreferences
              jobCountByState={jobCountByState}
              jobCountByDivision={jobCountByDivision}
              jobCountByJobType={jobCountByJobType}
            />
          </MobileLayout>
        </>
      ) : (
        <>
          <Hero />
          <PageLayout>
            <Banner />
            <Offer data={allJobData?.data} />
            <MajorCities jobCountBySuberbs={sortedStates} />
            <Specialty jobCountBySpecilaty={jobCountBySpecilaty} />
            <Locum data={allJobData?.data} />
            {/* <Register /> */}
            <GratitudeComponent />
            <Blogs />
            <Preferences
              jobCountByState={jobCountByState}
              jobCountByDivision={jobCountByDivision}
              jobCountByJobType={jobCountByJobType}
            />
          </PageLayout>
        </>
      )}
    </>
  );
}
