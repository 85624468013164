import { LogoProps } from '@/types/types';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';

export default function LogoCommon({ height, width, handleClick }: LogoProps) {
  return (
    <header>
      <div className="w-[300px] h-[80px]">
        <img
          src={`${AWS_BASE_URL_ASSETS}/Medfuture-logo-blue.png`}
          height={height}
          width={width}
          loading="lazy"
          alt="Logo"
          className="cursor-pointer object-contain h-full w-full object-center"
          onClick={handleClick}
        />
      </div>
    </header>
  );
}
