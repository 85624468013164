import GetFilterDataApi from '@/api/GetAllFilterMenuApi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface StateData {
  divisions_id: number;
  country_id: number;
  name: string;
  description: string;
  status: number;
  image: string;
  image_alt: string;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

interface FetchAllBottomMenuData {
  filterMenuData: StateData[] | null;
  loading: boolean;
  error: Error | null;
}

export default function useFetchFilterData(): FetchAllBottomMenuData {
  const navigate = useNavigate();
  const [filterMenuData, setFilterMenuData] = useState<StateData[] | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetFilterDataApi();
      setFilterMenuData(response);
    } catch (error: any) {
      if (error.status === 429) {
        navigate('/many-requests', {
          state: { retryAfter: error.data.retry_after },
        });
      } else {
        setError(error as Error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { filterMenuData, loading, error };
}
