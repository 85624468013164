import { ProfessionSelector } from '@/components/forms/selectors/ProfessionSelector';
import { StateSelector } from '@/components/forms/selectors/StateSelector';
import Button from '@/components/shared/button/Button';
import PopupNotification from '@/components/shared/notification/PopupNotification';
import { verifyEmailFormat } from '@/utils/EmailVerification';
import { validatePassword } from '@/utils/PasswordValidationUtil';
import { isPhoneValid } from '@/utils/PhoneNumberVerification';
import { useEffect, useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import { Link } from 'react-router-dom';
import 'react-international-phone/style.css';
import { CountrySelector } from '@/components/forms/selectors/CountrySelector';
import { SocialMediaSelector } from '@/components/forms/selectors/SocialMediaSelector';
import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import pdfIcon from '/icons/pdfIcon.png';
import wordIcon from '/icons/wordIcon.png';
import { getBaseUrl } from '@/config/BaseUrl';
import { confirmPassword } from '@/utils/confirmPasswordUtil';
import CandidateRegistrationApi from '@/api/Registration/CandidateRegistrationApi';

export default function MobileRegister() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    country: '',
    phoneNumber: '',
    state: '',
    profession: '',
    social: '',
    cv: '',
    email: '',
    password: '',
    confirmPassword: '',
    agree: false,
    subscribe: false,
  });
  const [submit, setSubmit] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    country: '',
    phoneNumber: '',
    state: '',
    profession: '',
    social: '',
    cv: '',
    email: '',
    fileUpload: '',
    password: '',
    confirmPassword: '',
    agree: false,
  });
  const [file, setFile] = useState<File | null>(null);
  const [notification, setNotification] = useState({
    show: false,
    title: '',
    message: '',
    type: 'success' as 'success' | 'error',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (e: { target: { id: string; value: string } }) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const useUploadFile = e.target.files[0];
      const allowedFileTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];

      if (!allowedFileTypes.includes(useUploadFile.type)) {
        setFormErrors({
          ...formErrors,
          fileUpload: 'Please upload your CV in PDF or Word format',
        });
        setIsLoading(false);
        setFile(null);
      } else {
        setFormErrors({
          ...formErrors,
          fileUpload: '',
        });
        setFile(e.target.files[0]);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const getIconForFileType = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (extension === 'pdf') {
      return (
        <img src={pdfIcon} alt="PDF Icon" className="w-[30px] h-[30px] mr-2" />
      );
    } else if (['txt', 'doc', 'docx'].includes(extension || '')) {
      return (
        <img
          src={wordIcon}
          alt="Text Icon"
          className="w-[30px] h-[30px] mr-2"
        />
      );
    } else {
      return null;
    }
  };

  const allErrors =
    formErrors.firstName ||
    formErrors.lastName ||
    formErrors.country ||
    formErrors.phoneNumber ||
    formErrors.state ||
    formErrors.profession ||
    formErrors.email ||
    formErrors.fileUpload ||
    formErrors.social ||
    formErrors.password ||
    formErrors.confirmPassword ||
    formErrors.agree;

  const validateForm = () => {
    const newErrors = {
      firstName: !formData.firstName ? 'Please enter your first name' : '',
      lastName: !formData.lastName ? 'Please enter your last name' : '',
      email: !formData.email
        ? 'Please enter your email address'
        : !verifyEmailFormat(formData.email)
          ? 'Please enter a valid email address'
          : '',
      profession: !formData.profession ? 'Please select profession' : '',
      state: !formData.state ? 'Please select state' : '',
      country: !formData.country ? 'Please select a Country' : '',
      phoneNumber: !formData.phoneNumber
        ? 'Please enter your Phone Number'
        : !isPhoneValid(formData.phoneNumber)
          ? 'Please enter a valid Phone Number'
          : '',
      fileUpload: !file ? 'Please upload your CV here' : '',
      social: !formData.social ? 'Please give your response' : '',
      agree: !formData.agree
        ? 'You must agree to the Terms and Conditions and Privacy Policy'
        : '',
      password: validatePassword(formData.password),
      confirmPassword: confirmPassword(
        formData.password,
        formData.confirmPassword
      ),
    };

    setFormErrors(newErrors);
    setIsLoading(false);
  };

  useEffect(() => {
    if (click) {
      validateForm();
    }
  }, [click, formData]);

  const baseUrl = getBaseUrl();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log(formData);
    e.preventDefault();
    setIsLoading(false);
    setClick(true);

    const errorsExist = Object.values(formErrors).some((error) => !!error);

    if (errorsExist) {
      setIsLoading(false);
      return;
    } else {
      try {
        const randomNumber = Math.floor(Math.random() * 1000000);
        const fileData = new FormData();
        fileData.append('image', file);
        fileData.append('folder_path', 'desktop/candidate_resume');
        fileData.append('file_name', `${formData.firstName}_${randomNumber}`);
        setIsLoading(true);
        const fileUploadResponse = await fetch(`${baseUrl}upload`, {
          method: 'POST',
          body: fileData,
        });
        const fileUploadResult = await fileUploadResponse.json();

        if (!fileUploadResult) {
          throw new Error('File upload failed');
        } else {
          const apiResponse = await CandidateRegistrationApi(
            formData.firstName,
            formData.lastName,
            formData.email,
            formData.phoneNumber,
            formData.state,
            formData.country,
            formData.profession,
            formData.social,
            fileUploadResult.fileName,
            formData.password
          );
          if (apiResponse.profile_id) {
            setSubmit(true);
            setIsLoading(false);
            setFormData({
              firstName: '',
              lastName: '',
              country: '',
              phoneNumber: '',
              state: '',
              profession: '',
              social: '',
              cv: '',
              email: '',
              password: '',
              confirmPassword: '',
              agree: false,
              subscribe: false,
            });
          } else {
            console.error('Registration failed: ', apiResponse.error);
            setSubmit(false);
            setIsLoading(false);
            setNotification({
              show: true,
              title: 'Registration Failed',
              message: apiResponse.error,
              type: 'error',
            });
          }
        }
      } catch (error) {
        console.error('Error during registration: ', error);
        setSubmit(false);
        setIsLoading(false);
        setNotification({
          show: false,
          title: 'Registration Error',
          message:
            'An error occurred during registration. Please try again later.',
          type: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="w-[317px] md:w-[500px] mb-10 drop-shadow-sm rounded-lg"
        noValidate
      >
        <div className="relative min-w-[317px] mb-5">
          <input
            type="text"
            id="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className={`mobile-form-container input-placeholder ${formErrors.firstName ? 'outline-red-300' : 'outline-gray-500'}`}
            autoComplete="off"
            placeholder="First Name"
          />
          {formErrors.firstName && (
            <div className="form-error-msg">{formErrors.firstName}</div>
          )}
        </div>
        <div className="relative min-w-[317px] mb-5">
          <input
            type="text"
            id="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className={`mobile-form-container input-placeholder ${formErrors.lastName ? 'outline-red-300' : 'outline-gray-500'}`}
            autoComplete="off"
            placeholder="Last Name"
          />
          {formErrors.lastName && (
            <div className="form-error-msg">{formErrors.lastName}</div>
          )}
        </div>
        <div className="relative min-w-[317px] mb-5">
          <input
            type="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className={`mobile-form-container input-placeholder ${formErrors.email ? 'outline-red-300' : 'outline-gray-500'}`}
            autoComplete="off"
            placeholder="Email"
          />
          {formErrors.email && (
            <div className="form-error-msg">{formErrors.email}</div>
          )}
        </div>
        <div className="mb-5">
          <PhoneInput
            forceDialCode={true}
            defaultCountry="au"
            countrySelectorStyleProps={{
              buttonStyle: {
                zIndex: '30',
                position: 'absolute',
                left: '0px',
                height: '34px',
                width: '56px',
                border: 'none',
                backgroundColor: '#EBF7FF',
              },
            }}
            onChange={(phone) =>
              setFormData({ ...formData, phoneNumber: phone })
            }
            inputProps={{
              id: 'phoneNumber',
              autoComplete: 'off',
              className:
                'w-full relative pl-16 focus:outline-none mb-[5px] bg-blue-40',
            }}
            className={`mobile-form-container ${formErrors.phoneNumber ? 'outline-red-300' : 'outline-gray-500'}`}
            value={formData.phoneNumber}
          />
          {formErrors.phoneNumber && (
            <div className="text-red-100 absolute text-sm mt-[4px]">
              {formErrors.phoneNumber}
            </div>
          )}
        </div>
        <div className="relative min-w-[317px] mb-5">
          <CountrySelector
            onChange={(value) => setFormData({ ...formData, country: value })}
            error={formErrors.country}
          />
          {formErrors.country && (
            <div className="form-error-msg">{formErrors.country}</div>
          )}
        </div>
        <div className="relative min-w-[317px] mb-5">
          <StateSelector
            onChange={(value) => setFormData({ ...formData, state: value })}
            error={formErrors.state}
          />
          {formErrors.state && (
            <div className="form-error-msg">{formErrors.state}</div>
          )}
        </div>
        <div className="relative min-w-[317px] mb-5">
          <ProfessionSelector
            onChange={(value) =>
              setFormData({ ...formData, profession: value })
            }
            error={formErrors.profession}
          />
          {formErrors.profession && (
            <div className="form-error-msg">{formErrors.profession}</div>
          )}
        </div>
        <div className="relative min-w-[317px] mb-5">
          <input
            type="password"
            id="password"
            value={formData.password}
            onChange={handleChange}
            className={`mobile-form-container input-placeholder ${formErrors.password ? 'outline-red-300' : 'outline-gray-500'}`}
            autoComplete="off"
            placeholder="Password"
          />
          {formErrors.password && (
            <div className="form-error-msg">{formErrors.password}</div>
          )}
        </div>
        <div className="relative min-w-[317px] mb-5">
          <input
            type="password"
            id="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            className={`mobile-form-container input-placeholder ${formErrors.confirmPassword ? 'outline-red-300' : 'outline-blue-250'}`}
            autoComplete="off"
            placeholder="Confirm Password"
          />
          {formErrors.confirmPassword && (
            <div className="form-error-msg">{formErrors.confirmPassword}</div>
          )}
        </div>
        <div className="relative min-w-[317px] mb-5">
          <SocialMediaSelector
            onChange={(value) => setFormData({ ...formData, social: value })}
            error={formErrors.social}
          />
          {formErrors.social && (
            <div className="form-error-msg">{formErrors.social}</div>
          )}
        </div>
        <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
          <div
            className={`mobile-form-container ${formErrors.fileUpload ? 'outline-red-300' : 'outline-blue-250'}`}
            onChange={handleFileChange}
          >
            <label htmlFor="fileUpload" className=" w-full cursor-pointer">
              <div className=" ">
                {file ? (
                  <div className="flex items-center text-nowrap justify-center">
                    <div>{getIconForFileType(file.name)}</div>
                    <h1 className="form-text">{file.name}</h1>
                  </div>
                ) : (
                  <p className="form-text mt-[6px]">
                    <div className="flex items-center justify-between">
                      <span className="">Upload Your CV</span>
                      <img
                        src={`${AWS_BASE_ASSETS_ICONS}CloudUploadFill.png`}
                        width={23}
                        height={23}
                      />
                    </div>
                  </p>
                )}
              </div>
              <input
                id="fileUpload"
                type="file"
                className="hidden"
                onChange={handleFileChange}
              />
            </label>
          </div>
          {formErrors.fileUpload && (
            <div className="form-error-msg">{formErrors.fileUpload}</div>
          )}
        </div>
        <div className="font-sans font-normal text-xs md:text-sm leading-8 text-gray-900">
          <div className="mt-6">
            <div className="mb-4">
              <input
                type="checkbox"
                id="agree"
                checked={formData.agree}
                onChange={handleChange}
              />
              <label
                htmlFor="agree"
                className={`relative ${
                  formErrors.agree
                    ? 'after:text-red-100'
                    : 'after:text-gray-900'
                } after:content-['*'] after:ml-1  absolute top-0 left-3`}
              >
                I Agree to the{' '}
                <Link to={'/terms-and-conditions'} className=" text-blue-220">
                  Term and Condition
                </Link>{' '}
                and{' '}
                <Link to={'/privacy-policy'} className=" text-blue-220">
                  Privacy policy
                </Link>
              </label>
              {formErrors.agree && (
                <div className="absolute text-red-100 text-sm leading-[16px] mt-[-10px] md:mt-[-3px] ml-5">
                  {formErrors.agree}
                </div>
              )}
            </div>
            <input
              type="checkbox"
              id="subscribe"
              checked={formData.subscribe}
              onChange={handleChange}
              className=""
              required
            />
            <label htmlFor="subscribe" className="ml-2">
              Subscribe for Job Alert
            </label>
          </div>
        </div>
        <div className="mt-5">
          <Button
            type="submit"
            title="Submit"
            variant="find-jobs-btn"
            loading={isLoading}
          />
        </div>
        {submit && (
          <div>
            <PopupNotification
              title={'Registered Successful'}
              message={
                'Thank you for registering with us. Please verify yourself by clicking on verification link on your mail.'
              }
              onClose={function (): void {
                throw new Error('Function not implemented.');
              }}
              type={'success'}
            />
          </div>
        )}
      </form>
    </div>
  );
}
