import { FC, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import Button from '../../../shared/button/Button';
import { AWS_BASE_URL_BLOG } from '@/config/seviceApiConfig';

interface OfferData {
  id: number;
  title: string;
  url: string;
}

interface MobileOfferDataProps {
  data: OfferData[]; // Define the correct type for the 'data' prop
}

const MobileOfferData: FC<MobileOfferDataProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    touchMove: true,
    variableWidth: true,
    appendDots: (dots: JSX.Element[]) => (
      <ul className="slick-dots">
        {dots.slice(0, 3).map((dot, index) => (
          <li key={index} className="inline-block m-2 cursor-pointer">
            {dot}
          </li>
        ))}
      </ul>
    ),
    customPaging: (i: number) => (
      <div
        className={`w-[0.688rem] h-[0.688rem] lg:w-[0.813rem] lg:h-[0.813rem] rounded-full mt-7 ${i === activeIndex ? 'bg-blue-400' : 'bg-gray-450'}`}
      />
    ),
    beforeChange: () => {
      const maxIndex = 2;
      //const maxIndex = Math.ceil(data.length / settings.slidesToShow) - 1;
      setActiveIndex((oldIndex) => {
        const newIndex = oldIndex + 1;
        return newIndex > maxIndex ? 0 : newIndex;
      });
    },
  };

  return (
    <div>
      <Slider {...settings}>
        {data.map((offer) => (
          <div
            key={offer.id}
            className="max-w-[316px] max-h-[247px] p-3 drop-shadow-lg"
          >
            <div className="w-[292px] h-[140px]">
              <img
                src={`${AWS_BASE_URL_BLOG}${offer.src}`}
                alt="blog-img"
                className="object-cover object-center w-full h-full rounded-lg"
              />
            </div>
            <div className="">
              <h1 className="sub-blog-title mt-2">{offer.title}</h1>
              <Button type="button" title="Read More" variant="read-more-sub" />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MobileOfferData;
