import Logo from '../../shared/logo/Logo';
import regions from '../../../data/region.json';
import { Link } from 'react-router-dom';
import { FOOTER_LINKS } from '../../../constants/HomePage';
import data from '../../../data/footer.json';

export default function MobileFooter() {
  return (
    <footer className="max-width-container bg-blue-500">
      <div className="mobile-container mobile-padding-container">
        <div>
          <div>
            <Logo height={42} width={176} />
          </div>
          <div className="mt-3">
            {regions.map((region) => (
              <Link
                to={region.href}
                key={region.id}
                className="flex items-center cursor-pointer text-[0.625rem] font-normal leading-5"
              >
                <img
                  src={region.icon}
                  alt={region.name}
                  className="w-10 h-12 object-contain object-center"
                />
                <span className="text-gray-250 ml-4">{region.name}</span>
              </Link>
            ))}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-6 mt-6 mb-6">
            {FOOTER_LINKS.map((value, index) => (
              <article key={index}>
                <div className="">
                  <p className="footer-text-topic">{value.title}</p>
                </div>
                <div className="footer-text-subtopic">
                  {value.links.map((link, linkIndex) => (
                    <div key={linkIndex} className="mb-1">
                      {link.type === 'email' ? (
                        <Link to={link.url}>{link.name}</Link>
                      ) : link.type === 'tel' ? (
                        <Link to={link.url}>{link.name}</Link>
                      ) : typeof link === 'string' ? (
                        link
                      ) : link.type === 'internal' ? (
                        <Link to={link.url}>{link.name}</Link>
                      ) : (
                        link.type === 'external' && (
                          <Link
                            to={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.name}
                          </Link>
                        )
                      )}
                    </div>
                  ))}
                </div>
              </article>
            ))}
            <div className="grid grid-cols-2 md:hidden gap-3 items-center">
              {data.map((city) => (
                <div key={city.id}>
                  <img
                    src={city.icon}
                    alt="avatar"
                    width="42"
                    height="42"
                    className="rounded-full object-cover object-center"
                  />
                </div>
              ))}
            </div>
          </div>
          <hr className="mt-4 border-t-[0.5px] border-gray-600" />
          <div className="footer-text-subtopic text-center mt-3 md:hidden">
            Copyrights © 2024 Medfuture
          </div>
          <div className="hidden md:flex items-center justify-between mt-5">
            <div className="footer-text-subtopic">
              Copyrights © 2024 Medfuture
            </div>
            <div className="flex items-center gap-6">
              {data.map((city) => (
                <div
                  key={city.id}
                  className="h-[30px] w-[30px]  md:w-[36px] md:h-[36px]"
                >
                  <img
                    src={city.icon}
                    alt="avatar"
                    className="w-full h-full rounded-full "
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
