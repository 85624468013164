import { useState } from 'react';
import MoreFacility from './MoreFacility';
import data from '@/data/MoreFacility.json';
import Button from '../shared/button/Button';
import LockImg from '/icons/Lock.png';
import { useNavigate } from 'react-router-dom';

export default function LocumFacility() {
  const navigate = useNavigate();
  const [userRegistered, setUserRegistered] = useState<boolean>(false);

  const toggleRegistration = () => {
    setUserRegistered(!userRegistered);
  };
  const renderBtnColor = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return 'locum-gold-btn';
    } else if (path === '/nursing') {
      return 'locum-pink-btn';
    } else {
      return 'locum-silver-btn';
    }
  };

  const navigateToRegister = () => {
    navigate('/register');
  };

  return (
    <section className="mb-20 relative">
      <div className="grid grid-cols-2 gap-5">
        {data.map((facility, index) => (
          <div key={facility.id}>
            {userRegistered || index < 2 ? (
              <MoreFacility
                index={index}
                facility={facility}
                userRegistered={userRegistered}
              />
            ) : (
              <div className="relative h-[130px]">
                <div className="absolute inset-0 bg-gray-400 blur rounded-md">
                  <MoreFacility
                    index={index}
                    facility={facility}
                    userRegistered={userRegistered}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
        {!userRegistered && (
          <div className="absolute inset-0 top-[20%] transform translate-y-[20%] rounded-md flex flex-col items-center justify-center">
            <img src={LockImg} className="w-[50px] h-[50px] object-cover" />
            <h6 className="font-sans font-normal 2xl:text-[30px] 4xl:text-[28px] leading-8 my-10">
              Unlock More Details
            </h6>
            <div className="">
              <Button
                type={'button'}
                title={'Register Now'}
                variant={renderBtnColor()}
                handleClick={navigateToRegister}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
