export const FIND_LOCATION_IMAGE = [
  {
    id: 1,
    image: 'AusLocation.png',
    address:
      'Suite 204- Level 2 ,55-75 Victor Crescent, Narre Warren, Victoria - 3805, Australia',
  },
  {
    id: 2,
    image: 'NZLocation.png',
    address:
      'Level 16, NTT Tower, 157 Lambton Quay, Wellington 6011, New Zealand',
  },
  {
    id: 3,
    image: 'SLLocation.png',
    address: '65C Srimath Anagarika Dharmapala Mawatha, Colombo 00700',
  },
];
