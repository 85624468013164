import { useState, useEffect, useRef } from 'react';

const useMegaMenu = () => {
  const [selectedLink, setSelectedLink] = useState(null);
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const [isPolygonVisible, setIsPolygonVisible] = useState(false);
  const [isMegaMenuClicked, setIsMegaMenuClicked] = useState(false);
  const menuRef = useRef(null);
  const megaMenuRef = useRef(null);

  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (!event.target.closest('.mega-menu') && !isMegaMenuClicked) {
        setIsMegaMenuOpen(false);
        setIsPolygonVisible(false);
      }
      setIsMegaMenuClicked(false);
    };

    const handleScroll = () => {
      setIsMegaMenuOpen(false);
      setIsPolygonVisible(false);
    };

    document.addEventListener('mousedown', handleClickOutsideMenu);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLinkClick = (link) => {
    if (selectedLink === link) {
      setIsMegaMenuOpen(!isMegaMenuOpen);
      setIsPolygonVisible(!isPolygonVisible);
    } else {
      setSelectedLink(link);
      setIsMegaMenuOpen(true);
      setIsPolygonVisible(true);
    }
  };

  const handleMegaMenuClick = () => {
    setIsMegaMenuClicked(true);
  };

  return {
    selectedLink,
    isMegaMenuOpen,
    isPolygonVisible,
    handleLinkClick,
    handleMegaMenuClick,
  };
};

export default useMegaMenu;
