import { Button } from '@/components';
import EnquiryNow from '@/components/forms/enquiryNow/EnquiryNow';
import HeroBackground3 from '@/components/hero/HeroBackground3';
import FindUsComponent from '@/components/mobile/contactComponent/FindUsComponent';
import HealthcareProfessional from '@/components/mobile/contactComponent/HealthcareProfessional';
import MobileContactBanner from '@/components/mobile/contactComponent/MobileContactBanner';
import ContactMobileHero from '@/components/mobile/hero/ContactMobileHero';
import SeoOptimized from '@/components/reactHelmet/SeoOptimized';
import { getProductionUrl } from '@/config/getProductionUrl';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';
import {
  LOCUM_CONTACT_INFORMATION,
  PERMANENT_CONTACT_INFORMATION,
} from '@/constants/ContactInformation';
import { EMPLOYERS_ROUTES, FIND_JOB_ROUTES } from '@/constants/HardCodeRoutes';
import useFetchAllSeoDetails from '@/hooks/useFetchAllSeoDetails';
import useResponsive from '@/hooks/useResponsive';
import { MobileLayout, PageLayout } from '@/layout';
import { formatNameForURL } from '@/utils/formatNameForURL';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function ContactUs() {
  const CurLocation = useLocation();
  const { isMobile } = useResponsive();
  const navigate = useNavigate();

  const productionUrl = getProductionUrl();
  const { seoData } = useFetchAllSeoDetails(
    `${productionUrl}${CurLocation.pathname}`
  );

  const getJobUrl = (job) => {
    if ([1, 3, 4, 13, 14].includes(job.id)) {
      return `/specialty/permanent/${formatNameForURL(job.short_name.replace(/jobs/i, '').trim())}-jobs-in-australia`;
    } else {
      return '/default-url'; // Default URL for other jobs
    }
  };

  const navigateToRegister = () => {
    navigate('/register');
  };

  const hoverAnimation = {
    initial: {
      background: 'linear-gradient(to right, white 50%, blue 50%)',
      backgroundSize: '200% 100%',
      backgroundPosition: 'right bottom',
    },
    hover: {
      backgroundPosition: 'left bottom',
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };
  return (
    <>
      {seoData && (
        <SeoOptimized
          title={seoData?.title}
          description={seoData?.description}
          robots={seoData?.robots}
          twitterImage={seoData?.twitterImage || seoData?.image}
          ogTitle={seoData?.og_title}
          ogDescription={seoData?.og_description}
          ogImage={seoData?.og_image}
          ogUrl={seoData?.og_URL}
          ogSiteName={seoData?.og_site_name}
          ogLocale={seoData?.og_locale}
          ogType={seoData?.og_type}
          canonicalUrl={seoData?.canonical}
        />
      )}
      {isMobile ? (
        <>
          <ContactMobileHero />
          <MobileLayout>
            <div className="mobile-padding-container">
              <h2 className="font-sans font-bold text-[26px] leading-[34px] text-gray-800">
                Contact Information for Healthcare Professionals
              </h2>
              <HealthcareProfessional />
            </div>
            <div className="mobile-padding-container">
              <h2 className="font-sans font-bold text-[26px] leading-[34px] text-gray-800">
                Contact Information for Healthcare Employers
              </h2>
              <HealthcareProfessional />
            </div>
          </MobileLayout>
          <MobileContactBanner />
          <MobileLayout>
            <FindUsComponent />
          </MobileLayout>
        </>
      ) : (
        <>
          <HeroBackground3 />
          <PageLayout bgColor="gray-450">
            <div className="flex items-start justify-between lg:gap-x-5 4xl:gap-x-10 lg:my-16 4xl:my-40 lg:mb-[290px] 4xl:mb-[350px] ">
              <div>
                <h6 className="font-sans font-bold lg:text-[18px] xl:text-[22px] 4xl:text-[26px] text-gray-800 mb-10">
                  Contact Information for Healthcare Professionals
                </h6>
                <div className="grid grid-cols-2 lg:gap-4 4xl:gap-8">
                  {PERMANENT_CONTACT_INFORMATION.map((company) => (
                    <div className="group cursor-pointer lg:w-[200px] xl:w-[250px] xl:h-[180px] 4xl:w-[352px] 4xl:h-[207px] bg-white relative overflow-hidden shadow-lg rounded-[4px] transition duration-500 ease-in-out ">
                      <div className="lg:p-3 xl:p-8 4xl:p-7 relative z-[4]">
                        <h3 className="font-sans font-normal lg:text-[16px] 4xl:text-[20px] leading-[26px] text-blue-700 mb-3 group-hover:text-white transition duration-300 ease-in-out">
                          {company.name}
                        </h3>
                        <p className="font-sans font-normal lg:text-xs  4xl:text-base group-hover:text-white transition duration-300 ease-in-out">
                          <strong>Call:</strong>{' '}
                          <a
                            href={`tel:${company.call}`}
                            className="text-blue-700 group-hover:text-white transition duration-300 ease-in-out"
                          >
                            {company.call}
                          </a>
                        </p>
                        <p className="font-sans font-normal lg:text-xs  4xl:text-base group-hover:text-white transition duration-300 ease-in-out">
                          <strong>Email:</strong>{' '}
                          <a
                            href={`mailto:${company.email}`}
                            className="text-blue-700 group-hover:text-white transition duration-300 ease-in-out"
                          >
                            {company.email}
                          </a>
                        </p>
                        <a
                          href={company.link}
                          className="text-blue-400 text-xs group-hover:text-white transition duration-300 ease-in-out"
                        >
                          Website link
                        </a>
                      </div>
                      <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-blue-150 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700 ease-in-out"></div>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <h6 className="font-sans font-bold lg:text-[18px] xl:text-[22px] 4xl:text-[26px] text-gray-800 mb-10">
                  Contact Information for Healthcare Employers
                </h6>
                <div className="grid grid-cols-2 lg:gap-4 4xl:gap-8">
                  {LOCUM_CONTACT_INFORMATION.map((company) => (
                    <div className="group cursor-pointer lg:w-[200px] xl:w-[250px] xl:h-[180px] 4xl:w-[352px] 4xl:h-[207px] bg-white relative overflow-hidden shadow-lg rounded-[4px] transition duration-500 ease-in-out ">
                      <div className="lg:p-3 xl:p-8 4xl:p-7 relative z-[4]">
                        <h3 className="font-sans font-normal lg:text-[16px] 4xl:text-[20px] leading-[26px] text-blue-700 mb-3 group-hover:text-white transition duration-300 ease-in-out">
                          {company.name}
                        </h3>
                        <p className="font-sans font-normal lg:text-xs  4xl:text-base group-hover:text-white transition duration-300 ease-in-out">
                          <strong>Call:</strong>{' '}
                          <a
                            href={`tel:${company.call}`}
                            className="text-blue-700 group-hover:text-white transition duration-300 ease-in-out"
                          >
                            {company.call}
                          </a>
                        </p>
                        <p className="font-sans font-normal lg:text-xs  4xl:text-base group-hover:text-white transition duration-300 ease-in-out">
                          <strong>Email:</strong>{' '}
                          <a
                            href={`mailto:${company.email}`}
                            className="text-blue-700 group-hover:text-white transition duration-300 ease-in-out"
                          >
                            {company.email}
                          </a>
                        </p>
                        <a
                          href={company.link}
                          className="text-blue-400 text-xs group-hover:text-white transition duration-300 ease-in-out"
                        >
                          Website link
                        </a>
                      </div>
                      <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-blue-150 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 ease-in-out"></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </PageLayout>
          <PageLayout bgColor="gray-380">
            <div className="relative flex items-center justify-center h-[700px] ">
              <EnquiryNow />
            </div>
            <div className="flex items-center justify-center padding-container">
              <div className="w-[900px] 4xl:w-[1285px] h-[250px] bg-white 4xl:h-[287px] flex items-end justify-between shadow-xl lg:px-20 4xl:px-40 lg:pb-16 4xl:pb-20">
                <p className="font-sans lg:text-[34px] 4xl:text-[50px] 4xl:leading-[60px] text-blue-600 w-[647px]">
                  Partner with us to find your next opportunity
                </p>
                <div className="w-[200px] 4xl:w-[237px]">
                  <Button
                    type="submit"
                    title="Register Now"
                    variant="find-jobs-btn"
                    handleClick={navigateToRegister}
                  />
                </div>
              </div>
            </div>
            <div className="padding-container mb-20">
              <h2 className="text-gray-800 text-[34px] leading-[40px] font-bold mb-10">
                Find Us
              </h2>
              <div className="w-full h-[280px] 2xl:h-[344px] bh-white shadow-lg flex items-center justify-normal mb-10">
                <div className="w-[680px] h-[280px] xl:w-[900px] 2xl:w-[1018px] 2xl:h-[344px]">
                  <img
                    src={`${AWS_BASE_URL_ASSETS}/NZLocation.png`}
                    className="object-cover object-center w-full h-full"
                  />
                </div>
                <p className="font-sans font-bold text-sm lg:text-lg lg:leading-6 2xl:leading-[33px] ml-4 2xl:ml-10">
                  <h6>Level 16, NTT Tower, 157 </h6>
                  <h6>Lambton Quay, </h6>
                  <h6>Wellington 6011, </h6>
                  <h6>New Zealand</h6>
                </p>
              </div>
              <div className="w-full h-[280px] 2xl:h-[344px] bh-white shadow-lg flex items-center justify-normal mb-10">
                <div className="w-[680px] h-[280px] xl:w-[900px] 2xl:w-[1018px] 2xl:h-[344px]">
                  <img
                    src={`${AWS_BASE_URL_ASSETS}/AusLocation.png`}
                    className="object-cover object-center w-full h-full"
                  />
                </div>
                <p className="font-sans font-bold text-sm lg:text-lg lg:leading-6 2xl:leading-[33px] ml-4 2xl:ml-10">
                  <h6>Suite 204- Level 2 ,55-75</h6>
                  <h6>Victor Crescent, Narre </h6>
                  <h6>Warren, Victoria - 3805,</h6>
                  <h6>Australia</h6>
                </p>
              </div>
              <div className="w-full h-[280px] 2xl:h-[344px] bh-white shadow-lg flex items-center justify-normal">
                <div className="w-[680px] h-[280px] xl:w-[900px] 2xl:w-[1018px] 2xl:h-[344px]">
                  <img
                    src={`${AWS_BASE_URL_ASSETS}/SLLocation.png`}
                    className="object-cover object-center w-full h-full"
                  />
                </div>
                <p className="font-sans font-bold text-sm lg:text-lg lg:leading-6 2xl:leading-[33px] ml-4 2xl:ml-10">
                  <h6>65 Srimath Anagarika</h6>
                  <h6>Dharmapala Mawatha </h6>
                  <h6>Colombo 00700</h6>
                  <h6>Sri Lanka</h6>
                </p>
              </div>
            </div>
          </PageLayout>
          {/* <PageLayout>
        <div className="flex items-start justify-between gap-x-10 padding-container">
          <div className="w-1/2">
            <h1 className="font-sans font-bold text-[22px] leading-[26px] mb-8">
              Find a Job
            </h1>
            <div className="grid grid-cols-2 ">
              {FIND_JOB_ROUTES.map((job, index) => (
                <div
                  key={index}
                  className="font-sans font-normal text-sm leading-[30px] 4xl:text-base 4xl:leading-[45px]"
                >
                  <Link
                    to={
                      [1, 3, 4, 13, 14].includes(job.id)
                        ? ``
                        : `/specialty/permanent/${formatNameForURL(job.short_name.replace(/jobs/i, '').trim())}-jobs-in-australia`
                    }
                  >
                    {job.name}
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="w-1/2">
            <h1 className="font-sans font-bold text-[22px] leading-[26px] mb-8">
              Employers
            </h1>
            <div className="grid grid-cols-2 ">
              {EMPLOYERS_ROUTES.map((job, index) => (
                <div
                  key={index}
                  className="font-sans font-normal text-sm leading-[30px] 4xl:text-base 4xl:leading-[45px]"
                >
                  <Link to={job.url}>{job.name}</Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </PageLayout> */}
        </>
      )}
    </>
  );
}
