import Facebook from '/icons/fbIcon.png';
import MailIcon from '/icons/MailIcon.png';
import WhatsappIcon from '/icons/whatsappIcon.png';
import InstaIcon from '/icons/instaIcon.png';
import youTubeIcon from '/icons/youTubeIcon.png';
import TwitterIcon from '/icons/TwitterIcon.png';
import shareIcon from '/icons/shareIcon.png';
import linkedinIcon from '/icons/linkedinIcon.png';

export const BOTTOM_NAV_LINKS = [
  { href: '/#doctor', key: 'doctor', label: 'Doctor' },
  { href: '/#ahp', key: 'ahp', label: 'AHP' },
  { href: '/#oralHealth', key: 'oralHealth', label: 'Oral Health' },
  { href: '/#mentalHealth', key: 'mentalHealth', label: 'Mental Health' },
  { href: '/#nursing', key: 'nursing', label: 'Nursing' },
  { href: '/#healthcare', key: 'healthcare', label: 'Healthcare Executive ' },
];

//Footer section
export const FOOTER_LINKS = [
  {
    title: 'Information',
    links: [
      // { name: 'About', type: 'internal', url: '/#' },
      // { name: 'News', type: 'internal', url: '/#' },
      { name: 'Privacy & Policies', type: 'internal', url: '/privacy-policy' },
      {
        name: 'Terms & Conditions',
        type: 'internal',
        url: '/terms-and-conditions',
      },
      { name: 'Compliance', type: 'internal', url: '/compliance' },
    ],
  },
  {
    title: 'Support',
    links: [
      { name: 'Contact Us', type: 'internal', url: '/contact-us' },
      { name: 'Create an Account', type: 'internal', url: '/register' },
      // { name: 'Register for Job Alerts', type: 'internal', url: '' },
    ],
  },
  {
    title: 'Jobs',
    links: [
      {
        name: 'North Island',
        type: 'external',
        url: 'jobs-in-north-island',
      },
      { name: 'South Island', type: 'external', url: 'jobs-in-south-island' },
      // {
      //   name: 'South Australia',
      //   type: 'external',
      //   url: 'jobs-in-south-australia',
      // },
      // { name: 'Tasmania', type: 'external', url: 'jobs-in-tasmania' },
      // { name: 'Victoria', type: 'external', url: 'jobs-in-victoria' },
      // {
      //   name: 'Western Australia',
      //   type: 'external',
      //   url: 'jobs-in-western-australia',
      // },
      // {
      //   name: 'Australian Capital Territory',
      //   type: 'external',
      //   url: 'jobs-in-australian-capital-territory',
      // },
      // {
      //   name: 'Northern Territory',
      //   type: 'external',
      //   url: 'jobs-in-northern-territory',
      // },
    ],
  },
];

export const FOOTER_SOCIAL = [
  {
    links: [
      { icon: 'Facebook', type: 'external', url: '' },
      {
        icon: 'Instagram',
        type: 'external',
        url: '',
      },
      { icon: 'Linkedin', type: 'external', url: '' },
      { icon: 'Twitter', type: 'external', url: '' },
      { icon: 'Youtube', type: 'external', url: '' },
    ],
  },
];

export const SHARE_SOCIAL_MEDIA = [
  {
    links: [
      { icon: MailIcon, type: 'external', url: 'mailto:?body=' },
      {
        icon: WhatsappIcon,
        type: 'external',
        url: 'https://api.whatsapp.com/send?text=',
      },
      {
        icon: Facebook,
        type: 'external',
        url: 'https://www.facebook.com/sharer/sharer.php?u=',
      },
      {
        icon: InstaIcon,
        type: 'external',
        url: 'https://www.instagram.com/?url=',
      },
      {
        icon: TwitterIcon,
        type: 'external',
        url: 'https://twitter.com/intent/tweet?url=',
      },
      { icon: shareIcon, type: 'external', url: '' },
      {
        icon: linkedinIcon,
        type: 'external',
        url: 'https://www.linkedin.com/shareArticle?url=',
      },
    ],
  },
];
