import { useState } from 'react';
import LockImg from '/icons/Lock.png';
import Button from '@/components/shared/button/Button';
import MobileFacility from './MobileFacility';

export default function MobileCardFacility({ data }) {
  const [userRegistered, setUserRegistered] = useState<boolean>(false);
  const renderBtnColor = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return 'locum-gold-btn w-full';
    } else {
      return 'locum-silver-btn w-full';
    }
  };

  return (
    <div className="py-10">
      <div className="grid grid-cols-2 gap-5">
        {data.map((facility, index) => (
          <div key={facility.id}>
            {userRegistered || index < 2 ? (
              <MobileFacility
                index={index}
                facility={facility}
                userRegistered={userRegistered}
              />
            ) : (
              <div className="relative h-[130px]">
                <div className="absolute inset-0 bg-gray-400 blur rounded-md">
                  <MobileFacility
                    index={index}
                    facility={facility}
                    userRegistered={userRegistered}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
        {!userRegistered && (
          <div className="absolute inset-0 top-[40%] transform translate-y-[40%] md:top-3/4 md:translate-y-3/4 rounded-md flex flex-col items-center justify-center">
            <img src={LockImg} className="w-[42px] h-[42px] object-cover" />
            <h1 className="font-sans font-normal text-[22px] leading-[26px] my-5">
              Unlock More Details
            </h1>
            <div className="w-[200px] h-[44px]">
              <Button
                type={'button'}
                title={'Register Now'}
                variant={renderBtnColor()}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
