import { useState, useEffect } from 'react';
import GetAllCountriesApi from '@/api/GetAllCountryApi';

interface CountryData {
  country_id: number;
  name: string;
  image: string;
  image_alt: string | null;
  domain: string;
  description: string | null;
  status: number;
  created_by: string | null;
  updated_by: string | null;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  position: number | null;
  short_name: string | null;
  mobile_image: string | null;
}

interface FetchAllCountries {
  allCountriesData: CountryData[] | null;
  countryDataLoading: boolean;
  error: Error | null;
}

export default function useFetchAllCountries(): FetchAllCountries {
  const [allCountriesData, setAllCountriesData] = useState<
    CountryData[] | null
  >(null);
  const [countryDataLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetAllCountriesApi();
      setAllCountriesData(response);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { allCountriesData, countryDataLoading, error };
}
