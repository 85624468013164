import { motion } from 'framer-motion';
import { BENEFITS } from '@/constants/Benifits';
import BenefitCard from './BenefitCard';

export default function MedfutureBenefits() {
  const bounceVariant = {
    initial: {
      y: 100,
      opacity: 0,
    },
    animate: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        delay: i * 0.2,
        type: 'spring',
        stiffness: 100,
      },
    }),
  };
  return (
    <motion.section className="padding-container">
      <div>
        <motion.h2
          className="font-sans font-bold text-center text-gray-800 text-[32px] leading-[44px] mb-16"
          initial={{ x: -500, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          Benefits of Choosing Medfuture
        </motion.h2>
        <div className="grid grid-cols-4 gap-x-20">
          {BENEFITS.map((benefit, index) => (
            <motion.div
              key={benefit.id}
              className="flex flex-col items-start"
              custom={index}
              initial="initial"
              whileInView="animate"
              variants={bounceVariant}
              whileHover={{
                scale: 1.05,
              }}
              transition={{ duration: 0.2, ease: 'easeOut' }}
            >
              <BenefitCard benefit={benefit} />
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
}
