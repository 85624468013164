import { motion } from 'framer-motion';
import BenefitCard from './BenefitCard';
import { REFER_AND_EARN } from '@/constants/Refer';

export default function BenifitsOfRefer() {
  const bounceVariant = {
    initial: {
      y: 100,
      opacity: 0,
    },
    animate: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        delay: i * 0.2,
        type: 'spring',
        stiffness: 100,
      },
    }),
  };
  return (
    <motion.section className="padding-container">
      <div>
        <motion.h1
          className="font-sans font-bold text-center text-gray-800 text-[32px] leading-[44px] mb-16"
          initial={{ x: -500, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          How It Works
        </motion.h1>
        <div className="grid grid-cols-3 gap-x-10">
          {REFER_AND_EARN.map((benefit, index) => (
            <motion.div
              key={benefit.id}
              className="flex flex-col items-start"
              custom={index}
              initial="initial"
              whileInView="animate"
              variants={bounceVariant}
              whileHover={{
                scale: 1.05,
              }}
              transition={{ duration: 0.2, ease: 'easeOut' }}
            >
              <div className="flex flex-col items-start cursor-pointer">
                <img
                  src={benefit.icon}
                  alt={benefit.title}
                  className="w-16 h-16 mb-8"
                />
                <h3 className="font-sans font-bold text-gray-800 text-lg mb-4">
                  {benefit.title}
                </h3>
                <p className="font-sans text-gray-600 text-lg">
                  {benefit.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
}
