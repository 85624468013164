import { getBaseUrl } from '@/config/BaseUrl';

interface ClientData {
  company_name: string;
  trade_name: string;
  industry_type: number;
  website: string;
  official_contact_name: string;
  designation: string;
  email: string;
  mobile: string;
  vacancy_profile: any;
  hear_about_us: string | null;
  client_id: string;
  updated_at: string;
  created_at: string;
  client_profiles_id: number;
}

interface ClientResponse {
  message?: string;
  data?: ClientData;
  errors?: string[];
}

export default async function ClientRegistrationApi(
  company_name: string,
  trade_name: string,
  industry_type: number,
  website: string,
  official_contact_name: string,
  designation: string,
  email: string,
  mobile: string,
  password: string,
  vacancy_profile: any,
  hear_about_us: string | null,
  alert: boolean
): Promise<ClientResponse> {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}web/client/save`;

  const requestBody = {
    company_name,
    trade_name,
    industry_type,
    website,
    official_contact_name,
    designation,
    email,
    mobile,
    password,
    vacancy_profile,
    hear_about_us,
    alert,
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(requestBody),
  };

  try {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      const responseData: ClientResponse = await response.json();
      if (response.status === 422 && responseData.errors?.length > 0) {
        throw new Error(responseData.errors[0]);
      } else {
        throw new Error(
          responseData.message || `Failed with status: ${response.status}`
        );
      }
    }

    const responseData: ClientResponse = await response.json();
    return responseData;
  } catch (error) {
    const errorMessage = error.message || 'Unknown error occurred';
    throw new Error(errorMessage);
  }
}
