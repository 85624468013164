export const REFER_AND_EARN = [
  {
    id: 1,
    icon: '/images/ReferImage.png',
    title: 'Refer a Professional',
    description:
      'Know someone who would be a great fit for Medfuture? Refer them to us by filling out the referral form below. Provide their contact details and a brief note about why they’d be a great addition to our team.',
  },
  {
    id: 2,
    icon: '/images/ReachOut.png',
    title: 'We Reach Out',
    description:
      'Our recruitment team will review the referral and reach out to the candidate to discuss potential opportunities at Medfuture. If they meet our criteria and are successfully hired, you’ll be eligible for a referral reward.',
  },
  {
    id: 3,
    icon: '/images/Reward.png',
    title: 'Earn Rewards',
    description:
      'Once your referral has completed 90 days of employment with Medfuture, you’ll receive a reward as a thank you for helping us find top talent. It’s our way of showing appreciation for your support in growing our team.',
  },
];
