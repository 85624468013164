import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GetJobsCountBySpecialtyApi from '@/api/GetJobsCountBySpecialty';

interface FilterJobsData {}

interface FetchJobFilterData {
  jobCountBySpecilaty: FilterJobsData[] | null;
  jobCountSpecialtyLoading: boolean;
  filterDataError: Error | null;
}

export default function useFetchJobCountBySpecialty(): FetchJobFilterData {
  const navigate = useNavigate();
  const [jobCountBySpecilaty, setJobCountSpecialty] = useState<
    FilterJobsData[] | null
  >(null);
  const [jobCountSpecialtyLoading, setLoading] = useState<boolean>(true);
  const [filterDataError, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetJobsCountBySpecialtyApi();
      setJobCountSpecialty(response);
    } catch (error: any) {
      if (error.status === 429) {
        navigate('/many-requests', {
          state: { retryAfter: error.data.retry_after },
        });
      } else {
        setError(error as Error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { jobCountBySpecilaty, jobCountSpecialtyLoading, filterDataError };
}
