// ContextProvider.tsx
import GetAllJobsApi from '@/api/GetAllJobsApi';
import GetAllStatesWithRegionsApi from '@/api/GetStatesWithRegionsApi';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

interface ContextProviderProps {
  isOffCanvasOpen: boolean;
  isMainMenuOpen: boolean;
  openOffCanvas: () => void;
  closeOffCanvas: () => void;
  openMainMenu: () => void;
  closeMainMenu: () => void;
  allJobData: AllJobData[] | null;
  loadingJobs: boolean;
  errorJobs: Error | null;

  stateWithRegions: stateWithRegionsData[] | null;
  loadingStates: boolean;
  errorStates: Error | null;

  fetchJobs: () => void;
  fetchStatesWithRegions: () => void;
}

interface AllJobData {}

interface FetchAllJobData {
  allJobData: AllJobData[] | null;
  loading: boolean;
  error: Error | null;
}

interface stateWithRegionsData {
  regions_id: number;
  state_id: number;
  country_id: number;
  name: string;
  description: string;
  status: number;
  image: string;
  image_alt: string;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

interface FetchStateWithRegions {
  stateWithRegions: stateWithRegionsData[] | null;
  loading: boolean;
  error: Error | null;
  fetchStatesWithRegions: () => void;
}

const cachedData: stateWithRegionsData[] | null = null;

const AppContext = createContext<ContextProviderProps | undefined>(undefined);

export default function ContextProvider({ children }: ContextProviderProps) {
  const [allJobData, setAllJobData] = useState<AllJobData[] | null>(null);
  const [loadingJobs, setLoadingJobs] = useState<boolean>(true);
  const [errorJobs, setErrorJobs] = useState<Error | null>(null);

  const [stateWithRegions, setStateWithRegions] = useState<
    StateWithRegionsData[] | null
  >(null);
  const [loadingStates, setLoadingStates] = useState<boolean>(true);
  const [errorStates, setErrorStates] = useState<Error | null>(null);

  // Fetch jobs data
  const fetchJobs = async () => {
    setLoadingJobs(true);
    try {
      const response = await GetAllJobsApi();
      const initialJobs = response;
      setAllJobData(initialJobs);
    } catch (error) {
      setErrorJobs(error as Error);
    } finally {
      setLoadingJobs(false);
    }
  };

  // Fetch states with regions data
  const fetchStatesWithRegions = useCallback(async () => {
    setLoadingStates(true);
    try {
      const response = await GetAllStatesWithRegionsApi();
      const regions = response.data[0].states;
      setStateWithRegions(regions);
    } catch (error) {
      setErrorStates(error as Error);
    } finally {
      setLoadingStates(false);
    }
  }, []);

  useEffect(() => {
    fetchJobs();
    fetchStatesWithRegions();
  }, []);
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);

  const openOffCanvas = () => {
    setIsOffCanvasOpen(true);
  };

  const closeOffCanvas = () => {
    setIsOffCanvasOpen(false);
  };

  const openMainMenu = () => {
    setIsMainMenuOpen(true);
  };

  const closeMainMenu = () => {
    setIsMainMenuOpen(false);
  };

  return (
    <AppContext.Provider
      value={{
        isOffCanvasOpen,
        isMainMenuOpen,
        openOffCanvas,
        closeOffCanvas,
        openMainMenu,
        closeMainMenu,
        allJobData,
        loadingJobs,
        errorJobs,
        stateWithRegions,
        loadingStates,
        errorStates,
        fetchJobs,
        fetchStatesWithRegions,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useStateContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useStateContext must be used within a ContextProvider');
  }
  return context;
};
