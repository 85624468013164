import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import JobsByMajorCitiesData from './JobsByMajorCitiesData';

interface Job {
  id: number;
  name: string;
  jobs: string;
  url: string;
}

interface City {
  id: number;
  title: string;
  data: Job[];
}

const JobsByMajorCities = ({ jobCountBySuberbs }) => {
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [suburbData, setSuburbData] = useState([]);

  useEffect(() => {
    if (jobCountBySuberbs && jobCountBySuberbs?.length > 0) {
      setSelectedStateId(jobCountBySuberbs[0].state_id);
    }
  }, [jobCountBySuberbs]);

  useEffect(() => {
    const selectedState = jobCountBySuberbs?.find(
      (state) => state.state_id === selectedStateId
    );
    if (selectedState) {
      const allSuburbs = selectedState.suburbs || [];
      setSuburbData(allSuburbs);
    }
  }, [selectedStateId, jobCountBySuberbs]);

  const handleStateClick = (stateId) => {
    setSelectedStateId(stateId);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  return (
    <section className="mobile-padding-container">
      <div className="flex items-center justify-between mb-5">
        <h2 className="section_header_text">Jobs By Major Cities</h2>
      </div>
      <div>
        <Slider {...settings}>
          {jobCountBySuberbs?.map((state) => (
            <div
              key={state.state_id}
              className={`cursor-pointer mb-6 preferences-topic ${
                selectedStateId === state.state_id
                  ? 'hover:bg-gradient-to-r bg-gradient-to-r from-blue-500 to-blue-150 transition duration-300 text-center border border-blue-400 text-white py-3 px-4 rounded-md'
                  : 'border-none py-3 px-4  rounded-md text-center text-blue-700 hover:text-gray-120'
              }`}
              // className={`city ${state === selectedStateId ? 'bg-blue-400 text-white py-3 px-11 rounded-md' : 'border-none py-3 px-11 rounded-md text-gray-800'}`}
              onClick={() => handleStateClick(state.state_id)}
            >
              {state.state_name}
            </div>
          ))}
        </Slider>
      </div>
      <JobsByMajorCitiesData suburbData={suburbData} />
    </section>
  );
};

export default JobsByMajorCities;
