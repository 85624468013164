import { getBaseUrl } from '@/config/BaseUrl';

interface CandidateData {
  email: string;
  password: string;
}

interface CandidateResponse {
  message: string;
  data: CandidateData;
  candidate_id: string;
}

export default async function CandidateLoginApi(email, password) {
  try {
    const requestBody = {
      email: email,
      password: password,
    };
    const baseUrl = getBaseUrl();
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(`${baseUrl}login`, requestOptions);

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message);
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error('Error login to Candidate Profile:', error);
    throw error;
  }
}
