import {
  BannerComponent,
  DropDownSection,
  DynamicMobileHero,
  HeroBackground2,
  JobSection,
  MobileAwardBanner,
  MobileDescription,
  MobileJobSection,
  MobileOfferDropDown,
  MobileRegister,
  Register,
} from '@/components';
import LandingReachUs from '@/components/forms/reachUs/LandingReachUs';
import useFetchAllProfessions from '@/components/forms/selectors/hook/useFetchAllProfessions';
import NewCardComponent from '@/components/home/newBanner/NewCardComponent';
import JobsLoadingComponent from '@/components/noData/JobsLoading';
import PageLoadingComponent from '@/components/noData/PageLoadingComponent';
import SeoOptimized from '@/components/reactHelmet/SeoOptimized';
import { getProductionUrl } from '@/config/getProductionUrl';
import { AWS_BASE_ASSETS_SENIORITY } from '@/config/seviceApiConfig';
import useFetchAllSeoDetails from '@/hooks/useFetchAllSeoDetails';
import useFetchFilterJobDetails from '@/hooks/useFetchFilterJobsDetails';
import useFetchFilterData from '@/hooks/useFetchFilterMenuData';
import useResponsive from '@/hooks/useResponsive';
import { MobileLayout, PageLayout } from '@/layout';
import { formatURLNameToNormal } from '@/utils/formatNameUtil';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function SeniorityLandingPage() {
  const curLocation = useLocation();
  const path = curLocation.pathname;
  const match = path.match(/\/([a-z-]+)-jobs-in-[a-z-]+/i);
  const seniority = match ? match[1] : '';
  const { isMobile } = useResponsive();
  const productionUrl = getProductionUrl();
  const { seoData } = useFetchAllSeoDetails(
    `${productionUrl}${curLocation.pathname}`
  );

  const { filterMenuData, loading } = useFetchFilterData();

  const [professionId, setProfessionId] = useState<number>();
  const [seniorityId, setSeniorityId] = useState<number>();
  const [description, setDescription] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [miniTitle, setMiniTitle] = useState<string>('');
  const [banner, setBanner] = useState<string>('');
  const [bannerAlt, setBannerAlt] = useState<string>('');
  const { filterData, FilterDataLoading, FilterDataError } =
    useFetchFilterJobDetails({
      professionId,
      seniorityId,
    });
  const [stateJobs, setStateJobs] = useState({});
  const [loadingJobs, setLoadingJobs] = useState(true);

  useEffect(() => {
    if (seniority && filterMenuData) {
      filterMenuData?.forEach((division) => {
        division?.professions?.forEach((profession) => {
          profession.seniorities.forEach((senior) => {
            if (senior.locum_url === formatURLNameToNormal(seniority)) {
              setProfessionId(profession.profession_id);
              setSeniorityId(senior.seniorities_id);
              setDescription(senior.description);
              setTitle(senior.lp_title);
              setMiniTitle(senior.lp_mini_title);
              setBanner(`${AWS_BASE_ASSETS_SENIORITY}${senior?.cover_image}`);
            }
          });
        });
      });
    }
  }, [seniority, filterMenuData]);

  const displayedJobs = filterData;

  useEffect(() => {
    if (!FilterDataLoading) {
      const jobsByState = {};
      displayedJobs?.forEach((job) => {
        const stateName = job.state?.name;
        if (stateName) {
          if (!jobsByState[stateName]) {
            jobsByState[stateName] = [];
          }
          jobsByState[stateName].push(job);
        }
      });
      setStateJobs(jobsByState);
      setLoadingJobs(false);
    }
  }, [displayedJobs, FilterDataLoading]);

  const hasJobs = Object.keys(stateJobs).length > 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return <PageLoadingComponent />;
  }

  if (FilterDataError) {
    return <div>Error: {FilterDataError.message}</div>;
  }
  return (
    <>
      {seoData && (
        <SeoOptimized
          title={seoData?.title}
          description={seoData?.description}
          robots={seoData?.robots}
          twitterImage={seoData?.twitterImage || seoData?.image}
          ogTitle={seoData?.og_title}
          ogDescription={seoData?.og_description}
          ogImage={seoData?.og_image}
          ogUrl={seoData?.og_URL}
          ogSiteName={seoData?.og_site_name}
          ogLocale={seoData?.og_locale}
          ogType={seoData?.og_type}
          canonicalUrl={seoData?.canonical}
        />
      )}
      {isMobile ? (
        <>
          <MobileLayout>
            {/* <DynamicMobileHero
              title={title}
              description={description}
              miniTitle={miniTitle}
              image={banner}
            />
            <MobileDescription />
            <MobileAwardBanner />
            <MobileJobSection JobData={displayedJobs} />
            <MobileOfferDropDown data={OfferData} />
            <MobileRegister /> */}
          </MobileLayout>
        </>
      ) : (
        <>
          <HeroBackground2
            title={title}
            description={miniTitle}
            icon={''}
            banner={banner}
            bannerAlt={bannerAlt}
          />
          <BannerComponent />
          <PageLayout>
            <div className="px-4">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
                className="text-center font-sans font-normal text-[33px] lg:text-[24px] leading-[48px] lg:leading-[36px] text-blue-350 padding-container"
              >
                {description?.split('').map((char, index) => (
                  <motion.span
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 + index * 0.01 }}
                  >
                    {char}
                  </motion.span>
                ))}
              </motion.div>
            </div>
            {FilterDataLoading || loadingJobs ? (
              <div className="flex items-center justify-center">
                <JobsLoadingComponent />
              </div>
            ) : hasJobs ? (
              <JobSection hasJobs={hasJobs} stateJobs={stateJobs} />
            ) : (
              <div className="text-center font-normal font-sans text-[42px] leading-[88px] my-20">
                No jobs are currently available
              </div>
            )}
            <LandingReachUs />
            {/* <DropDownSection /> */}
            {/* <Register /> */}
            <NewCardComponent />
            {/* <InsightComponent /> */}
          </PageLayout>
        </>
      )}
    </>
  );
}
