export const FORM_ERROR_TIME = 3000;
export const COUNTRY_CODE = 153;

// Hero Status Data
export const YEAR_COUNT = 10;
export const CLIENT_COUNT = 1962;
export const JOBS_COUNT = 106;
export const CANDIDATE_COUNT = 1617;
export const YEAR_COUNT_TIME = 1.5;
export const CLIENT_COUNT_TIME = 2.5;
export const JOBS_COUNT_TIME = 2.5;
export const CANDIDATE_COUNT_TIME = 2.5;

// Pagination
export const PER_PAGE = 300;
export const CURRENT_PAGE = 1;
