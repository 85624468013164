export const PERMANENT_CONTACT_INFORMATION = [
  {
    id: 1,
    name: 'Doctor Division',
    call: '64 9 884 8634',
    email: 'medicalrecruitment@medfuture.co.nz',
    link: '',
  },
  {
    id: 2,
    name: 'GP Divison',
    call: '64 9 884 8634',
    email: 'gprecruitment@medfuture.co.nz',
    link: '',
  },
  {
    id: 3,
    name: 'SIMG Division',
    call: '64 9 884 8634',
    email: 'medicalrecruitment@medfuture.co.nz',
    link: '',
  },
  {
    id: 4,
    name: 'Oral Health Division',
    call: '64 9 884 8634',
    email: 'dentists@medfuture.co.nz',
    link: '',
  },
  {
    id: 5,
    name: 'Mental Health Division',
    call: '64 9 884 8634',
    email: 'psychologists@medfuture.co.nz',
    link: '',
  },
  {
    id: 6,
    name: 'AHP Division',
    call: '64 9 884 8634',
    email: 'ahp@medfuture.co.nz',
    link: '',
  },
  {
    id: 7,
    name: 'Nursing Division',
    call: '64 9 884 8634',
    email: 'nursing@medfuture.co.nz',
    link: '',
  },
  {
    id: 8,
    name: 'Premier Locum',
    call: '64 9 884 8634',
    email: 'locum@medfuture.co.nz',
    link: '',
  },
  {
    id: 9,
    name: 'Locum Division',
    call: '64 9 884 8634',
    email: 'locum@medfuture.co.nz',
    link: '',
  },
];

export const LOCUM_CONTACT_INFORMATION = [
  {
    id: 1,
    name: 'Permanent Recruitment',
    call: '64 9 884 8634',
    email: 'medicalrecruitment@medfuture.co.nz',
    link: '',
  },
  {
    id: 2,
    name: 'Temporary Recruitment',
    call: '64 9 884 8634',
    email: 'locum@medfuture.co.nz',
    link: '',
  },
  {
    id: 3,
    name: 'Locum Doctor Booking',
    call: '64 9 884 8634',
    email: 'locum@medfuture.co.nz',
    link: '',
  },
  {
    id: 4,
    name: 'Agency Nursing Booking',
    call: '64 9 884 8634',
    email: 'nursing@medfuture.co.nz',
    link: '',
  },
  {
    id: 5,
    name: 'Specialist International Medical Graduate Recruitment',
    call: '64 9 884 8634',
    email: 'medicalrecruitment@medfuture.co.nz',
    link: '',
  },
  {
    id: 6,
    name: 'International Allied Health Recruitment',
    call: '64 9 884 8634',
    email: 'ahp@medfuture.co.nz',
    link: '',
  },
  {
    id: 7,
    name: 'Nursing Locum Services',
    call: '64 9 884 8634',
    email: 'nursing@medfuture.co.nz',
    link: '',
  },
  // {
  //   id: 8,
  //   name: 'Outsource your Recruitment',
  //   call: '+44 (0) 1908 552820',
  //   email: 'Clientservices@medfuture.com.au',
  //   link: '',
  // },
  {
    id: 9,
    name: 'Emergency Doctor Services',
    call: '64 9 884 8634',
    email: 'gprecruitment@medfuture.co.nz',
    link: '',
  },
  // {
  //   id: 10,
  //   name: 'International Recruitment',
  //   call: '61 (02) 8527 0686',
  //   email: 'Clientservices@medfuture.com.au',
  //   link: '',
  // },
];
