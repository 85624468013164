import { useNavigate } from 'react-router-dom';
import Button from '../shared/button/Button';
import { motion } from 'framer-motion';

interface CommonHeroSectionProps {
  title1: string;
  title2: string;
  description: string;
  number: string;
  src: string;
  customers: Customers[];
}

interface Customers {
  url: string;
  id: number;
}

export default function CommonHeroSection({
  title1,
  title2,
  description,
  customers,
  number,
  src,
}: CommonHeroSectionProps) {
  const navigate = useNavigate();
  const renderGradientBackground = () => {
    const path = location.pathname;
    if (
      path === '/premier-locum-general-practitioner' ||
      path === '/premier-locum-doctor'
    ) {
      return 'bg-gradient-premierHero';
    } else if (path === '/spot-workforce') {
      return 'bg-gradient-spotHero';
    } else if (path === '/nursing') {
      return 'bg-gradient-nurse';
    } else {
      return null;
    }
  };

  const renderHeaderColor = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return 'text-gradient-GP';
    } else if (path === '/premier-locum-doctor' || path === '/spot-workforce') {
      return 'text-gradient-common';
    } else if (path === '/nursing') {
      return 'text-gradient-nursing';
    } else {
      return null;
    }
  };

  const renderBtnColor = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return 'locum-gold-btn';
    } else if (path === '/nursing') {
      return 'locum-pink-btn';
    } else {
      return 'locum-silver-btn';
    }
  };

  const navigateToRegister = () => {
    const registerForm = document.getElementById('register-reach-us');
    if (registerForm) {
      registerForm.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const textVariant = {
    initial: {
      x: -500,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <section>
      <div className={`max-width-container  ${renderGradientBackground()}`}>
        <div className="main-width-container">
          <motion.div
            className="flex items-center justify-between lg:mb-32 xl:mb-28 2xl:mb-40"
            variants={textVariant}
            initial="initial"
            animate="animate"
          >
            <div className="lg:mt-20 2xl:mt-10">
              <motion.h1
                variants={textVariant}
                className={`font-sans font-normal text-[60px] leading-[76px] ${renderHeaderColor()} lg:w-[460px] xl:w-[600px] 2xl:w-[650px] 4xl:w-[650px]`}
              >
                {title1}{' '}
                <motion.span
                  className="font-sans font-normal text-[50px] 2xl:text-[56px] 4xl:text-[60px] leading-[76px] text-white w-[600px] 2xl:w-[650px] 4xl:w-[690px]"
                  variants={textVariant}
                >
                  {title2}
                </motion.span>
              </motion.h1>
              <motion.p
                className="font-roboto text-white leading-[34px] lg:text-[18px] 2xl:text-[21px] font-light lg:w-[460px] xl:w-[540px] 2xl:w-[600px] 4xl:w-[650px]"
                variants={textVariant}
              >
                {description}
              </motion.p>
              <motion.div className="my-10" variants={textVariant}>
                <Button
                  type={'button'}
                  title={'Register Now'}
                  variant={renderBtnColor()}
                  handleClick={navigateToRegister}
                />
              </motion.div>
              <motion.div
                className="flex items-center justify-start gap-x-4"
                variants={textVariant}
              >
                <div className="relative flex">
                  {customers?.map((customer, index) => (
                    <div
                      key={customer.id}
                      className={`relative ${index !== 0 ? '-ml-4' : ''}`}
                      style={{ zIndex: customers.length - index }}
                    >
                      <img
                        src={customer.url}
                        alt="avatar"
                        className="h-[44px] w-[44px] rounded-full object-cover object-center border border-white"
                      />
                    </div>
                  ))}
                </div>
                <motion.div
                  className="font-roboto font-light leading-[34px] text-base text-white"
                  variants={textVariant}
                >
                  {number} Registered Doctors
                </motion.div>
              </motion.div>
            </div>
            <div className="lg:w-[390px] lg:h-[390px] 2xl:w-[508px] 2xl:h-[508px] bg-radial-gradient border border-white blur-border border-opacity-20 rounded-full relative mr-12 mt-12">
              <motion.div
                className="absolute 2xl:w-[600px] 4xl:w-[700px] 2xl:-top-14 4xl:-top-20 z-3 -right-10"
                initial={{ rotate: 0, scale: 1 }}
                animate={{ rotate: [0, 3, -3, 0], scale: [1, 1.1, 1.1, 1] }}
                transition={{
                  repeat: Infinity,
                  repeatType: 'reverse',
                  duration: 4,
                  ease: 'easeInOut',
                }}
              >
                <motion.img
                  src={src}
                  alt="card-image"
                  className="object-cover w-full h-full object-center"
                  loading="eager"
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.5 }}
                />
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
