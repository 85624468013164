import { getBaseUrl } from '@/config/BaseUrl';

export default async function EnquirySubmissionApi(
  name: string,
  email: string,
  message: string,
  mobile: string,
  page_type: string
) {
  try {
    const requestBody = {
      name: name,
      mobile: mobile,
      email: email,
      message: message,
      page_type: page_type,
    };
    const baseUrl = getBaseUrl();
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(
      `${baseUrl}web/enquiries/save`,
      requestOptions
    );

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message);
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error('Error submitting information:', error);
    throw error;
  }
}
