import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GetMajorCitiesApi from '@/api/GetMajorCitiesApi';

interface FilterJobsData {}

interface FetchJobFilterData {
  jobCountBySuberbs: FilterJobsData[] | null;
  jobCountLoading: boolean;
  filterDataError: Error | null;
}

export default function useFetchJobCountByState(): FetchJobFilterData {
  const navigate = useNavigate();
  const [jobCountBySuberbs, setJobCountSuburbs] = useState<
    FilterJobsData[] | null
  >(null);
  const [jobCountLoading, setLoading] = useState<boolean>(true);
  const [filterDataError, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetMajorCitiesApi();
      setJobCountSuburbs(response.data);
    } catch (error: any) {
      if (error.status === 429) {
        navigate('/many-requests', {
          state: { retryAfter: error.data.retry_after },
        });
      } else if (error.status === 500) {
        navigate('/server-error');
      } else {
        setError(error as Error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { jobCountBySuberbs, jobCountLoading, filterDataError };
}
