import React, { useEffect, useState } from 'react';
import CrossIcon from '@/assets/icons/CrossFilterIcon.png';
import AddIcon from '@/assets/icons/AddFilterIcon.png';

const LocumJobFilter = ({
  data,
  allStates,
  professionId,
  specialtyId,
  seniorityId,
  stateId,
  onProfessionChange,
  onSpecialtyChange,
  onSeniorityChange,
  onStateChange,
  onSuburbSelect,
  suburbId,
  regionId,
  divisionId,
  onDivisionChange,
  onRegionChange,
}) => {
  const [selectedDivisionId, setSelectedDivisionId] = useState(
    divisionId || null
  );
  const [selectedProfession, setSelectedProfession] = useState(
    professionId || null
  );
  const [selectedSpecialty, setSelectedSpecialty] = useState(
    specialtyId || null
  );
  const [selectedSeniority, setSelectedSeniority] = useState(
    seniorityId || null
  );
  const [selectedState, setSelectedState] = useState(stateId || null);
  const [selectedRegion, setSelectedRegion] = useState(regionId || null);
  const [selectedCity, setSelectedCity] = useState(suburbId || null);
  const [specialties, setSpecialties] = useState([]);
  const [seniorities, setSeniorities] = useState([]);
  const [openDivision, setOpenDivision] = useState(null);
  const [openState, setOpenState] = useState({});
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setSelectedProfession(professionId);
  }, [professionId]);

  useEffect(() => {
    setSelectedSpecialty(specialtyId);
  }, [specialtyId]);

  useEffect(() => {
    setSelectedSeniority(seniorityId);
  }, [seniorityId]);

  useEffect(() => {
    setSelectedState(stateId);
  }, [stateId]);

  useEffect(() => {
    setSelectedRegion(regionId);
  }, [stateId, regionId]);

  useEffect(() => {
    setSelectedDivisionId(divisionId);
  }, [divisionId]);

  useEffect(() => {
    setSelectedRegion(regionId);
  }, [regionId]);

  // useEffect(() => {
  //   setSelectedCity(selectedCity);
  // }, [suburbId]);

  useEffect(() => {
    setSelectedCity(selectedCity);
  }, [stateId, regionId, suburbId]);

  useEffect(() => {
    if (regionId && allStates) {
      const initialRegion = allStates
        .flatMap((state) => state.regions)
        .find((region) => region.regions_id === regionId);
      if (initialRegion) {
        // setSelectedSuburb(initialRegion.suberb || []);
        setCities(initialRegion.suburb || []);
      }
    }
  }, [regionId, allStates]);

  useEffect(() => {
    if (selectedState && allStates) {
      const state = allStates.find((state) => state.state_id === selectedState);
      if (state && !selectedRegion) {
        setSelectedRegion(state.regions[0].region_id);
      }
    }
  }, [selectedState, allStates, selectedRegion]);

  useEffect(() => {
    if (selectedProfession && data) {
      const divisionWithProfession = data.find((division) =>
        division.professions.some(
          (profession) => profession.profession_id === selectedProfession
        )
      );

      const profession = data.find((division) =>
        division.professions.some((p) => p.profession_id === selectedProfession)
      );
      if (profession) {
        setOpenDivision(profession.divisions_id);
        const professionData = profession.professions.find(
          (p) => p.profession_id === selectedProfession
        );
        setSpecialties(professionData.specilities || []);
        setSeniorities(professionData.seniorities || []);
      }

      if (divisionWithProfession) {
        setOpenDivision(divisionWithProfession.divisions_id);
        setSelectedDivisionId(divisionWithProfession.divisions_id);
        onDivisionChange(divisionWithProfession.divisions_id);
      }
    }
  }, [selectedProfession, data]);

  const handleDivisionToggle = (divisionId) => {
    setOpenDivision(openDivision === divisionId ? null : divisionId);
  };

  const handleDivisionChange = (divisionId) => {
    setSelectedDivisionId(divisionId);
    onDivisionChange(divisionId);

    if (selectedProfession) {
      const relatedProfession = data.find((division) =>
        division.professions.some(
          (profession) =>
            profession.profession_id === selectedProfession &&
            division.divisions_id === divisionId
        )
      );
      if (!relatedProfession) {
        setSelectedProfession(null);
      }
    }
  };

  useEffect(() => {
    if (regionId && allStates) {
      const stateContainingRegion = allStates.find((state) =>
        state.regions.some((region) => region.regions_id === regionId)
      );

      if (stateContainingRegion) {
        setSelectedState(stateContainingRegion.state_id);
        setOpenState((prevState) => ({
          ...prevState,
          [stateContainingRegion.state_id]: true,
        }));
      }

      setSelectedRegion(regionId);
      setOpenState((prevState) => ({
        ...prevState,
        [stateContainingRegion.state_id]: true,
      }));
    }
  }, [regionId, allStates]);

  const handleProfessionChange = (professionId) => {
    setSelectedProfession(professionId);
    onProfessionChange(professionId);
  };

  const handleSpecialtyChange = (specialtyId) => {
    setSelectedSpecialty(specialtyId);
    onSpecialtyChange(specialtyId);
  };

  const handleSeniorityChange = (seniorityId) => {
    setSelectedSeniority(seniorityId);
    onSeniorityChange(seniorityId);
  };

  const handleStateChange = (stateId) => {
    setSelectedState(stateId);
    onStateChange(stateId);
    setSelectedRegion(null);
    setSelectedCity(null);
  };

  const handleRegionChange = (regionId) => {
    setSelectedRegion(regionId);
    onRegionChange(regionId);

    const relatedState = allStates?.find((state) =>
      state.regions.some((region) => region.regions_id === regionId)
    );

    if (relatedState) {
      setSelectedState(relatedState.state_id);
      onStateChange(relatedState.state_id);
    }

    const selectedRegionData = allStates
      .flatMap((state) => state.regions)
      .find((region) => region.regions_id === regionId);
    if (selectedRegionData) {
      // setSelectedSuburb(selectedRegionData.suberb);
      setCities(selectedRegionData.suburb);
    }
  };

  const handleSuburbClick = (suburbId) => {
    setSelectedCity(suburbId);
    onSuburbSelect(suburbId);
  };

  useEffect(() => {
    // Initialize all states to be open initially
    const initialOpenState = allStates.reduce((acc, state) => {
      acc[state.state_id] = true;
      return acc;
    }, {});
    setOpenState(initialOpenState);
  }, [allStates]);

  return (
    <div className="bg-white drop-shadow-2xl border border-gray-80 rounded-b-md p-2 w-[290px] md:w-[370px] md:p-4 lg:p-8 lg:w-[480px]">
      <div className="border border-gray-440 my-5 lg:my-2 p-3 rounded-md">
        <h2 className="font-sans font-bold text-lg lg:text-[20px] leading-[24px] text-blue-700 border-b-[0.5px] border-gray-440 pb-2">
          Professions
        </h2>
        {data?.map((division) => (
          <div key={division.divisions_id}>
            <div
              onClick={() => handleDivisionToggle(division.divisions_id)}
              className="flex items-center justify-between mb-1"
            >
              <div className="flex items-center">
                <input
                  className="radio-btn"
                  type="radio"
                  id={`division-${division.divisions_id}`}
                  name="division"
                  value={division.divisions_id}
                  checked={selectedDivisionId === division.divisions_id}
                  onChange={() => handleDivisionChange(division.divisions_id)}
                />
                <h3 className="font-sans font-normal text-sm lg:text-[17px] leading-[32px] text-blue-700 ml-3">
                  {division.name}
                </h3>
              </div>
              <div className="w-[27px] h-[27px] rounded-full bg-gray-420 flex items-center justify-center cursor-pointer">
                <img
                  src={
                    openDivision === division.divisions_id ? CrossIcon : AddIcon
                  }
                  className="w-[15px] h-[15px] object-contain"
                />
              </div>
            </div>
            {openDivision === division.divisions_id && (
              <div>
                {division.professions?.map((profession) => (
                  <div
                    key={profession.profession_id}
                    className="ml-4 text-gray-480 text-xs lg:text-[15px] leading-8 flex items-center"
                  >
                    <input
                      className="radio-btn"
                      type="radio"
                      id={`profession-${profession.profession_id}`}
                      name="profession"
                      value={profession.profession_id}
                      checked={selectedProfession === profession.profession_id}
                      onChange={() =>
                        handleProfessionChange(profession.profession_id)
                      }
                    />
                    <label
                      className="ml-3 cursor-pointer"
                      htmlFor={`profession-${profession.profession_id}`}
                    >
                      {profession.name}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      {selectedProfession ? (
        <>
          <div className="border border-gray-440 my-5 lg:my-2 p-3 rounded-md">
            <div className="flex items-center justify-between border-b-[0.5px] border-gray-440 pb-2">
              <h2 className="font-sans font-bold text-lg lg:text-[20px] leading-[24px] text-blue-700">
                Specialty
              </h2>
              <button
                className="ml-2 text-sm text-gray-500"
                onClick={() => handleSpecialtyChange(null)}
              >
                Clear
              </button>
            </div>
            {specialties.length > 0 ? (
              specialties.map((specialty) => (
                <div
                  key={specialty.specialities_id}
                  className="ml-4 text-gray-480 text-xs lg:text-[15px] leading-8 flex items-center"
                >
                  <input
                    className="radio-btn"
                    type="radio"
                    id={`specialty-${specialty.specialities_id}`}
                    name="specialty"
                    value={specialty.specialities_id}
                    checked={selectedSpecialty === specialty.specialities_id}
                    onChange={() =>
                      handleSpecialtyChange(specialty.specialities_id)
                    }
                  />
                  <label
                    htmlFor={`specialty-${specialty.specialities_id}`}
                    className="ml-3"
                  >
                    {specialty.name}
                  </label>
                </div>
              ))
            ) : (
              <p>No specialties available</p>
            )}
          </div>
          <div className="border border-gray-440 my-5 lg:my-2 p-3 rounded-md">
            <div className="flex items-center justify-between border-b-[0.5px] border-gray-440 pb-2">
              <h2 className="font-sans font-bold text-lg lg:text-[20px] leading-[32px] text-blue-700 ">
                Seniority
              </h2>
              <button
                className="ml-2 text-sm text-gray-500"
                onClick={() => handleSeniorityChange(null)}
              >
                Clear
              </button>
            </div>
            {seniorities.length > 0 ? (
              seniorities.map((seniority) => (
                <div
                  key={seniority.seniorities_id}
                  className="ml-4 text-gray-480 text-xs lg:text-[15px] leading-8 flex items-center"
                >
                  <input
                    className="radio-btn"
                    type="radio"
                    id={`seniority-${seniority.seniorities_id}`}
                    name="seniority"
                    value={seniority.seniorities_id}
                    checked={selectedSeniority === seniority.seniorities_id}
                    onChange={() =>
                      handleSeniorityChange(seniority.seniorities_id)
                    }
                  />
                  <label
                    htmlFor={`seniority-${seniority.seniorities_id}`}
                    className="ml-3"
                  >
                    {seniority.name}
                  </label>
                </div>
              ))
            ) : (
              <p>No seniorities available</p>
            )}
          </div>
        </>
      ) : (
        <div className="my-5 lg:my-2">
          <h2 className="font-sans font-bold text-[20px] leading-[24px] text-blue-700 border-b-[0.5px] border-gray-440 pb-2">
            Specialty
          </h2>
          <p className="p-2 text-gray-480">
            Please select a profession to check specialties.
          </p>
          <h2 className="font-sans font-bold text-[20px] leading-[32px] text-blue-700 border-b-[0.5px] border-gray-440 pb-2 mt-4">
            Seniority
          </h2>
          <p className="p-2 text-gray-480">
            Please select a profession to check seniorities.
          </p>
        </div>
      )}

      <div className="border border-gray-440 my-5 lg:my-2 p-3 rounded-md">
        <h2 className="font-sans font-bold text-lg lg:text-[20px] leading-[24px] text-blue-700 border-b-[0.5px] border-gray-440 pb-2">
          Location
        </h2>
        <div>
          {allStates && allStates.length > 0 ? (
            allStates.map((state) => (
              <div
                key={state.id}
                className="ml-4 text-gray-480 text-xs lg:text-[15px] leading-8 "
              >
                <div className="flex items-center justify-between mb-1">
                  <div>
                    <input
                      className="radio-btn"
                      type="radio"
                      id={`state-${state.state_id}`}
                      name="state"
                      value={state.state_id}
                      checked={selectedState === state.state_id}
                      onChange={() => handleStateChange(state.state_id)}
                    />
                    <label htmlFor={`state-${state.state_id}`} className="ml-3">
                      {state.name}
                    </label>
                  </div>
                  <div className="w-[27px] h-[27px] rounded-full bg-gray-420 flex items-center justify-center cursor-pointer">
                    <img
                      src={openState[state.state_id] ? CrossIcon : AddIcon}
                      className="w-[15px] h-[15px] object-cover"
                      onClick={() =>
                        setOpenState({
                          ...openState,
                          [state.state_id]: !openState[state.state_id],
                        })
                      }
                    />
                  </div>
                </div>
                {openState[state.state_id] && (
                  <div className="ml-8">
                    {state.regions.map((region) => (
                      <div key={region.regions_id}>
                        <div className="">
                          <div>
                            <input
                              className="radio-btn"
                              type="radio"
                              id={`region-${region.regions_id}`}
                              name="region"
                              value={region.regions_id}
                              checked={selectedRegion === region.regions_id}
                              onChange={() =>
                                handleRegionChange(region.regions_id)
                              }
                            />
                            <label
                              htmlFor={`region-${region.regions_id}`}
                              className="ml-4"
                            >
                              {region.name}
                            </label>
                          </div>
                          {selectedRegion === region.regions_id && (
                            <div className="grid grid-cols-3 gap-4 my-3">
                              {region.suburb?.map((city) => (
                                <div key={city.suberbs_id} className="">
                                  <button
                                    className={`w-[110px] text-xs lg:text-base py-3 text-center text-wrap cursor-pointer rounded-md focus:outline-none ${
                                      selectedCity === city.suberbs_id
                                        ? 'bg-gradient-to-r from-blue-100 to-blue-150 text-white'
                                        : 'bg-gray-200'
                                    }`}
                                    onClick={() =>
                                      handleSuburbClick(city.suberbs_id)
                                    }
                                  >
                                    {city.name}
                                  </button>
                                </div>
                              ))}
                            </div>
                          )}

                          {/* {selectedRegion === region.regions_id && (
                            <div className="grid grid-cols-3 gap-4 my-3">
                              {region.suberb?.map((city) => (
                                <div key={city.suberbs_id} className="">
                                  <button
                                    className={`w-[110px] text-xs lg:text-base py-3 text-center text-wrap cursor-pointer rounded-md focus:outline-none ${
                                      selectedCity === city.suberbs_id
                                        ? 'bg-gradient-to-r from-blue-100 to-blue-150 text-white'
                                        : 'bg-gray-200'
                                    }`}
                                    onClick={() =>
                                      handleSuburbClick(city.suberbs_id)
                                    }
                                  >
                                    {city.name}
                                  </button>
                                </div>
                              ))}
                            </div>
                          )} */}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No states available</p>
          )}
        </div>
        {/* <div className="grid grid-cols-3 gap-4 mt-6">
          {selectedRegion &&
            cities?.map((city) => (
              <div key={city.suberbs_id} className="">
                <button
                  className={`w-[120px] py-4 text-center text-wrap cursor-pointer rounded-md focus:outline-none ${
                    selectedCity === city.suberbs_id
                      ? 'bg-gradient-to-r from-blue-100 to-blue-150 text-white'
                      : 'bg-gray-200'
                  }`}
                  onClick={() => handleSuburbClick(city.suberbs_id)}
                >
                  {city.name}
                </button>
              </div>
            ))}
        </div> */}
      </div>
    </div>
  );
};
export default LocumJobFilter;
