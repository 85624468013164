interface LocumFacilityProps {
  facility: Facility;
  userRegistered: boolean;
  index: number;
}

interface Facility {
  url: string;
  title: string;
  description: string;
}

export default function MoreFacility({
  facility,
  userRegistered,
  index,
}: LocumFacilityProps) {
  const isAccessible = userRegistered || index < 2;

  return (
    <div
      className={`bg-white shadow-lg px-20 py-9 rounded-md ${
        !isAccessible ? 'blur' : ''
      }`}
    >
      {isAccessible && (
        <div className="w-[69px] h-[69px] rounded-full my-4">
          <img
            src={facility.url}
            alt="img"
            className="w-full h-full object-contain object-center"
          />
        </div>
      )}
      <h2 className="font-sans font-normal text-[28px] leading-8 text-blue-460 py-3">
        {facility.title}
      </h2>
      <p className="font-sans font-normal 2xl:text-base 2xl:leading-[26px] 4xl:text-lg leading-7 text-blue-440">
        {isAccessible ? facility.description : ''}
      </p>
    </div>
  );
}
