import { useState, useEffect, useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import MajorCitiesData from '@/components/home/cities/MajorCitiesData';
import left_arrow from '/icons/left.png';
import right_arrow from '/icons/right.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { motion } from 'framer-motion';

export default function MajorCities({ jobCountBySuberbs }) {
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [shortName, setShortName] = useState(null);
  const [suburbData, setSuburbData] = useState([]);
  const sliderRef = useRef(null);

  useEffect(() => {
    if (jobCountBySuberbs && jobCountBySuberbs.length > 0) {
      setSelectedStateId(jobCountBySuberbs[0].state_id);
    }
  }, [jobCountBySuberbs]);

  useEffect(() => {
    const selectedState = jobCountBySuberbs?.find(
      (state) => state.state_id === selectedStateId
    );
    if (selectedState) {
      setShortName(selectedState.state_image_alt);
      const allSuburbs = selectedState.suburbs || [];
      setSuburbData(allSuburbs);
    }
  }, [selectedStateId, jobCountBySuberbs]);

  const handleStateClick = (stateId) => {
    setSelectedStateId(stateId);
  };

  const settings: Settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function CustomPrevArrow(props) {
    const { currentSlide, slideCount, ...restProps } = props;
    return (
      <div
        {...restProps}
        className="slick-arrow-blue-custom slick-prev-blue-custom"
      >
        <img src={left_arrow} alt="Left Arrow" />
      </div>
    );
  }

  function CustomNextArrow(props) {
    const { currentSlide, slideCount, ...restProps } = props;
    return (
      <div
        {...restProps}
        className="slick-arrow-blue-custom slick-next-blue-custom"
      >
        <img src={right_arrow} alt="Right Arrow" />
      </div>
    );
  }

  const Variant = {
    initial: {
      scale: 0.8,
      opacity: 0.5,
    },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.2,
      },
    },
  };

  const textVariant = {
    initial: {
      x: -80,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <motion.section className="padding-container mb-20">
      <motion.div className="relative">
        <motion.div
          variants={textVariant}
          initial="initial"
          whileInView="animate"
        >
          <motion.h2 className="section_header_text" variants={textVariant}>
            Jobs By Major Cities
          </motion.h2>
        </motion.div>
        <div>
          <Slider {...settings} ref={sliderRef} className="">
            {jobCountBySuberbs?.map((state, index) => (
              <div key={index}>
                <div
                  onClick={() => handleStateClick(state.state_id)}
                  className={`cursor-pointer mb-16 preferences-topic py-4 px-2 rounded-md text-center ${
                    selectedStateId === state.state_id
                      ? 'bg-gradient-to-r from-blue-500 to-blue-150 text-white  border border-blue-400 transition duration-300'
                      : 'text-blue-700 hover:text-gray-120'
                  }`}
                >
                  {state.state_name}
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <motion.div
          className="h-[658px]"
          variants={Variant}
          initial="initial"
          whileInView="animate"
        >
          <MajorCitiesData data={suburbData} />
        </motion.div>
        <div>
          <p className="city-topic-main absolute lg:top-[87%] 2xl:top-[85%] 4xl:top-[92%] ">
            {shortName}
          </p>
        </div>
      </motion.div>
    </motion.section>
  );
}
