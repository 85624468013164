import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GetAllStatesApi from '@/api/selectors/GetStatesApi';

interface StateData {
  state_id: number;
  country_id: string;
  name: string;
  description: string | null;
  status: number;
  image: string | null;
  image_alt: string;
  created_by: string | null;
  updated_by: string | null;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  position: string;
  short_name: string | null;
}

interface FetchAllSpecialty {
  allStates: StateData[] | null;
  stateLoading: boolean;
  error: Error | null;
}

export default function useFetchAllState(): FetchAllSpecialty {
  const navigate = useNavigate();
  const [allStates, setAllStates] = useState<StateData[] | null>(null);
  const [stateLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetAllStatesApi();
      setAllStates(response);
      setError(null);
    } catch (error: any) {
      if (error.status === 429) {
        navigate('/many-requests', {
          state: { retryAfter: error.data.retry_after },
        });
      } else {
        setError(error as Error);
        setAllStates(null);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { allStates, stateLoading, error };
}
