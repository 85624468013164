import Button from '@/components/shared/button/Button';
import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import calculateDaysDifference from '@/utils/getNoOfDatesUtil';

export default function MainMobileJobCard({ data }) {
  console.log(data);
  return (
    <div
      className="bg-white rounded-md drop-shadow-lg p-4 w-[307px] h-[368px] mx-1 mt-2 mb-4"
      key={data?.jobdetails_id}
    >
      <h2 className="font-satoshi font-medium text-[20px] leading-[26px] text-blue-700 line-clamp-2 h-[56px]">
        {data?.job_title}
      </h2>
      <p className="font-sans font-normal text-xs leading-[30px] text-gray-700">
        {calculateDaysDifference(data?.commencement_date)} days
      </p>
      <h2 className="font-satoshi font-medium text-lg leading-[35px] text-blue-550">
        {data?.profession.name}
      </h2>
      <div className="flex items-center my-1">
        <div className="h-[28px] w-[28px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
          <img
            src={`${AWS_BASE_ASSETS_ICONS}CurrencyIcon.png`}
            alt="Salary Icon"
            className="w-[14.8px] h-[14.8px] object-contain"
          />
        </div>
        <p className="font-satoshi font-medium text-[13px] leading-[20px] text-gray-700">
          {data?.billing_share && data?.hourly_fee ? (
            <p className="line-clamp-1">{`${data?.hourly_fee} or ${data?.billing_share}`}</p>
          ) : (
            <p className="line-clamp-1">
              {data?.hourly_fee || data?.billing_share}
            </p>
          )}
        </p>
      </div>
      <div className="flex items-center  my-1">
        <div className="h-[28px] w-[28px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
          <img
            src={`${AWS_BASE_ASSETS_ICONS}LocationIcon.png`}
            alt="Salary Icon"
            className="w-[14.8px] h-[15.3px] object-contain"
          />
        </div>
        <p className="font-satoshi font-medium text-[13px] leading-[20px] text-gray-700">
          {`${data?.suburb?.name} , ${data?.state?.name}`}
        </p>
      </div>
      <div className="flex items-center  my-1">
        <div className="h-[28px] w-[28px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
          <img
            src={`${AWS_BASE_ASSETS_ICONS}ClockIcon.png`}
            alt="Salary Icon"
            className="w-[14.8px] h-[14.8px] object-contain "
          />
        </div>
        <p className="font-satoshi font-medium text-[13px] leading-[20px] text-gray-700">
          {data?.engagement_type?.name}
        </p>
      </div>
      <div className="flex items-center  my-1">
        <div className="h-[28px] w-[28px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
          <img
            src={`${AWS_BASE_ASSETS_ICONS}JobTypeIcon.png`}
            alt="Salary Icon"
            className="w-[13.34px] h-[12.13px] object-contain"
          />
        </div>
        <p className="font-satoshi font-medium text-[13px] leading-[20px] text-gray-700">
          {data?.is_permanent === '1' ? 'Permanent' : 'Locum'}
        </p>
      </div>
      <div className="mt-5">
        <Button
          type="submit"
          title="Apply Now"
          variant="find-jobs-mobile-btn "
        />
      </div>
    </div>
  );
}
