import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function JobsLoadingComponent() {
  return (
    <div className="w-[480px] h-[520px] shadow-md rounded-md mr-3 cursor-pointer my-[2px]">
      <div className="flex flex-col card-text-items">
        <div className="bg-gray-200 p-5 relative">
          <div className="flex items-center justify-between relative">
            <div className="flex items-center justify-normal mb-1">
              <h3 className="card-text-main mr-4">
                <Skeleton width={100} />
              </h3>
              <h3 className="text-gray-480 font-semibold bg-gray-20 border rounded-md border-gray-320 px-[6px] text-sm">
                <Skeleton width={50} />
              </h3>
            </div>
            <div className="absolute top-0 right-2">
              <Skeleton width={43} height={80} />
            </div>
          </div>
          <div className="flex items-center mb-1">
            <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
              <Skeleton width={15} height={15} />
            </div>
            <p className="line-clamp-1">
              <Skeleton width={150} />
            </p>
          </div>
          <div className="flex items-center my-1">
            <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
              <Skeleton width={15} height={15} />
            </div>
            <p className="line-clamp-1">
              <Skeleton width={150} />
            </p>
          </div>
          <div className="flex items-center my-1">
            <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
              <Skeleton width={15} height={15} />
            </div>
            <p className="line-clamp-1">
              <Skeleton width={150} />
            </p>
          </div>
          <div className="flex items-center my-1">
            <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
              <Skeleton width={15} height={15} />
            </div>
            <p className="line-clamp-1">
              <Skeleton width={150} />
            </p>
          </div>
          <div className="absolute bottom-4 right-4 font-normal font-sans text-sm leading-9">
            <Skeleton width={100} />
          </div>
        </div>
        <div className="p-5">
          <h2 className="font-sans font-normal text-[26px] leading-[34px] text-blue-700 h-[73px] line-clamp-2">
            <Skeleton width={`60%`} />
          </h2>
          <div className="h-[97px]">
            <p className="font-satoshi text-base leading-[26px] font-normal text-gray-700 line-clamp-3 mt-5">
              <Skeleton count={3} />
            </p>
          </div>
          <div className="mt-10 flex items-center justify-between gap-x-2">
            <Skeleton width={100} height={40} />
            <Skeleton width={100} height={40} />
          </div>
        </div>
      </div>
    </div>
  );
}
