import Button from '@/components/shared/button/Button';

export default function ContactMobileHero() {
  return (
    <section className="max-width-container">
      <div className="mobile-container py-[1.125rem] md:py-8">
        <div className="mobile-padding-container bg-mobile-contact-hero-img bg-cover bg-blue-500 rounded-[20px] h-[220px] md:h-[280px] p-10 md:p-14 flex flex-col items-center justify-center">
          <h2 className="font-sans font-bold text-[20px] md:text-[30px] leading-[32px] md:leading-[44px] text-center text-white mb-4 md:mb-6">
            We'd Love to Hear From You
          </h2>
          <img
            src="/images/MobileMap.png"
            alt="Contact Us"
            className="w-[285px] md:w-[350px] h-auto"
          />
        </div>
        <div className="py-8">
          <Button
            type={'button'}
            title={'Refer a Friend'}
            variant="find-jobs-btn"
          />
        </div>
      </div>
    </section>
  );
}
