import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useStateContext } from '@/context/ContextProvider';
import CloseIcon from '/icons/MobileClose.png';
import Button from '@/components/shared/button/Button';
import { ICON_FOR_LINKS, MOBILE_MENU_LINKS } from '@/constants/NavbarConstants';
import { Link } from 'react-router-dom';
import UpArrow from '@/assets/icons/upArrow.png';
import DownArrow from '@/assets/icons/downArrow.png';
import useFetchBottomMenuData from '@/components/navbar/hook/useBottomNavBar';
import { AWS_BASE_ASSETS_MENU_ICONS } from '@/config/seviceApiConfig';

const MobileMainMenu: React.FC = () => {
  const { closeMainMenu } = useStateContext();
  const { bottomMenuData } = useFetchBottomMenuData();
  const [expandedLinks, setExpandedLinks] = useState<{
    [key: string]: boolean;
  }>({});

  const handleCloseMenu = () => {
    closeMainMenu();
  };

  const toggleSubLinks = (key: string) => {
    setExpandedLinks((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <motion.div
      className="fixed inset-0 z-[12] bg-mobile-navbar-gradient w-[366px] md:w-[480px] p-6 overflow-y-auto"
      initial={{ x: '-70%', opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ type: 'spring', stiffness: 50, duration: 2 }}
    >
      <div className="flex items-center justify-between">
        <motion.img
          src={CloseIcon}
          onClick={handleCloseMenu}
          className="object-cover cursor-pointer"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.2 }}
        />
        <div className="w-[114px]">
          <Button
            type={'button'}
            title={'Sign up'}
            variant={'mobile-sign-up-btn'}
          />
        </div>
        <div className="w-[114px] border border-white">
          <Button
            type={'button'}
            title={'Sign in'}
            variant={'mobile-sign-in-btn'}
          />
        </div>
      </div>
      <div className="mt-10 border-b-[0.25px] border-gray-200"></div>
      <div className="mt-4">
        {MOBILE_MENU_LINKS.map((link) => (
          <div key={link.key}>
            <div
              className="flex items-center justify-between gap-4 py-3"
              onClick={() => toggleSubLinks(link.key)}
            >
              <div className="flex items-center gap-4">
                <div className="w-[20px] h-[20px]">
                  <motion.img
                    src={link.icon}
                    alt={link.label}
                    className="object-cover object-center"
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.2 }}
                  />
                </div>
                <Link
                  className="text-[15px] font-normal text-white leading-[20px]"
                  to={link.href}
                >
                  {link.label}
                </Link>
              </div>
              {link.subLinks && (
                <motion.img
                  src={expandedLinks[link.key] ? UpArrow : DownArrow}
                  alt="Toggle"
                  className="w-[10px] h-[10px] cursor-pointer"
                  animate={{ rotate: expandedLinks[link.key] ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                />
              )}
            </div>
            {expandedLinks[link.key] && link.subLinks && (
              <div className="pl-8">
                {link.subLinks.map((sublink) => (
                  <Link
                    key={sublink.id}
                    className="block py-2 text-[14px] font-light text-white"
                    to={sublink.href}
                  >
                    {sublink.label}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="">
        {bottomMenuData?.map((bottomLink, index) => (
          <div key={`bottom-${index}`}>
            <div
              className="flex items-center justify-between gap-4 py-3"
              onClick={() => toggleSubLinks(`bottom-${index}`)}
            >
              <div className="flex items-center gap-4">
                <div className="w-[20px] h-[20px]">
                  <motion.img
                    src={ICON_FOR_LINKS[index].icon}
                    alt={bottomLink.name}
                    className="object-cover object-center"
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.2 }}
                  />
                </div>
                <Link className="text-[15px] font-normal text-white" to={''}>
                  {bottomLink.name}
                </Link>
              </div>
              <motion.img
                src={expandedLinks[`bottom-${index}`] ? UpArrow : DownArrow}
                alt="Toggle"
                className="w-[10px] h-[10px] ml-2 cursor-pointer"
                animate={{ rotate: expandedLinks[`bottom-${index}`] ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              />
            </div>
            {expandedLinks[`bottom-${index}`] && (
              <>
                {bottomLink.professions?.map((profession) =>
                  profession.specilities?.map((speciality) => (
                    <div
                      key={speciality.specialities_id}
                      className="block py-2 text-[14px] ml-10 font-light text-white"
                    >
                      <div className="flex items-center justify-normal gap-x-4">
                        <img
                          src={`${AWS_BASE_ASSETS_MENU_ICONS}${speciality.menu_icon}`}
                          className="w-[20px] h-[20px] object-contain object-center"
                        />
                        {speciality.short_name}
                      </div>
                    </div>
                  ))
                )}
                <div className="ml-20 mt-5">
                  <h6 className="text-[15px] font-normal text-white leading-[20px] mb-4">
                    Explore More
                  </h6>
                  {bottomLink.professions?.map((profession) =>
                    profession.seniorities?.map((seniority) => (
                      <div
                        key={seniority.seniorities_id}
                        className="block py-2 text-[14px] ml-7 font-light text-white"
                      >
                        {seniority.short_name}
                      </div>
                    ))
                  )}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default MobileMainMenu;
