import {
  CardPageHero,
  CommonHeroSection,
  FrequentQuestions,
  GratitudeComponent,
  InsightComponent,
  LocumFacility,
  LocumOffer,
  LocumRegister,
  MobileBanner,
  MobileCardFacility,
  MobileGratitude,
  MobileInsight,
  MobileLocumOffer,
  MobileOfferDropDown,
} from '@/components';
import { MobileLayout, PageLayout } from '@/layout';
import LocumOfferImg from '/images/DoctorCard.png';
import useResponsive from '@/hooks/useResponsive';
import FacilityData from '@/data/MoreFacility.json';
import OfferData from '@/data/dropdownquestion.json';
import FeedbackData from '@/data/feedback.json';
import InsightData from '@/data/insight.json';
import data from '@/data/doctor.json';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';
import useScaleInView from '@/hooks/useScaleInView';
import { motion } from 'framer-motion';

export default function PremierLocumDoctor() {
  const { isMobile } = useResponsive();
  const [locumOfferControls, locumOfferRef] = useScaleInView();
  const [facilityControls, facilityRef] = useScaleInView();
  const [registerControls, registerRef] = useScaleInView();
  const [gratitudeControls, gratitudeRef] = useScaleInView();
  const [questionsControls, questionsRef] = useScaleInView();
  const [insightControls, insightRef] = useScaleInView();
  return (
    <>
      {isMobile ? (
        <>
          <CardPageHero
            MainTitle={'Premier Locum '}
            SubTitle={'Doctor'}
            src={`${AWS_BASE_URL_ASSETS}/LocumDoctor.png`}
          />
          <MobileLayout bgColor="blue-20">
            <MobileCardFacility data={FacilityData} />
          </MobileLayout>
          <MobileLayout>
            <MobileLocumOffer
              description1={
                'Premier Locum General Practitioner is all about Locum Jobs for our elite group of General Practitioners who seek high guaranteed income throughout the Locum engagement while experiencing various benefits such as Flexibility, No Locking Contract, No Kilometer restrictions, complete administrative support, and Various Bonus Income Benefits on the go.'
              }
              description2={
                'Working with more than 4000 general practitioner clinics across Australia, Medfuture can provide you the Locum Assignment wherever in Australia A GP Wants. Medfuture provides GP Locum jobs across Various States, Capital cities, and Other Major Towns of Australia.'
              }
              description3={
                'We Ensure Top Offers, Flexibility, Accommodation, and Travel Arrangements. We are quite experts in providing Lengthy Locum offers starting from 3 Months to ongoing Locum Jobs for Eligible General Practitioners'
              }
            />
            {/* <MobileOfferDropDown data={OfferData} /> */}
            <MobileBanner />
            <MobileGratitude data={FeedbackData} />
            {/* <MobileInsight data={InsightData} /> */}
          </MobileLayout>
        </>
      ) : (
        <>
          <CommonHeroSection
            src={`${AWS_BASE_URL_ASSETS}/LocumDoctor.png`}
            title1={'Premier Locum'}
            title2={'Doctor'}
            description={
              'Working with more than 4000 general practitioner clinics across Australia, Medfuture can provide you the Locum Assignment wherever in Australia A GP Wants.'
            }
            number={'153'}
            customers={data}
          />
          <PageLayout>
            <motion.div
              ref={locumOfferRef}
              initial={{ scale: 0.8 }}
              animate={locumOfferControls}
            >
              <LocumOffer
                src={LocumOfferImg}
                description1={
                  'Premier Locum General Practitioner is all about Locum Jobs for our elite group of General Practitioners who seek high guaranteed income throughout the Locum engagement while experiencing various benefits such as Flexibility, No Locking Contract, No Kilometer restrictions, complete administrative support, and Various Bonus Income Benefits on the go.'
                }
                description2={
                  'Working with more than 4000 general practitioner clinics across Australia, Medfuture can provide you the Locum Assignment wherever in Australia A GP Wants. Medfuture provides GP Locum jobs across Various States, Capital cities, and Other Major Towns of Australia.'
                }
                description3={
                  'We Ensure Top Offers, Flexibility, Accommodation, and Travel Arrangements. We are quite experts in providing Lengthy Locum offers starting from 3 Months to ongoing Locum Jobs for Eligible General Practitioners'
                }
              />
            </motion.div>
          </PageLayout>
          <PageLayout bgColor="blue-480">
            <motion.div
              ref={facilityRef}
              initial={{ scale: 0.8 }}
              animate={facilityControls}
            >
              <LocumFacility />
            </motion.div>
            <motion.div
              ref={registerRef}
              initial={{ scale: 0.8 }}
              animate={registerControls}
            >
              <LocumRegister />
            </motion.div>
            <motion.div
              ref={gratitudeRef}
              initial={{ scale: 0.8 }}
              animate={gratitudeControls}
            >
              <GratitudeComponent
                mainTitle="Refer and Earn"
                subTitle="2500 AUD$"
              />
            </motion.div>
          </PageLayout>
          <PageLayout>
            {/* <motion.div
              ref={questionsRef}
              initial={{ scale: 0.8 }}
              animate={questionsControls}
            >
              <FrequentQuestions />
            </motion.div> */}
            {/* <motion.div
              ref={insightRef}
              initial={{ scale: 0.8 }}
              animate={insightControls}
            >
              <InsightComponent />
            </motion.div> */}
          </PageLayout>
        </>
      )}
    </>
  );
}
