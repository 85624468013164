export const TESTIMONIALS = [
  {
    id: 1,
    comment:
      'Medfuture have been amazing and have secured the practice with a couple of Doctors. Jacki has been our main contact and has been very professional and helpful. She is passionate about helping rural practices.',
    user: 'Drayton Street Family Practice',
    url: '',
    rating: 5,
  },
  {
    id: 2,
    comment:
      'It was a great experience throughout the process for my position Iocum working for currently. Every step explains in relation to employee perspective .Cheny was very positive and helpful, no negatives at all for the service. Thank you..!',
    user: 'Shantha Batuwangala',
    url: '',
    rating: 5,
  },
  {
    id: 3,
    comment:
      'I believe I have met the right person, Cheny. It was very quick in just 3 days I got a position & he managed to organize an interview for me. He offered me 2 choices and I chose one of them. Makes me very happy & glad with my choice having myself contacted with Medfuture. Cheny was really helpful during the application process including obtaining Medicare provider number. No negative dealing with Medfuture.',
    user: 'Nyo Win',
    url: '',
    rating: 5,
  },
  {
    id: 4,
    comment:
      'My experience with MedFuture had been overwhelmingly positive over the course of the last four months. I found Recruitment consultant is very kind and has a good understanding of my needs and requirements as an applicant. He had always been prompt in responding to my queries, even after hours. He always tries to help and provides detailed information. Most important of all, I found Medfuture proactive.',
    user: 'Danmin Zhu',
    url: '',
    rating: 5,
  },
  {
    id: 5,
    comment:
      'Medfuture have been amazing and have secured the practice with a couple of Doctors. Jacki has been our main contact and has been very professional and helpful.',
    user: 'Farah Deeba',
    url: '',
    rating: 5,
  },
  {
    id: 6,
    comment:
      'I had a very positive experience. The consultant who I dealt with was exceptionally person entered and provided fantastic service. She patiently and carefully listened to my requirements, provided prompt response and actions to all my requests and correspondences. I am pleased to find them and using their service.',
    user: 'Ambreen Nisar',
    url: '',
    rating: 5,
  },
];
