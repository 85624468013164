import { motion, useScroll, useTransform } from 'framer-motion';
import Button from '../shared/button/Button';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

interface EmployerBlogCardsProps {
  title1: string;
  paragraph1: string;
  banner1: string;
  url1: string;
  title2: string;
  paragraph2: string;
  banner2: string;
  url2: string;
  title3: string;
  paragraph3: string;
  banner3: string;
  url3: string;
}

export default function EmployerBlogCards({
  title1,
  paragraph1,
  banner1,
  url1,
  title2,
  paragraph2,
  banner2,
  url2,
  title3,
  paragraph3,
  banner3,
  url3,
}: EmployerBlogCardsProps) {
  const navigate = useNavigate();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();

  const { scrollYProgress: scrollYProgress1 } = useScroll({ target: ref1 });
  const { scrollYProgress: scrollYProgress2 } = useScroll({ target: ref2 });
  const { scrollYProgress: scrollYProgress3 } = useScroll({ target: ref3 });

  const y1 = useTransform(scrollYProgress1, [0, 1], [-80, 80]);
  const y2 = useTransform(scrollYProgress2, [0, 1], [-80, 80]);
  const y3 = useTransform(scrollYProgress3, [0, 1], [-80, 80]);

  const navigationToPage = (url: string) => {
    navigate(url);
  };

  return (
    <section className="padding-container">
      <div className="grid grid-cols-1 gap-y-10 xl:gap-y-32">
        <div className="flex items-center justify-between gap-x-5">
          <div className="w-[650px] xl:w-[728px] h-[438px]" ref={ref1}>
            <img
              src={`${AWS_BASE_URL_ASSETS}/${banner1}`}
              className="object-cover"
              alt="Permanent Recruitment Services"
            />
          </div>
          <motion.div className="w-[550px] xl:w-[607px]" style={{ y: y1 }}>
            <h6 className="font-sans font-bold text-gray-800 text-[32px] leading-[44px] mb-6">
              {title1}
            </h6>
            <p className="font-sans font-normal text-lg text-blue-350 mb-4">
              {paragraph1}
            </p>
            <div className="w-[191px]">
              <Button
                type="button"
                title="Discover More"
                variant="find-jobs-btn"
                handleClick={() => navigationToPage(url1)}
              />
            </div>
          </motion.div>
        </div>

        <div className="flex items-center justify-between gap-x-5">
          <motion.div className="w-[550px] xl:w-[607px]" style={{ y: y2 }}>
            <h6 className="font-sans font-bold text-gray-800 text-[32px] leading-[44px] mb-6">
              {title2}
            </h6>
            <p className="font-sans font-normal text-lg text-blue-350 mb-4">
              {paragraph2}
            </p>
            <div className="w-[191px]">
              <Button
                type="button"
                title="Discover More"
                variant="find-jobs-btn"
                handleClick={() => navigationToPage(url2)}
              />
            </div>
          </motion.div>
          <div className="w-[650px] xl:w-[728px] h-[438px]" ref={ref2}>
            <img
              src={`${AWS_BASE_URL_ASSETS}/${banner2}`}
              className="object-cover"
              alt="Temporary Staffing Solutions"
            />
          </div>
        </div>

        <div className="flex items-center justify-between gap-x-5">
          <div className="w-[650px] xl:w-[728px] h-[438px]" ref={ref3}>
            <img
              src={`${AWS_BASE_URL_ASSETS}/${banner3}`}
              className="object-cover"
              alt="International Medical Recruitment"
            />
          </div>
          <motion.div className="w-[550px] xl:w-[607px]" style={{ y: y3 }}>
            <h6 className="font-sans font-bold text-gray-800 text-[32px] leading-[44px] mb-6">
              {title3}
            </h6>
            <p className="font-sans font-normal text-lg text-blue-350 mb-4">
              {paragraph3}
            </p>
            <div className="w-[191px]">
              <Button
                type="button"
                title="Discover More"
                variant="find-jobs-btn"
                handleClick={() => navigationToPage(url3)}
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
