import { HeroBackground } from '@/components';
import ClientSignInComponent from '@/components/mainMenu/ClientSignIn';
import { PageLayout } from '@/layout';

export default function ClientSignIn() {
  return (
    <>
      <HeroBackground title={'Login to your client profile'} />
      <PageLayout>
        <div className="flex items-center justify-center h-[900px]">
          <ClientSignInComponent />
        </div>
      </PageLayout>
    </>
  );
}
