import GetJobCountByDivisionApi from '@/api/preferences/GetJobCountByDivisionApi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface AllJobData {}

interface FetchAllJobData {
  jobCountByDivision: AllJobData[] | null;
  loading: boolean;
  error: Error | null;
}

export default function useFetchJobCountByDivision(): FetchAllJobData {
  const navigate = useNavigate();
  const [jobCountByDivision, setJobCountByDivision] = useState<
    AllJobData[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetJobCountByDivisionApi();
      setJobCountByDivision(response?.data);
    } catch (error: any) {
      if (error.status === 429) {
        navigate('/many-requests', {
          state: { retryAfter: error.data.retry_after },
        });
      } else if (error.status === 500) {
        navigate('/server-error');
      } else {
        setError(error as Error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { jobCountByDivision, loading, error };
}
