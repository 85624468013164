import GetAllSeniorityByProfession from '@/api/GetSeniorityProfessionWise';
import { Seniority } from '@/types/FetchDataTypes';
import { useEffect, useState } from 'react';

interface FetchAllSeniority {
  senioritiesProfessionWise: Seniority[] | null;
  seniorityLoading: boolean;
  error: Error | null;
}

export default function useFetchSeniorityProfessionWise(
  profession_id: number
): FetchAllSeniority {
  const [senioritiesProfessionWise, setSenioritiesProfessionWise] = useState<
    Seniority[] | null
  >(null);
  const [seniorityLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    if (isNaN(profession_id) || profession_id === null) {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const response = await GetAllSeniorityByProfession(profession_id);
      setSenioritiesProfessionWise(response.data[0].seniorities);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [profession_id]);

  return { senioritiesProfessionWise, seniorityLoading, error };
}
