import { getBaseUrl } from '@/config/BaseUrl';

interface CandidateData {
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  state: string;
  country: string;
  profession: string;
  speciality: string;
  hear_us: string;
  resume: string | null;
  access_status: boolean;
  candidate_id: string;
  profile_verified: number;
  updated_at: string;
  created_at: string;
  candidate_profiles_id: number;
}

interface CandidateResponse {
  message?: string;
  data?: CandidateData;
  errors?: string[];
}

export default async function CandidateRegistrationApi(
  first_name: string,
  last_name: string,
  email: string,
  mobile: string,
  state: string,
  country: string,
  profession: string,
  speciality: string,
  social: string,
  resumeName: string | null,
  password: string
): Promise<CandidateResponse> {
  const baseUrl = getBaseUrl();
  const url = `${baseUrl}web/candidate/save`;

  const requestBody = {
    first_name,
    last_name,
    email,
    mobile,
    state,
    country,
    profession,
    speciality,
    hear_us: social,
    resume: resumeName,
    password,
    role_id: 8,
    access_status: true,
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(requestBody),
  };

  try {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      const responseData: CandidateResponse = await response.json();
      if (response.status === 422 && responseData.errors?.length > 0) {
        throw new Error(responseData.errors[0]);
      } else {
        throw new Error(
          responseData.message || `Failed with status: ${response.status}`
        );
      }
    }

    const responseData: CandidateResponse = await response.json();
    return responseData;
  } catch (error) {
    // Extract the message from the error object
    const errorMessage = error.message || 'Unknown error occurred';
    throw new Error(errorMessage); // Re-throw with a consistent error message format
  }
}
