import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GetAllEmployeePlacementTypesApi from '@/api/GetAllPlacementTypeApi';

interface PlacementType {
  placement_types_id: number;
  name: string;
  description: string;
  status: number;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  position: number | null;
  short_name: string | null;
}

interface FetchPlacementTypeData {
  EmployeePlacementData: PlacementType[] | null;
  placementTypeLoading: boolean;
  filterDataError: Error | null;
}

export default function useFetchEmployeePlacementType(): FetchPlacementTypeData {
  const navigate = useNavigate();
  const [EmployeePlacementData, setEmployeePlacementData] = useState<
    PlacementType[] | null
  >(null);
  const [placementTypeLoading, setLoading] = useState<boolean>(true);
  const [filterDataError, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetAllEmployeePlacementTypesApi();
      setEmployeePlacementData(response);
    } catch (error: any) {
      if (error.status === 429) {
        navigate('/many-requests', {
          state: { retryAfter: error.data.retry_after },
        });
      } else if (error.status === 500) {
        navigate('/server-error');
      } else {
        setError(error as Error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { EmployeePlacementData, placementTypeLoading, filterDataError };
}
