import Button from '@/components/shared/button/Button';
import BackgroundImage from '@/assets/images/MobileCardPageHero.jpeg';

interface MobileCardHeroProps {
  MainTitle: string;
  SubTitle: string;
  src: string;
}

export default function CardPageHero({
  MainTitle,
  SubTitle,
  src,
}: MobileCardHeroProps) {
  const renderGradientBackground = () => {
    const path = location.pathname;
    if (
      path === '/premier-locum-general-practitioner' ||
      path === '/premier-locum-doctor'
    ) {
      return 'bg-mobile-premier-gradient';
    } else if (path === '/spot-workforce') {
      return 'bg-mobile-spot-gradient';
    } else {
      return null;
    }
  };

  const renderBtnColor = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return 'locum-gold-btn w-full mb-10';
    } else {
      return 'locum-silver-btn w-full mb-10';
    }
  };

  const renderCardImage = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return src;
    } else if (path === '/premier-locum-doctor') {
      return src;
    } else if (path === '/spot-workforce') {
      return src;
    }
  };

  const renderTextGradient = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return 'text-gradient-GP';
    } else if (path === '/premier-locum-doctor') {
      return 'text-gradient-common';
    } else if (path === '/spot-workforce') {
      return 'text-gradient-common';
    }
  };

  return (
    <div className="max-width-container mobile-container flex flex-col mx-auto relative">
      <div className="py-10">
        <div className="relative max-w-[376px] h-[220px] md:max-w-[688px] md:h-[300px]">
          <img
            src={BackgroundImage}
            className="w-full h-full object-cover rounded-[20px] opacity-50"
          />
          <div
            className={`absolute top-0 left-0 w-full h-full ${renderGradientBackground()} rounded-[20px]`}
          />
        </div>
        <div className="">
          <h1
            className={`absolute top-14 left-14 md:left-16 md:top-20 font-sans font-bold text-[28px] md:text-[56px] md:leading-[74px] leading-[40px] text-start ${renderTextGradient()}`}
          >
            {MainTitle}{' '}
            <div className="font-sans font-normal text-lg leading-[24px] md:text-[50px] md:leading-[28px] text-white">
              {SubTitle}
            </div>
          </h1>
          <img
            src={renderCardImage()}
            className="w-[162px] h-[134px] md:w-[300px] md:h-[300px] object-cover absolute bottom-32 right-10"
          />
        </div>
      </div>
      <div>
        <Button
          type={'button'}
          title={'Register Now'}
          variant={renderBtnColor()}
        />
      </div>
    </div>
  );
}
