import { getBaseUrl } from '@/config/BaseUrl';

export default async function GetAllSeniorityByProfession(
  profession_id: number
) {
  let res: string = '';
  try {
    const baseUrl = getBaseUrl();
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    };
    await fetch(
      `${baseUrl}web/getbyProfession/${profession_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.error('Error getting seniorities: ', error);
        res = error;
      });
  } catch (error) {
    console.error(error);
  }

  return res;
}
