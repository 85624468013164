export default function TermsAndConditionComponents() {
  return (
    <section className="bg-white shadow">
      <div className="p-28">
        <div>
          <h1 className="terms-title"> 1. Acceptance of terms</h1>
          <div className="terms-paragraph">
            <p>
              The services that [Medfuture] provides to User is subject to the
              following Terms of Use ("TOU"). [Medfuture] reserves the right to
              update the TOU at any time without notice to User. The most
              current version of the TOU can be reviewed by clicking on the
              "Terms of Use" hypertext link located at the bottom of our Web
              pages.
            </p>
            <li className="mb-3">
              This Agreement, which incorporates by reference other provisions
              applicable to use of [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ], including, but not limited to, supplemental terms and
              conditions set forth hereof ("Supplemental Terms") governing the
              use of certain specific material contained in [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ], sets forth the terms and conditions that apply to use of [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] by User. By using [Medfuture] (other than to read this Agreement
              for the first time), User agrees to comply with all of the terms
              and conditions hereof. The right to use [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] is personal to User and is not transferable to any other person
              or entity. User is responsible for all use of User's Account
              (under any screen name or password) and for ensuring that all use
              of User's Account complies fully with the provisions of this
              Agreement. User shall be responsible for protecting the
              confidentiality of User's password(s), if any.
            </li>
            <li className="mb-3">
              [Medfuture] shall have the right at any time to change or
              discontinue any aspect or feature of [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ], including, but not limited to, content, hours of availability,
              and equipment needed for access or use.
            </li>
          </div>
        </div>
        <div>
          <h1 className="terms-title"> 2. Changed Terms</h1>
          <div className="terms-paragraph">
            <p>
              [Medfuture] shall have the right at any time to change or modify
              the terms and conditions applicable to User's use of [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ], or any part thereof, or to impose new conditions, including,but
              not limited to, adding fees and charges for use. Such changes,
              modifications, additions or deletions shall be effective
              immediately upon notice thereof, which may be given by means
              including, but not limited to, posting on [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ], or by electronic or conventional mail, or by any other means by
              which User obtains notice thereof. Any use of [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] by User after such notice shall be deemed to constitute
              acceptance by User of such changes, modifications or additions.
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title"> 3. Description of services</h1>
          <div className="terms-paragraph">
            <p>
              Through its Web property, [MEDFUTURE] provides User with access to
              a variety of resources, including download areas, communication
              forums and product information (collectively "Services"). The
              Services, including any updates, enhancements, new features,
              and/or the addition of any new Web properties, are subject to the
              TOU.
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title"> 4. Equipment</h1>
          <div className="terms-paragraph">
            <p>
              User shall be responsible for obtaining and maintaining all
              telephone, computer hardware, software and other equipment needed
              for access to and use of [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] and all charges related thereto.
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title"> 5. User Conduct</h1>
          <div className="terms-paragraph">
            <p>
              User shall be responsible for obtaining and maintaining all
              telephone, computer hardware, software and other equipment needed
              for access to and use of [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] and all charges related thereto.
            </p>
            <li className="mb-3">
              User shall use [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] for lawful purposes only. User shall not post or transmit
              through [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] any material which violates or infringes in any way upon the
              rights of others, which is unlawful, threatening, abusive,
              defamatory, invasive of privacy or publicity rights, vulgar,
              obscene, profane or otherwise objectionable, which encourages
              conduct that would constitute a criminal offense, give rise to
              civil liability or otherwise violate any law, or which, without
              [Medfuture] 's express prior approval, contains advertising or any
              solicitation with respect to products or services. Any conduct by
              a User that in [Medfuture] 's discretion restricts or inhibits any
              other User from using or enjoying [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] will not be permitted. User shall not use [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] to advertise or perform any commercial solicitation, including,
              but not limited to, the solicitation of users to become
              subscribers of other on-line information services competitive with
              [Medfuture]
            </li>
            <li className="mb-3">
              [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] contains copyrighted material, trademarks and other proprietary
              information, including, but not limited to, text, software,
              photos, video, graphics, music and sound, and the entire contents
              of [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] are copyrighted as a collective work under the [Australia]
              copyright laws. [Medfuture] owns a copyright in the selection,
              coordination, arrangement and enhancement of such content, as well
              as in the content original to it. User may not modify, publish,
              transmit, participate in the transfer or sale, create derivative
              works, or in any way exploit, any of the content, in whole or in
              part. User may download copyrighted material for User's personal
              use only. Except as otherwise expressly permitted under copyright
              law, no copying, redistribution, retransmission, publication or
              commercial exploitation of downloaded material will be permitted
              without the express permission of [Medfuture] and the copyright
              owner. In the event of any permitted copying, redistribution or
              publication of copyrighted material, no changes in or deletion of
              author attribution, trademark legend or copyright notice shall be
              made. User acknowledges that it does not acquire any ownership
              rights by downloading copyrighted materia
            </li>
            <li className="mb-3">
              User shall not upload, post or otherwise make available on [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] any material protected by copyright, trademark or other
              proprietary right without the express permission of the owner of
              the copyright, trademark or other proprietary right and the burden
              of determining that any material is not protected by copyright
              rests with User. User shall be solely liable for any damage
              resulting from any infringement of copyrights, proprietary rights,
              or any other harm resulting from such a submission. By submitting
              material to any public area of [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ], User automatically grants, or warrants that the owner of such
              material has expressly granted
            </li>
            <li className="mb-3">
              [Medfuture] the royalty-free, perpetual, irrevocable,
              non-exclusive right and license to use, reproduce, modify, adapt,
              publish, translate and distribute such material (in whole or in
              part) worldwide and/or to incorporate it in other works in any
              form, media or technology now known or hereafter developed for the
              full term of any copyright that may exist in such material. User
              also permits any other User to access, view, store or reproduce
              the material for that User's personal use. User hereby grants
              [Medfuture] the right to edit, copy, publish and distribute any
              material made available on [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] by User. The foregoing provisions of Section 5 are for the
              benefit of [Medfuture], its subsidiaries, affiliates and its
              third-party content providers and licensors and each shall have
              the right to assert and enforce such provisions directly or on its
              own behalf.
            </li>
          </div>
        </div>
        <div>
          <h1 className="terms-title"> 6. User of Services</h1>
          <div className="terms-paragraph">
            <p>
              The Services may contain email services, bulletin board services,
              chat areas, news groups, forums, communities, personal web pages,
              calendars, photo albums, file cabinets and/or other message or
              communication facilities designed to enable User to communicate
              with others (each a "Communication Service" and collectively
              "Communication Services"). User agrees to use the Communication
              Services only to post, send and receive messages and material that
              are proper and, when applicable, related to the particular
              Communication Service. By way of example, and not as a limitation,
              User agrees that when using the Communication Services, User will
              not:
            </p>
            <li className="mb-3">
              Use the Communication Services in connection with surveys,
              contests, pyramid schemes, chain letters, junk email, spamming or
              any duplicative or unsolicited messages (commercial or otherwise).
            </li>
            <li className="mb-3">
              Defame, abuse, harass, stalk, threaten or otherwise violate the
              legal rights (such as rights of privacy and publicity) of others.
            </li>
            <li className="mb-3">
              Publish, post, upload, distribute or disseminate any
              inappropriate, profane, defamatory, obscene, indecent or unlawful
              topic, name, material or information.
            </li>
            <li className="mb-3">
              Upload, or otherwise make available, files that contain images,
              photographs, software or other material protected by intellectual
              property laws, including, by way of example, and not as
              limitation, copyright or trademark laws (or by rights of privacy
              or publicity) unless User own or control the rights thereto or
              have received all necessary consent to do the same.
            </li>
            <li className="mb-3">
              Use any material or information, including images or photographs,
              which are made available through the Services in any manner that
              infringes any copyright, trademark, patent, trade secret, or other
              proprietary right of any party.
            </li>
            <li className="mb-3">
              Upload files that contain viruses, Trojan horses, worms, time
              bombs, cancelbots, corrupted files, or any other similar software
              or programs that may damage the operation of another's computer or
              property of another.
            </li>
            <li className="mb-3">
              Advertise or offer to sell or buy any goods or services for any
              business purpose, unless such Communication Services specifically
              allows such messages
            </li>
            <li className="mb-3">
              Download any file posted by another user of a Communication
              Service that User know, or reasonably should know, cannot be
              legally reproduced, displayed, performed, and/or distributed in
              such manner.
            </li>
            <li className="mb-3">
              Falsify or delete any copyright management information, such as
              author attributions, legal or other proper notices or proprietary
              designations or labels of the origin or source of software or
              other material contained in a file that is uploaded.
            </li>
            <li className="mb-3">
              Restrict or inhibit any other user from using and enjoying the
              Communication Services.
            </li>
            <li className="mb-3">
              Violate any code of conduct or other guidelines which may be
              applicable for any particular Communication Service.
            </li>
            <li className="mb-3">
              Harvest or otherwise collect information about others, including
              email addresses.
            </li>
            <li className="mb-3">
              Violate any applicable laws or regulations.
            </li>
            <li className="mb-3">
              Create a false identity for the purpose of misleading others
            </li>
            <li className="mb-3">
              Use, download or otherwise copy, or provide (whether or not for a
              fee) to a person or entity any directory of users of the Services
              or other user or usage information or any portion thereof.
            </li>
            <p className="mb-3">
              [Medfuture] has no obligation to monitor the Communication
              Services. However, [Medfuture] reserves the right to review
              materials posted to the Communication Services and to remove any
              materials in its sole discretion. [Medfuture] reserves the right
              to terminate User’s access to any or allof the Communication
              Services at any time, without notice, for any reason whatsoever.
              [Medfuture] reserves the right at all times to disclose any
              information as it deems necessary to satisfy any applicable law,
              regulation, legal process or governmental request, or to edit,
              refuse to post or to remove any information or materials, in whole
              or in part, in [Medfuture]'s sole discretion. Materials uploaded
              to the Communication Services may be subject to posted limitations
              on usage, reproduction and/or dissemination; User is responsible
              for adhering to such limitations if User downloads the materials.
            </p>
            <p className="mb-3">
              Always use caution when giving out any personally identifiable
              information in any Communication Services. [Medfuture] does not
              control or endorse the content, messages or information found in
              any Communication Services and, therefore, [Medfuture]
              specifically disclaims any liability with regard to the
              Communication Services and any actions resulting from User’s
              participation in any Communication Services. Managers and hosts
              are not authorized [Medfuture] spokespersons, and their views do
              not necessarily reflect those of [Medfuture].
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title">
            {' '}
            8. Member Account, Password and Security
          </h1>
          <div className="terms-paragraph">
            <p className="mb-3">
              If any of the Services requires User to open an account, User must
              complete the registration process by providing [Medfuture] with
              current, complete and accurate information as prompted by the
              applicable registration form. User also will choose a password and
              a user name. User is entirely responsible for maintaining the
              confidentiality of User’s password and account. Furthermore, User
              is entirely responsible for any and all activities that occur
              under User’s account. User agrees to notify [Medfuture]
              immediately of any unauthorized use of User’s account or any other
              breach of security. [Medfuture] will not be liable for any loss
              that User may incur as a result of someone else using User’s
              password or account, either with or without User’s knowledge.
              However, User could be held liable for losses incurred by
              [Medfuture] or another party due to someone else using User’s
              account or password. User may not use anyone else's account at any
              time, without the permission of the account holder.
            </p>
            <p className="mb-3">
              Any software that is made available to download from the Services
              ("Software") is the copyrighted work of [Medfuture] and/or its
              suppliers. Use of the Software is governed by the terms of the end
              user license agreement, if any, which accompanies or is included
              with the Software ("License Agreement"). An end user will be
              unable to install any Software that is accompanied by or includes
              a License Agreement, unless he or she first agrees to the License
              Agreement terms. The Software is made available for download
              solely for use by end users according to the License Agreement.
              Any reproduction or redistribution of the Software not in
              accordance with the License Agreement is expressly prohibited by
              law, and may result in severe civil and criminal penalties.
              Violators will be prosecuted to the maximum extent possible.
            </p>
            <p className="mb-3">
              Without limiting the foregoing, copying or reproduction of the
              software to any other server or location for further reproduction
              or redistribution is expressly prohibited, unless such
              reproduction or redistribution is expressly permitted by the
              license agreement accompanying such software. the software is
              warranted, if at all, only according to the terms of the license
              agreement. except as warranted in the license agreement,
              [medfuture] hereby disclaims all warranties and conditions with
              regard to the software, including all warranties and conditions of
              merchantability, whether express, implied or statutory, fitness
              for a particular purpose, title and non-infringement.
            </p>
            <p>
              For your convenience, [medfuture] may make available as part of
              the services or in its software products, tools and utilities for
              use and/or download. [medfuture] does not make any assurances with
              regard to the accuracy of the results or output that derives from
              such use of any such tools and utilities. please respect the
              intellectual property rights of others when using the tools and
              utilities made available on the service
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title">
            {' '}
            9. Notices regarding software, Documents and services available on
            this Site
          </h1>
          <div className="terms-paragraph">
            <p className="mb-3">
              In no event shall [medfuture] and/or its respective suppliers be
              liable for any special, indirect or consequential damages or any
              damages whatsoever resulting from loss of use, data or profits,
              whether in an action of contract, negligence or other tortious
              action, arising out of or in connection with the use or
              performance of software, documents, provision of or failure to
              provide services, or information available from the services.
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title">
            {' '}
            10. Materials Provided to (Medfuture) Or Posted At Any Of ITS -
            www.medfuture.com.aus
          </h1>
          <div className="terms-paragraph">
            <p className="mb-3">
              [Medfuture] does not claim ownership of the materials User provide
              to [Medfuture] (including feedback and suggestions) or post,
              upload, input or submit to any Services or its associated services
              for review by the general public, or by the members of any public
              or private community, (each a "Submission" and collectively
              "Submissions"). However, by posting, uploading, inputting,
              providing or submitting ("Posting") User’s Submission User is
              granting [Medfuture], its affiliated companies and necessary
              sublicenses permission to use User’s Submission in connection with
              the operation of their Internet businesses (including, without
              limitation, all [Medfuture] Services), including, without
              limitation, the license rights to: copy, distribute, transmit,
              publicly display, publicly perform, reproduce, edit, translate and
              reformat User’s Submission; to publish User’s name in connection
              with User’s Submission; and the right to sublicense such rights to
              any supplier of the Services.
            </p>
            <p className="mb-3">
              No compensation will be paid with respect to the use of User’s
              Submission, as provided herein. [Medfuture] is under no obligation
              to post or use any Submission User may provide and [Medfuture] may
              remove any Submission at any time in its sole discretion. By
              Posting a Submission User warrants and represents to own or
              otherwise control all of the rights to User’s Submission as
              described in theseTerms of Use including, without limitation, all
              the rights necessary for User to provide, post, upload, input or
              submit the Submissions.
            </p>
            <p className="mb-3">
              In addition to the warranty and representation set forth above, by
              Posting a Submission that contain images, photographs, pictures or
              that are otherwise graphical in whole or in part ("Images"), User
              warrant and represent that (a) User is the copyright owner of such
              Images, or that the copyright owner of such Images has granted
              User permission to use such Images or any content and/or images
              contained in such Images consistent with the manner and purpose of
              User’s use and as otherwise permitted by these Terms of Use and
              the Services, (b) User have the rights necessary to grant the
              licenses and sublicenses described in these Terms of Use, and (c)
              that each person depicted in such Images, if any, has provided
              consent to the use of the Images as set forth in these Terms of
              Use, including, by way of example, and not as a limitation, the
              distribution, public display and reproduction of such Images. By
              Posting Images, User is granting (a) to all members of User’s
              private community (for each such Images available to members of
              such private community), and/or (b) to the general public (for
              each such Images available anywhere on the Services, other than a
              private community), permission to use User’s Images in connection
              with the use, as permitted by these Terms of Use, of any of the
              Services, (including, by way of example, and not as a limitation,
              making prints and gift items which include such Images), and
              including, without limitation, a non-exclusive, world-wide,
              royalty-free license to: copy, distribute, transmit, publicly
              display, publicly perform, reproduce, edit, translate and reformat
              User’s Images without having User’s name attached to such Images,
              and the right to sublicense such rights to any supplier of the
              Services. The licenses granted in the preceding sentences for a
              Images will terminate at the time User completely remove such
              Images from the Services, provided that, such termination shall
              not affect any licenses granted in connection with such Images
              prior to the time User completely remove such Images. No
              compensation will be paid with respect to the use of User’s
              Images.
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title">
            {' '}
            11. Disclaimer Of Warranty; Limitation Of Liability
          </h1>
          <div className="terms-paragraph">
            <p className="mb-3">
              User expressly agrees that use of [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] is at user's sole risk. neither [medfuture], its affiliates nor
              any of their respective employees, agents,third party content
              providers or licensors warrant that [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] will be uninterrupted or error free; nor do they make any
              warranty as to the results that may be obtained from use of [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ], or as to the accuracy, reliability or content of any
              information, service, or merchandise provided through [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ].
            </p>
            <p className="mb-3">
              [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] is provided on an "as is" basis without warranties of any kind,
              either express or implied, including, but not limited to,
              warranties of title or implied warranties of merchantability or
              fitness for a particular purpose, other than those warranties
              which are implied by and incapable of exclusion, restriction or
              modification under the laws applicable to this agreement.
            </p>
            <p className="mb-3">
              This disclaimer of liability applies to any damages or injury
              caused by any failure of performance, error, omission,
              interruption, deletion, defect, delay in operation or
              transmission, computer virus, communication line failure, theft or
              destruction or unauthorized access to, alteration of, or use of
              record, whether for breach of contract, tortious behavior,
              negligence, or under any other cause of action. user specifically
              acknowledges that [medfuture] is not liable for the defamatory,
              offensive or illegal conduct of other users or third-parties and
              that the risk of injury from the foregoing rests entirely with
              user.
            </p>
            <p className="mb-3">
              In no event will [medfuture], or any person or entity involved in
              creating, producing or distributing [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ] or the [medfuture] software, be liable for any damages,
              including, without limitation, direct, indirect, incidental,
              special, consequential or punitive damages arising out of the use
              of or inability to use [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ]. user hereby acknowledges that the provisions of this section
              shall apply to all content on the site.
            </p>
            <p className="mb-3">
              In addition to the terms set forth above neither, [medfuture], nor
              its affiliates, information providers or content partners shall be
              liable regardless of the cause or duration, for any errors,
              inaccuracies, omissions, or other defects in, or untimeliness or
              unauthenticity of, the information contained within [
              <a
                href="http://www.medfuture.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.medfuture.com.au
              </a>
              ], or for any delay or interruption in the transmission thereof to
              the user, or for any claims or losses arising therefrom or
              occasioned thereby. none of the foregoing parties shall be liable
              for any third-party claims or losses of any nature, including, but
              not limited to, lost profits, punitive or consequential damages.
            </p>
            <p className="mb-3">
              Prior to the execution of a stock trade, users are advised to
              consult with your broker or other financial representative to
              verify pricing or other information. [medfuture], its affiliates,
              information providers or content partners shall have no liability
              for investment decisions based on the information provided.
              neither, [medfuture], nor its affiliates, information providers or
              content partners warrant or guarantee the timeliness, sequence,
              accuracy or completeness of this information. additionally, there
              are no warranties as to the results obtained from the use of the
              information.
            </p>
            <p className="mb-3">
              Force majeure – neither party will be responsible for any failure
              or delay in performance due to circumstances beyond its reasonable
              control, including, without limitation, acts of god, war, riot,
              embargoes, acts of civil or military authorities, fire, floods,
              accidents, service outages resulting from equipment and/or
              software failure and/or telecommunications failures, power
              failures, network failures, failures of third party service
              providers (including providers of internet services and
              telecommunications). the party affected by any such event shall
              notify the other party within a maximum of fifteen (15) days from
              its occurence. the performance of ths agreement shall then be
              suspended for as long as any such event shall prevent the affected
              party from performing its obligations under this agreement.
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title"> 12. Links To Third Party Sites</h1>
          <div className="terms-paragraph">
            <p>
              The links in this area will let you leave [medfuture]'s site. the
              linked sites are not under the control of [medfuture] and
              [medfuture] is not responsible for the contents of any linked site
              or any link contained in a linked site, or any changes or updates
              to such sites. [medfuture] is not responsible for webcasting or
              any other form of transmission received from any linked site.
              [medfuture] is providing these links to you only as a convenience,
              and the inclusion of any link does not imply endorsement by
              [medfuture] of the site
            </p>
            <p>
              [Medfuture] is a distributor (and not a publisher) of content
              supplied by third parties and Users. Accordingly, [Medfuture] has
              no more editorial control over such content than does a public
              library, bookstore, or newsstand. Any opinions, advice,
              statements, services, offers, or other information or content
              expressed or made available by third parties, including
              information providers, Users or any other user of
              [www.medfuture.com.au], are those of the respective author(s) or
              distributor(s) and not of [Medfuture]. Neither [Medfuture] nor any
              third-party provider of information guarantees the accuracy,
              completeness, or usefulness of any content, nor its
              merchantability or fitness for any particular purpose.
            </p>
            <p>
              In many instances, the content available through
              [www.medfuture.com.au] represents the opinions and judgments of
              the respective information provider, User, or other user not under
              contract with [Medfuture]. [Medfuture] neither endorses nor is
              responsible for the accuracy or reliability of any opinion, advice
              or statement made on [www.medfuture.com.au] by anyone other than
              authorized [Medfuture] employee spokespersons while acting in
              their official capacities. Under no circumstances will [Medfuture]
              be liable for any loss or damage caused by a User's reliance on
              information obtained through [www.medfuture.com.au]. It is the
              responsibility of User to evaluate the accuracy, completeness or
              usefulness of any information, opinion, advice or other content
              available through [Medfuture]. Please seek the advice of
              professionals, as appropriate, regarding the evaluation of any
              specific information, opinion, advice or other content.
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title">
            {' '}
            13. Unsolicited idea submission policy
          </h1>
          <div className="terms-paragraph">
            <p>
              [medfuture] or any of its employees do not accept or consider
              unsolicited ideas, including ideas for new advertising campaigns,
              new promotions, new products or technologies, processes,
              materials, marketing plans or new product names. please do not
              send any original creative artwork, samples, demos, or other
              works. the sole purpose of this policy is to avoid potential
              misunderstandings or disputes when [medfuture]'s products or
              marketing strategies might seem similar to ideas submitted to
              [medfuture]. so, please do not send your unsolicited ideas to
              [medfuture] or anyone at [medfuture]. if, despite our request that
              you not send us your ideas and materials, you still send them,
              please understand that [medfuture] makes no assurances that your
              ideas and materials will be treated as confidential or
              proprietary.
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title"> 14. Monitoring</h1>
          <div className="terms-paragraph">
            <p>
              [MEDFUTURE] shall have the right, but not the obligation, to
              monitor the content of [www.medfuture.com.au], including chat
              rooms and forums, to determine compliance with this Agreement and
              any operating rules established by [MEDFUTURE] and to satisfy any
              law, regulation or authorized government request. [MEDFUTURE]
              shall have the right in its sole discretion to edit, refuse to
              post or remove any material submitted to or posted on
              [www.medfuture.com.au]. Without limiting the foregoing,
              [MEDFUTURE] shall have the right to remove any material that
              [MEDFUTURE], in its sole discretion, finds to be in violation of
              the provisions hereof or otherwise objectionable.
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title"> 15. Indemnification</h1>
          <div className="terms-paragraph">
            <p>
              User agrees to defend, indemnify and hold harmless [Medfuture],
              its affiliates and their respective directors, officers, employees
              and agents from and against all claims and expenses, including
              attorneys' fees, arising out of the use of [Medfuture] by User or
              User's Account.
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title"> 16. Termination</h1>
          <div className="terms-paragraph">
            <p>
              Either [Medfuture] or User may terminate this Agreement at any
              time. Without limiting the foregoing, [Medfuture] shall have the
              right to immediately terminate User's Account in the event of any
              conduct by User which [Medfuture], in its sole discretion,
              considers to be unacceptable, or in the event of any breach by
              User of this Agreement.
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title"> 17. Miscellaneous</h1>
          <div className="terms-paragraph">
            <p>
              This Agreement and any operating rules for [www.medfuture.com.au]
              established by [Medfuture] constitute the entire agreement of the
              parties with respect to the subject matter hereof, and supersede
              all previous written or oral agreements between the parties with
              respect to such subject matter. This Agreement shall be construed
              in accordance with the laws of the [VIC/MELBOURNE], [ Australia],
              without regard to its conflict of laws rules. No waiver by either
              party of any breach or default hereunder shall be deemed to be a
              waiver of any preceding or subsequent breach or default. The
              section headings used herein are for convenience only and shall
              not be given any legal import.
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title"> 18. Copyright Notice</h1>
          <div className="terms-paragraph">
            <p>
              This Agreement and any operating rules for [www.medfuture.com.au]
              established by [Medfuture] constitute the entire agreement of the
              parties with respect to the subject matter hereof, and supersede
              all previous written or oral agreements between the parties with
              respect to such subject matter. This Agreement shall be construed
              in accordance with the laws of the [VIC/MELBOURNE], [ Australia],
              without regard to its conflict of laws rules. No waiver by either
              party of any breach or default hereunder shall be deemed to be a
              waiver of any preceding or subsequent breach or default. The
              section headings used herein are for convenience only and shall
              not be given any legal import.
            </p>
          </div>
        </div>
        <div>
          <h1 className="terms-title"> 19. Trademarks</h1>
          <div className="terms-paragraph">
            <p>
              The names of actual companies and products mentioned herein may be
              the trademarks of their respective owners. The example companies,
              organizations, products, domain names, email addresses, logos,
              people and events depicted herein are fictitious. No association
              with any real company, organization, product, domain name, email
              address, logo, person, or event is intended or should be inferred.
              Any rights not expressly granted herein are reserved.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
