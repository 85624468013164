export const BENEFITS = [
  {
    id: 1,
    icon: 'CandidatePool.png',
    title: '10,000+ Candidate Pool',
    description:
      'Our rigorous screening process ensures that you get the most qualified professionals. We assess candidates extensively',
  },
  {
    id: 2,
    icon: 'Screening.png',
    title: 'Comprehensive Screening Process',
    description:
      'Our rigorous screening process ensures that you get the most qualified professionals. We assess candidates extensively',
  },
  {
    id: 3,
    icon: 'Matching.png',
    title: 'Expert Candidate Matching',
    description:
      'Our rigorous screening process ensures that you get the most qualified professionals. We assess candidates extensively',
  },
  {
    id: 4,
    icon: 'CandidatePool.png',
    title: 'Dedicated Relationship Manager Support',
    description:
      'Our rigorous screening process ensures that you get the most qualified professionals. We assess candidates extensively',
  },
];
