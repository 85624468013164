import MobileBlogsData from './MobileBlogsData';
import data from '@/data/blogs.json';

export default function MobileBlogs() {
  return (
    <section className="mobile-padding-container">
      <h2 className="section_header_text ">Latest Blogs</h2>
      <MobileBlogsData data={data} />
    </section>
  );
}
