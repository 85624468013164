import React from 'react';
import { motion } from 'framer-motion';
import menuIcon from '../../../assets/icons/menuIcon.png';
import SearchIcon from '../../../assets/icons/SearchMobile.png';
import Logo from '../../shared/logo/Logo';
import CloseIcon from '/icons/MobileClose.png';
import MobileMainMenu from '../mainMenu/MobileMainMenu';
import { useStateContext } from '@/context/ContextProvider';

const MobileNavbar: React.FC = () => {
  const { isMainMenuOpen, isOffCanvasOpen, closeOffCanvas, openMainMenu } =
    useStateContext();

  const toggleOffCanvas = () => {
    isOffCanvasOpen ? closeOffCanvas() : openMainMenu();
  };

  return (
    <nav className="max-width-container bg-blue-500 sticky top-0 z-10">
      <div className="mobile-nav-container">
        <div onClick={toggleOffCanvas} className="cursor-pointer">
          <motion.div
            className="w-[27px] h-[19px] flex items-center justify-center"
            initial={{ rotate: 0 }}
            animate={{ rotate: isOffCanvasOpen ? 180 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <motion.img
              src={isOffCanvasOpen ? CloseIcon : menuIcon}
              className="object-cover cursor-pointer"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
          </motion.div>
        </div>
        <div className="logo-container">
          <Logo />
        </div>
        <div>
          <img src={SearchIcon} height={21} width={21} />
        </div>
      </div>
      {isMainMenuOpen && <MobileMainMenu />}
    </nav>
  );
};

export default MobileNavbar;
