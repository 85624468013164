import { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SpecialtyData from './SpecialtyData';
import { motion } from 'framer-motion';

export default function Specialty({ jobCountBySpecilaty }) {
  const [selectedDivisionId, setSelectedDivisionId] = useState(null);
  const [selectedDivisionSpecialties, setSelectedDivisionSpecialties] =
    useState([]);

  useEffect(() => {
    if (jobCountBySpecilaty && jobCountBySpecilaty?.length > 0) {
      setSelectedDivisionId(jobCountBySpecilaty[0].division_id);
    }
  }, [jobCountBySpecilaty]);

  useEffect(() => {
    if (selectedDivisionId && jobCountBySpecilaty?.length > 0) {
      const selectedDivision = jobCountBySpecilaty?.find(
        (division) => division.division_id === selectedDivisionId
      );
      if (selectedDivision) {
        const specialties = selectedDivision.specialities_with_job_count || [];
        setSelectedDivisionSpecialties(specialties);
      }
    }
  }, [selectedDivisionId, jobCountBySpecilaty]);

  const handleDivisionSelect = (divisionId) => {
    setSelectedDivisionId(divisionId);
  };

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const Variant = {
    initial: {
      scale: 0.8,
      opacity: 0.5,
    },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.2,
      },
    },
  };

  const textVariant = {
    initial: {
      x: -80,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <motion.section className="padding-container">
      <motion.div
        className="flex items-baseline justify-between"
        variants={textVariant}
        initial="initial"
        whileInView="animate"
      >
        <motion.h2 className="section_header_text" variants={textVariant}>
          Jobs By Specialty
        </motion.h2>
      </motion.div>
      <Slider {...sliderSettings}>
        {jobCountBySpecilaty?.map((division, index) => (
          <div
            key={index}
            onClick={() => handleDivisionSelect(division.division_id)}
            className={`cursor-pointer mb-10 preferences-topic ${
              selectedDivisionId === division.division_id
                ? 'bg-gradient-to-r from-blue-500 text-center transition duration-300 to-blue-150 border border-blue-400 text-white py-4 rounded-md'
                : 'border-none py-4 rounded-md text-center text-blue-700 hover:text-gray-120'
            }`}
          >
            <h3>{division.division_name}</h3>
          </div>
        ))}
      </Slider>
      <motion.div
        className="h-[352px]"
        variants={Variant}
        initial="initial"
        whileInView="animate"
      >
        <SpecialtyData data={selectedDivisionSpecialties} />
      </motion.div>
    </motion.section>
  );
}
