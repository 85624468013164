import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { AWS_BASE_ASSETS_MENU_ICONS } from '@/config/seviceApiConfig';

const menuVariants = {
  hidden: {
    opacity: 0.5,
    y: -10,
    scale: 0.98,
  },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.25,
      when: 'beforeChildren',
      staggerChildren: 0.05,
    },
  },
};

const itemVariants = {
  hidden: {
    opacity: 0,
    y: -10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.25,
    },
  },
};

const TopMenuEmployers = ({ data }) => {
  return (
    <section className="absolute backdrop-blur-lg top-[50px] left-[90%] transform -translate-x-[50%] w-[569px] z-[10]">
      <motion.div
        className="bg-white bg-opacity-[70%] rounded-md shadow py-10 font-normal text-[13px] text-blue-500 p-10"
        initial="hidden"
        animate="visible"
        variants={menuVariants}
      >
        <ul className="grid grid-cols-2 gap-10">
          {data.map((subLink, index) => (
            <motion.li
              key={subLink.id}
              className=""
              variants={itemVariants}
              custom={index}
              initial="hidden"
              animate="visible"
            >
              <div className="flex items-center justify-normal">
                <img
                  src={`${AWS_BASE_ASSETS_MENU_ICONS}${subLink.icon}`}
                  alt={subLink.label}
                  className="w-4 h-4 mr-2"
                />
                <Link
                  to={subLink.href}
                  className="leading-[20px] mr-4 cursor-pointer text-blue-500 text-[14px] font-bold"
                >
                  {subLink.label}
                </Link>
              </div>
              <p className="w-[166px] text-xs leading-[17px] text-blue-230 ml-6 mt-2">
                Chart your course to success in the Australian healthcare
              </p>
            </motion.li>
          ))}
        </ul>
      </motion.div>
    </section>
  );
};

export default TopMenuEmployers;
