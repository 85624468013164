export default function NotImplemented() {
  return (
    <section className="padding-container my-20">
      <div className="text-center text-blue-550">
        <p className="font-sans font-[900] text-[200px] leading-[74px]">501</p>
        <p className="font-sans font-semibold text-[40px] leading-[74px] mt-20">
          Not Implemented
        </p>
        <p className="font-sans font-normal text-[24px] leading-[34px]">
          The request method is not implemented by the server.
        </p>
      </div>
    </section>
  );
}
