import Button from '@/components/shared/button/Button';
import PopupNotification from '@/components/shared/notification/PopupNotification';
import { useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { verifyEmailFormat } from '@/utils/EmailVerification';
import { isPhoneValid } from '@/utils/PhoneNumberVerification';
import { FORM_ERROR_TIME } from '@/constants/Constant';
import EnquirySubmissionApi from '@/api/Registration/EnquirySubmissionapi';

export default function EnquiryNow() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    note: '',
  });
  const [submit, setSubmit] = useState<boolean>(false);
  const [notification, setNotification] = useState({
    show: false,
    title: '',
    message: '',
    type: 'success' as 'success' | 'error',
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    note: '',
  });
  const PageType = 'Contact Us';
  const handleChange = (e: { target: { id: string; value: string } }) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !formData.name ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.note
    ) {
      setSubmit(false);
      const newErrors = {
        name: !formData.name ? 'Please enter your name' : '',
        email: !formData.email
          ? 'Please enter your email address'
          : !verifyEmailFormat(formData.email)
            ? 'Please enter a valid email address'
            : '',
        note: !formData.note ? 'Please submit your enquiry' : '',
        phoneNumber: !formData.phoneNumber
          ? 'Please enter your phone number'
          : !isPhoneValid(formData.phoneNumber)
            ? 'Please enter valid phone number'
            : '',
      };
      setFormErrors(newErrors);
      setTimeout(
        () =>
          setFormErrors({
            name: '',
            phoneNumber: '',
            email: '',
            note: '',
          }),
        FORM_ERROR_TIME
      );
    }
    try {
      const apiResponse = await EnquirySubmissionApi(
        formData.name,
        formData.email,
        formData.note,
        formData.phoneNumber,
        PageType
      );
      if (apiResponse) {
        setSubmit(true);
        setFormData({
          name: '',
          email: '',
          phoneNumber: '',
          note: '',
        });
      } else {
        console.error('Message Submission Failed ', apiResponse.errors);
        setNotification({
          show: true,
          title: 'Message Submission Failed',
          message:
            'An error occurred during registration. Please try again later.',
          type: 'error',
        });
      }
    } catch (error) {
      console.error('Error during registration: ', error);
      setNotification({
        show: false,
        title: 'Message Submission Failed',
        message:
          'An error occurred during registration. Please try again later.',
        type: 'error',
      });
    }
  };
  return (
    <div className="absolute top-[-160px] ">
      <div className="flex items-start justify-between gap-32 bg-white lg:p-10 4xl:p-20">
        <div>
          <form
            onSubmit={handleSubmit}
            className="pb-[70px] relative rounded-lg"
            noValidate
          >
            <div className="font-sans font-normal text-[42px] text-center leading-[76px] mb-5">
              Enquiry Now
            </div>

            <div className="mb-4">
              <div className="relative min-w-[543px]">
                <label
                  htmlFor="name"
                  className="font-sans font-normal text-base text-gray-900 leading-[40px] "
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  className={`form-container input-placeholder ${formErrors.name ? 'outline-red-300' : 'outline-blue-250'}`}
                  autoComplete="off"
                  placeholder="Your Name"
                />
                {formErrors.name && (
                  <div className="form-error-msg-enq">{formErrors.name}</div>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="relative min-w-[543px]">
                <label
                  htmlFor="email"
                  className="font-sans font-normal text-base text-gray-900 leading-[40px] "
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`form-container input-placeholder ${formErrors.email ? 'outline-red-300' : 'outline-blue-250'}`}
                  autoComplete="off"
                  placeholder="Your Email"
                />
              </div>
              {formErrors.email && (
                <div className="form-error-msg-enq">{formErrors.email}</div>
              )}
            </div>
            <div className="mb-4 relative w-[543px]">
              <label
                htmlFor="phoneNumber"
                className="font-sans font-normal text-base text-gray-900 leading-[40px] "
              >
                Phone
              </label>
              <PhoneInput
                forceDialCode={true}
                defaultCountry="nz"
                countrySelectorStyleProps={{
                  buttonStyle: {
                    zIndex: '2',
                    position: 'absolute',
                    left: '0px',
                    height: '34px',
                    width: '56px',
                    border: 'none',
                    backgroundColor: 'white',
                  },
                }}
                onChange={(phone) =>
                  setFormData({ ...formData, phoneNumber: phone })
                }
                inputProps={{
                  id: 'phoneNumber',
                  autoComplete: 'off',
                  className:
                    'w-full relative pl-16 focus:outline-none mb-[5px]',
                }}
                className={`form-container input-placeholder ${formErrors.phoneNumber ? 'outline-red-300' : 'outline-blue-250'}`}
                value={formData.phoneNumber}
              />
              {formErrors.phoneNumber && (
                <div className="form-error-msg-enq">
                  {formErrors.phoneNumber}
                </div>
              )}
            </div>
            <div className="">
              <label
                htmlFor="note"
                className="font-sans font-normal text-base text-gray-900 leading-[40px] "
              >
                Message
              </label>
              <textarea
                id="note"
                value={formData.note}
                onChange={handleChange}
                className={`form-text-area input-placeholder ${formErrors.phoneNumber ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                rows={6}
                placeholder="Your Enquiry Here"
              />
              {formErrors.note && (
                <div className="form-error-msg-enq">{formErrors.note}</div>
              )}
            </div>
            <div className="absolute bottom-[-20px] left-[300px] flex flex-col items-center">
              <div className="w-[237px]">
                <Button type="submit" title="Submit" variant="find-jobs-btn" />
              </div>
            </div>
          </form>
          {submit && (
            <div>
              <PopupNotification
                title={'Message Submitted  '}
                message={'We will contact you with in 24 hours'}
                onClose={() => setSubmit(false)}
                type={'success'}
              />
            </div>
          )}
          {notification.show && (
            <PopupNotification
              title={notification.title}
              message={notification.message}
              onClose={() => setNotification({ ...notification, show: false })}
              type={notification.type}
            />
          )}
        </div>
      </div>
    </div>
  );
}
