import Button from '@/components/shared/button/Button';
import DoctorImage1 from '@/assets/images/Gratitude.png';

export default function MobileBanner() {
  const renderBtnColor = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return 'locum-gold-btn';
    } else {
      return 'locum-silver-btn';
    }
  };

  const GetTextColor = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return 'text-gratitude-gold-gradient';
    } else {
      return 'text-silver-gradient';
    }
  };

  return (
    <section className="mb-8">
      <div className="max-w-[376px] h-[204px]  md:min-w-[688px] md:h-[300px] rounded-[18px] relative">
        <div className="absolute top-10 left-10">
          <h1 className="font-sans font-normal text-[33px] md:text-[44px] leading-[35px] md:leading-[44px] text-blue-420">
            Refer and Earn
          </h1>
          <h3
            className={`font-sans font-bold text-[19px] md:text-[30px] leading-[34px] md:leading-[44px] ${GetTextColor()}`}
          >
            2500 AUD$
          </h3>
        </div>
        <img
          src={DoctorImage1}
          className="w-full h-full rounded-[34px] object-cover object-center"
          alt="doctor-img"
        />
        <div className="absolute left-10 bottom-10">
          <Button
            type={'button'}
            title={'Claim Now'}
            variant={renderBtnColor()}
          />
        </div>
      </div>
    </section>
  );
}
