import { useState } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CommonJobCard from './CommonJobCard';
import ShareModal from '../models/ShareModal';

export default function JobSection({ hasJobs, stateJobs }) {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [jobUrl, setJobUrl] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);

  const handleShareClick = (url) => {
    setJobUrl(url);
    setIsShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setIsShareModalOpen(false);
  };

  const handleBeforeChange = (_: any, next: number) => {
    setActiveIndex(next);
  };

  const settings: Settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    swipe: false,
    autoplay: false,
    touchMove: true,
    className: 'custom-carousel',
    appendDots: (dots: JSX.Element[]) => (
      <div className="slick-dots">
        {dots.slice(0, 3).map((dot, index) => (
          <div key={index} className="inline-block m-[2px] cursor-pointer">
            {dot}
          </div>
        ))}
      </div>
    ),
    beforeChange: handleBeforeChange,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <section className="">
      {Object.entries(stateJobs).map(([stateName, jobs]) => (
        <div key={stateName}>
          <h2 className="font-normal font-sans text-[42px] leading-[88px] mt-20">
            {stateName}
          </h2>
          <div className="my-5">
            <Slider {...settings}>
              {jobs
                ?.slice(0, 10)
                ?.map((data) => (
                  <CommonJobCard
                    key={data.jobdetails_id}
                    offer={data}
                    onShareClick={handleShareClick}
                  />
                ))}
            </Slider>
          </div>
        </div>
      ))}
      <div className={`modal ${isShareModalOpen ? 'modal-open' : ''}`}>
        <ShareModal
          isOpen={isShareModalOpen}
          onClose={handleCloseShareModal}
          jobUrl={jobUrl}
        />
      </div>
    </section>
  );
}
