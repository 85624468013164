export const AWS_BASE_URL_CITY =
  'https://medfuturebucketnz.s3.ap-southeast-1.amazonaws.com/desktop/city/';

export const AWS_BASE_URL_SPECIALTY =
  'https://medfuturebucketnz.s3.ap-southeast-1.amazonaws.com/desktop/specialty/';

export const AWS_BASE_URL_JOBS =
  'https://medfuturebucketnz.s3.ap-southeast-1.amazonaws.com/desktop/jobs/';

export const AWS_BASE_URL_BLOG =
  'https://medfuturebucketnz.s3.ap-southeast-1.amazonaws.com/desktop/blogs/';

export const AWS_BASE_URL_ASSETS =
  'https://medfuturebucketnz.s3.ap-southeast-1.amazonaws.com/desktop/website/images';

export const AWS_BASE_LOCATION_BANNERS =
  'https://medfuturebucketnz.s3.ap-southeast-1.amazonaws.com/desktop/website/images/location_banners/';

export const AWS_BASE_PROFESSIONAL_BANNERS =
  'https://medfuturebucketnz.s3.ap-southeast-1.amazonaws.com/desktop/website/images/profession_banners/';

export const AWS_BASE_ASSETS_ICONS =
  'https://medfuturebucketnz.s3.ap-southeast-1.amazonaws.com/desktop/website/icons/';

export const AWS_BASE_ASSETS_MENU_ICONS =
  'https://medfuturebucketnz.s3.ap-southeast-1.amazonaws.com/desktop/menu_icons/';

export const AWS_BASE_ASSETS_SENIORITY =
  'https://medfuturebucketnz.s3.ap-southeast-1.amazonaws.com/desktop/seniority/';

// Mobile Assets S3 URLS

export const AWS_MOBILE_CITY_URL =
  'https://medfuturebucketnz.s3.ap-southeast-1.amazonaws.com/mobile/city/';

export const AWS_MOBILE_SPECIALTY_URL =
  'https://medfuturebucketnz.s3.ap-southeast-1.amazonaws.com/mobile/specialty/';

export const AWS_MOBILE_DIVISION_URL =
  'https://medfuturebucketnz.s3.ap-southeast-1.amazonaws.com/mobile/divisions/';

export const AWS_MOBILE_ASSETS_ICONS =
  'https://medfuturebucketnz.s3.ap-southeast-1.amazonaws.com/mobile/website/icons/';
