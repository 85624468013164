import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GetAllSatesDetailsByCountryApi from '@/api/GetAllStateDetailsByCountryApi';

export default function useFetchAllStateDetailsByCountry() {
  const navigate = useNavigate();
  const [allStatesDetails, setAllStateDetails] = useState(null);
  const [allStateLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetAllSatesDetailsByCountryApi();
      setAllStateDetails(response.states);
      setError(null);
    } catch (error: any) {
      if (error.status === 429) {
        navigate('/many-requests', {
          state: { retryAfter: error.data.retry_after },
        });
      } else if (error.status === 500) {
        navigate('/server-error');
      } else {
        setError(error as Error);
        setAllStateDetails(null);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { allStatesDetails, allStateLoading, error };
}
