import { MobileRegisterForm } from '@/components';
import RegisterImg from '/images/MobileRegister.jpeg';

export default function MobileRegisterHero() {
  return (
    <section className="py-5 relative h-[1100px] md:h-[1300px] mb-10">
      <h1 className="font-bold text-center text-[24px] leading-[42px]">
        Candidate Registration
      </h1>
      <div className="max-w-[376px] h-[220px] md:min-w-[688px] md:h-[300px]">
        <img
          src={RegisterImg}
          className="w-full h-full object-cover rounded-[20px]"
        />
      </div>
      <div className=" absolute top-[220px] md:top-[320px] left-1/2 transform -translate-x-1/2">
        <MobileRegisterForm />
      </div>
    </section>
  );
}
