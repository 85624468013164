import { useEffect, useState } from 'react';
import { StateSelector } from '@/components/forms/selectors/StateSelector';
import Button from '@/components/shared/button/Button';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { verifyEmailFormat } from '@/utils/EmailVerification';
import { isPhoneValid } from '@/utils/PhoneNumberVerification';
import { Link, useLocation } from 'react-router-dom';
import { SenioritySelector } from '../selectors/SenioritySelector';
import { SpecialtySelector } from '../selectors/SpecialtySelector';
import { CountrySelector } from '../selectors/CountrySelector';
import { RegistrationTypeSelector } from '../selectors/RegistrationTypeSelector';
import { ProfessionalStatusSelector } from '../selectors/ProfessionalStatusSeelector';
import pdfIcon from '/icons/pdfIcon.png';
import wordIcon from '/icons/wordIcon.png';
import { ProfessionSelector } from '../selectors/ProfessionSelector';
import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import { getBaseUrl } from '@/config/BaseUrl';
import CommonReachUsApi from '@/api/Registration/ReachUsReagisterApi';
import PopupNotification from '@/components/shared/notification/PopupNotification';

export default function LocumRegister() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    country: '',
    state: '',
    profession: '',
    specialty: '',
    seniority: '',
    registrationType: '',
    fileUpload: '',
    agree: false,
    subscribe: false,
  });
  const [file, setFile] = useState<File | null>(null);
  const location = useLocation();
  const [submit, setSubmit] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    country: '',
    state: '',
    profession: '',
    specialty: '',
    seniority: '',
    registrationType: '',
    fileUpload: '',
    agree: false,
  });
  const [notification, setNotification] = useState({
    show: false,
    title: '',
    message: '',
    type: 'success' as 'success' | 'error',
  });

  const handleChange = (e: { target: { id: string; value: string } }) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const useUploadFile = e.target.files[0];
      const allowedFileTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];

      if (!allowedFileTypes.includes(useUploadFile.type)) {
        setFormErrors({
          ...formErrors,
          fileUpload: 'Please upload your CV in PDF or Word format',
        });
        setFile(null);
      } else {
        setFormErrors({
          ...formErrors,
          fileUpload: '',
        });
        setFile(e.target.files[0]);
      }
    }
  };

  const validateForm = () => {
    const newErrors = {
      firstName: !formData.firstName ? 'Please enter your first name' : '',
      lastName: !formData.lastName ? 'Please enter your last name' : '',
      email: !formData.email
        ? 'Please enter your email address'
        : !verifyEmailFormat(formData.email)
          ? 'Please enter a valid email address'
          : '',
      profession: !formData.profession ? 'Please select profession' : '',
      state: !formData.state ? 'Please select state' : '',
      country: !formData.country ? 'Please select a Country' : '',
      phoneNumber: !formData.phoneNumber
        ? 'Please enter your Phone Number'
        : !isPhoneValid(formData.phoneNumber)
          ? 'Please enter a valid Phone Number'
          : '',
      fileUpload: !file ? 'Please upload your cv here' : '',
      registrationType: !formData.registrationType
        ? 'Please select registration type'
        : '',
      specialty: !formData.specialty ? 'Please select a specialty' : '',
      seniority: !formData.seniority ? 'Please select a seniority' : '',
      agree: !formData.agree
        ? 'You must agree to the Terms and Conditions and Privacy Policy'
        : '',
    };

    setFormErrors(newErrors);
    return newErrors;
  };

  useEffect(() => {
    if (click) {
      validateForm();
    }
  }, [click, formData]);

  const baseUrl = getBaseUrl();
  const message = 'No message from card pages';

  const getPageType = () => {
    const path = location.pathname;
    switch (path) {
      case '/premier-locum-general-practitioner':
        return 'PLGP';
      case '/nursing':
        return 'CFORCE';
      case '/allied-health-professional':
        return 'SPOT';
      case '/premier-locum-doctor':
        return 'PLHD';
      case '/international-medical-graduate-recruitment':
        return 'IMG';
      default:
        return 'CardPages';
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log(formData);
    e.preventDefault();
    setClick(true);
    const errors = validateForm();
    if (Object.values(errors).some((error) => error)) {
      setIsSubmitting(false);
      return;
    }
    try {
      setIsSubmitting(true);
      const randomNumber = Math.floor(Math.random() * 1000000);
      const fileData = new FormData();
      fileData.append('image', file);
      fileData.append('folder_path', 'desktop/candidate_resume');
      fileData.append('file_name', `${formData.firstName}_${randomNumber}`);
      const fileUploadResponse = await fetch(`${baseUrl}upload`, {
        method: 'POST',
        body: fileData,
      });
      const fileUploadResult = await fileUploadResponse.json();
      console.log(fileUploadResult);

      if (!fileUploadResult) {
        throw new Error('File upload failed');
      } else {
        const apiResponse = await CommonReachUsApi(
          formData.firstName,
          formData.lastName,
          formData.profession,
          formData.state,
          formData.specialty,
          formData.seniority,
          formData.email,
          message,
          formData.phoneNumber,
          getPageType(),
          fileUploadResult.fileName,
          formData.country,
          formData.registrationType
        );
        if (apiResponse.reach_us_id) {
          setSubmit(true);
          setClick(false);
          setFormData({
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            country: '',
            state: '',
            profession: '',
            specialty: '',
            seniority: '',
            registrationType: '',
            fileUpload: '',
            agree: false,
            subscribe: false,
          });
          setFile(null);
        } else {
          console.error('Registration failed: ', apiResponse.error);
          setSubmit(false);
          setNotification({
            show: true,
            title: 'Registration Failed',
            message: apiResponse.error,
            type: 'error',
          });
        }
      }
    } catch (error) {
      console.error('Error during registration: ', error);
      setSubmit(false);
      setNotification({
        show: false,
        title: 'Registration Error',
        message:
          'An error occurred during registration. Please try again later.',
        type: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const getTextColor = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return 'text-register-form-gold-gradient';
    } else if (path === '/nursing') {
      return 'text-gradient-nursing';
    } else {
      return 'text-register-form-silver-gradient';
    }
  };

  const getBackgroundFormColor = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return 'bg-gray-80';
    } else {
      return 'bg-form-silver-gradient';
    }
  };

  const renderBtnColor = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return 'form-register-gold-btn';
    } else if (path === '/nursing') {
      return 'form-register-pink-btn';
    } else {
      return 'form-register-silver-btn';
    }
  };

  const renderFormStyle = (hasError: boolean) => {
    const path = location.pathname;
    if (hasError) {
      return 'outline-red-300';
    } else if (path === '/premier-locum-general-practitioner') {
      return 'outline-blue-560';
    } else {
      return 'outline-white';
    }
  };

  const renderUploadField = (hasError: boolean) => {
    const path = location.pathname;
    if (hasError) {
      return 'outline-red-300';
    } else if (path === '/premier-locum-general-practitioner') {
      return 'outline-blue-560';
    } else {
      return 'outline-white';
    }
  };

  const getIconForFileType = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (extension === 'pdf') {
      return <img src={pdfIcon} alt="PDF Icon" className="w-10 h-10 mr-2" />;
    } else if (['txt', 'doc', 'docx'].includes(extension || '')) {
      return <img src={wordIcon} alt="Text Icon" className="w-10 h-10 mr-2" />;
    } else {
      return null;
    }
  };

  return (
    <div
      className="relative padding-container flex items-center justify-center"
      id="register-reach-us"
    >
      <form
        onSubmit={handleSubmit}
        className={`lg:p-[50px] xl:p-[70px] lg:w-[900px] xl:w-[1054px]  drop-shadow-2xl bg-gray-80 border border-gray-80 rounded-lg ${getBackgroundFormColor()}`}
        noValidate
      >
        <div>
          <div className="text-center font-sans font-normal text-[42px] leading-[76px]">
            <h2 className="text-blue-420 pb-10 text-[52px] leading-[76px] font-normal font-sans">
              Register and <span className={getTextColor()}>Reach Us</span>
            </h2>
          </div>
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative min-w-[442px]">
              <input
                type="text"
                id="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className={`locum-form-container input-placeholder ${renderFormStyle(!!formErrors.firstName)}`}
                autoComplete="off"
                placeholder="First Name"
              />
              {formErrors.firstName && (
                <div className="form-error-msg">{formErrors.firstName}</div>
              )}
            </div>
            <div className="relative min-w-[442px]">
              <input
                type="text"
                id="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className={`locum-form-container input-placeholder ${renderFormStyle(!!formErrors.lastName)}`}
                autoComplete="off"
                placeholder="Last Name"
              />
              {formErrors.lastName && (
                <div className="form-error-msg">{formErrors.lastName}</div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between gap-6 mb-10 relative">
            <div className="lg:min-w-[367px] xl:min-w-[442px]">
              <PhoneInput
                forceDialCode={true}
                defaultCountry="au"
                countrySelectorStyleProps={{
                  buttonStyle: {
                    zIndex: '30',
                    position: 'absolute',
                    left: '0px',
                    height: '36px',
                    width: '56px',
                    border: 'none',
                    backgroundColor: '#F8F8F8',
                  },
                }}
                inputProps={{
                  id: 'phoneNumber',
                  autoComplete: 'off',
                  className:
                    'w-full relative pl-16 focus:outline-none mb-[2px] bg-gray-380',
                }}
                onChange={(phone) =>
                  setFormData({ ...formData, phoneNumber: phone })
                }
                className={`locum-form-container input-placeholder ${renderFormStyle(!!formErrors.phoneNumber)}`}
                value={formData.phoneNumber}
              />
              {formErrors.phoneNumber && (
                <div className="text-red-100 absolute text-sm">
                  {formErrors.phoneNumber}
                </div>
              )}
            </div>
            <div className="relative lg:min-w-[367px] xl:min-w-[442px]">
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className={`locum-form-container input-placeholder ${renderFormStyle(!!formErrors.email)}`}
                autoComplete="off"
                placeholder="Email"
              />
              {formErrors.email && (
                <div className="form-error-msg">{formErrors.email}</div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative min-w-[442px]">
              <CountrySelector
                onChange={(value) =>
                  setFormData({ ...formData, country: value })
                }
                error={formErrors.country}
                resetSelector={submit}
              />
              {formErrors.country && (
                <div className="form-error-msg">{formErrors.country}</div>
              )}
            </div>
            <div className="relative min-w-[442px]">
              <StateSelector
                onChange={(value) => setFormData({ ...formData, state: value })}
                error={formErrors.state}
                resetSelector={submit}
              />
              {formErrors.state && (
                <div className="form-error-msg">{formErrors.state}</div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative min-w-[442px]">
              <ProfessionSelector
                onChange={(value) =>
                  setFormData({ ...formData, profession: value })
                }
                error={formErrors.profession}
                resetSelector={submit}
              />
              {formErrors.profession && (
                <div className="form-error-msg">{formErrors.profession}</div>
              )}
            </div>
            <div className="relative min-w-[442px]">
              <SpecialtySelector
                onChange={(value) =>
                  setFormData({ ...formData, specialty: value })
                }
                error={formErrors.specialty}
                professionId={parseInt(formData.profession)}
                resetSelector={submit}
              />
              {formErrors.specialty && (
                <div className="form-error-msg">{formErrors.specialty}</div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative min-w-[442px]">
              <SenioritySelector
                onChange={(value) =>
                  setFormData({ ...formData, seniority: value })
                }
                error={formErrors.seniority}
                professionId={parseInt(formData.profession)}
                resetSelector={submit}
              />
              {formErrors.seniority && (
                <div className="form-error-msg">{formErrors.seniority}</div>
              )}
            </div>
            <div className="relative min-w-[442px]">
              <RegistrationTypeSelector
                onChange={(value) =>
                  setFormData({ ...formData, registrationType: value })
                }
                error={formErrors.registrationType}
                resetSelector={submit}
              />
              {formErrors.registrationType && (
                <div className="form-error-msg">
                  {formErrors.registrationType}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative min-w-[442px]">
              <div
                className={`form-upload-container input-placeholder ${renderUploadField(!!formErrors.firstName)}`}
                onChange={handleFileChange}
              >
                <label htmlFor="fileUpload" className=" w-full cursor-pointer">
                  <div className="px-2">
                    {file ? (
                      <div className="flex items-center text-nowrap justify-center p-2 py-9">
                        <div>{getIconForFileType(file.name)}</div>
                        <h1 className="form-text">{file.name}</h1>
                      </div>
                    ) : (
                      <p className="form-text mt-[6px]">
                        <div className="flex items-center justify-between py-9">
                          <span className="">Upload Your CV</span>
                          <img
                            src={`${AWS_BASE_ASSETS_ICONS}CloudUploadFill.png`}
                            width={23}
                            height={23}
                          />
                        </div>
                      </p>
                    )}
                  </div>
                  <input
                    id="fileUpload"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </label>
              </div>
              {formErrors.fileUpload && (
                <div className="form-error-msg">{formErrors.fileUpload}</div>
              )}
            </div>

            <div className="mb-10 mt-10">
              <div>
                <input
                  type="checkbox"
                  id="agree"
                  value={formData.agree}
                  onChange={handleChange}
                />
                <label
                  htmlFor="agree"
                  className={`relative ${
                    formErrors.agree ? 'after:text-red-100' : 'after:text-black'
                  } after:content-['*'] after:ml-1  absolute top-0 left-3`}
                >
                  I Agree to the{' '}
                  <Link to={'/terms-and-conditions'} className="text-blue-220">
                    Term and Condition
                  </Link>{' '}
                  and{' '}
                  <Link to={'/privacy-policy'} className="text-blue-220">
                    Privacy policy
                  </Link>
                  {formErrors.agree && (
                    <div className="absolute text-red-100 text-sm ml-4 text-nowrap">
                      {formErrors.agree}
                    </div>
                  )}
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="subscribe"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="mt-5"
                />
                <label htmlFor="subscribe" className="ml-2">
                  Subscribe for Job Alert
                </label>
              </div>
            </div>
          </div>
          <div className="absolute bottom-[40px] right-[70px] flex flex-col items-center">
            <div className="w-[202px]">
              <Button
                type="submit"
                title="Register Now"
                variant={`${renderBtnColor()}`}
              />
            </div>
          </div>
        </div>
      </form>
      {submit && (
        <PopupNotification
          title={'Registered Successfully'}
          message={
            'Thank you for registering with us. Please verify yourself by clicking on the verification link in your email.'
          }
          onClose={() => setSubmit(false)}
          type={'success'}
        />
      )}
      {notification.show && (
        <PopupNotification
          title={notification.title}
          message={notification.message}
          onClose={() => setNotification({ ...notification, show: false })}
          type={notification.type}
        />
      )}
    </div>
  );
}
