import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Email from '@/assets/icons/Email.png';
import Phone from '@/assets/icons/Phone.png';
import { Link } from 'react-router-dom';
import SplitStringUsingRegex from '@/utils/SplitStringUsingRegex';

interface LocumOfferProps {
  src?: string;
  description1?: string;
  description2?: string;
  description3?: string;
}

export default function LocumOffer({
  src,
  description1,
  description2,
  description3,
}: LocumOfferProps) {
  const CharVarients = {
    hidden: { opacity: 0 },
    reveal: { opacity: 1 },
  };

  const descChar1 = SplitStringUsingRegex(description1 || '');
  const descChar2 = SplitStringUsingRegex(description2 || '');
  const descChar3 = SplitStringUsingRegex(description3 || '');

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (step === 0 && descChar1.length) {
      const duration = descChar1.length * 0.52;
      setTimeout(() => setStep(1), duration * 1000);
    } else if (step === 1 && descChar2.length) {
      const duration = descChar2.length * 1.025;
      setTimeout(() => setStep(2), duration * 1000);
    }
  }, [step, descChar1, descChar2]);

  const GetTextColor = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return 'text-gold-gradient';
    } else if (path === '/nursing') {
      return 'text-gradient-nursing';
    } else {
      return 'text-silver-gradient';
    }
  };

  return (
    <div className="padding-container">
      <div className="flex items-center justify-between lg:gap-10 2xl:gap-40">
        <motion.div
          className="w-1/2 rounded-tr-[113px]"
          whileHover={{ scale: 1.05, rotate: 5 }}
          transition={{ duration: 1.5, type: 'spring', bounce: 0.4 }}
        >
          <motion.img
            src={src}
            alt="card-img"
            className="w-full h-full object-cover rounded-tr-[113px]"
            loading="lazy"
          />
        </motion.div>
        <div className="w-1/2 h-[536px]">
          <h2 className="font-sans font-normal text-[52px] leading-[60px] 2xl:leading-[76px] text-blue-420">
            Discover What <span className={`${GetTextColor()}`}>We Offer</span>
          </h2>
          <motion.div className="font-sans font-normal lg:text-base lg:leading-[30px] 4xl:text-lg 4xl:leading-[30px] text-blue-440">
            {step >= 0 && (
              <motion.p
                initial="hidden"
                animate="reveal"
                transition={{ staggerChildren: 0.02 }}
                onAnimationComplete={() => setStep(1)}
              >
                {descChar1.map((char, index) => (
                  <motion.span
                    key={index}
                    transition={{ duration: 0.5 }}
                    variants={CharVarients}
                  >
                    {char}
                  </motion.span>
                ))}
              </motion.p>
            )}
            {step >= 1 && (
              <motion.p
                initial="hidden"
                animate="reveal"
                transition={{ staggerChildren: 0.025 }}
                onAnimationComplete={() => setStep(2)}
              >
                {descChar2.map((char, index) => (
                  <motion.span
                    key={index}
                    transition={{ duration: 1 }}
                    variants={CharVarients}
                  >
                    {char}
                  </motion.span>
                ))}
              </motion.p>
            )}
            {step >= 2 && (
              <motion.p
                initial="hidden"
                animate="reveal"
                transition={{ staggerChildren: 0.01 }}
              >
                {descChar3.map((char, index) => (
                  <motion.span
                    key={index}
                    transition={{ duration: 1.2 }}
                    variants={CharVarients}
                  >
                    {char}
                  </motion.span>
                ))}
              </motion.p>
            )}
          </motion.div>
        </div>
      </div>
      <div className="flex justify-center my-20">
        <div className="flex items-center justify-around border-color rounded-3xl w-[535px] h-[145px] relative shadow">
          <div className="absolute top-[-19px] left-6 text-[22px] leading-[33px] bg-white px-2">
            Reach Us
          </div>
          <div className="flex items-center justify-between gap-5">
            <div>
              <h6>Email Us</h6>
              <div className="flex items-center justify-between mt-2">
                <img
                  src={Email}
                  className="w-[27px] h-[27px] object-cover mr-2"
                />
                <Link to={`mailto:premier@medfuture.com.au`}>
                  premier@medfuture.com.au
                </Link>
              </div>
            </div>
            <div>
              <h6>Call Us</h6>
              <div className="flex items-center justify-between mt-2">
                <img
                  src={Phone}
                  className="w-[27px] h-[27px] object-cover mr-2"
                />
                <Link to={`tel:1300 444 419`}>1300 444 419</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
