import { Button } from '@/components';
import applyNow from '@/assets/icons/ApplyNow.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function TooManyRequest() {
  const navigate = useNavigate();
  const location = useLocation();

  const retryAfter = location.state?.retryAfter;

  const [previousUrl, setPreviousUrl] = useState('/');
  const [remainingTime, setRemainingTime] = useState(retryAfter);

  useEffect(() => {
    const storedPreviousUrl = localStorage.getItem('previousUrl');
    if (storedPreviousUrl) {
      setPreviousUrl(storedPreviousUrl);
      localStorage.removeItem('previousUrl');
    }
  }, []);

  useEffect(() => {
    if (remainingTime > 0) {
      const intervalId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(intervalId); // Cleanup on unmount
    } else {
      navigate(previousUrl); // Automatically navigate back when the timer reaches zero
    }
  }, [remainingTime, navigate, previousUrl]);

  const navigateToPrevious = () => {
    navigate(previousUrl);
  };

  return (
    <section className="padding-container my-20">
      <div className="text-center text-blue-500">
        <p className="font-sans font-[900] text-[100px] lg:text-[200px] leading-[74px]">
          429
        </p>
        <p className="font-sans font-semibold text-[30px] md:text-[40px] leading-[74px] mt-20">
          Too many requests
        </p>
        <p className="font-sans font-normal text-[24px] leading-[34px]">
          We are experiencing high traffic now. Please wait for a moment and try
          again.
        </p>
        <p className="font-sans font-normal text-[24px] leading-[34px] mt-4">
          Retry after: {remainingTime} seconds
        </p>
        <div className="w-[300px] mx-auto mt-10">
          <Button
            type="button"
            title="Wait for Redirection"
            variant="apply-now-btn"
            iconSrc={applyNow}
            handleClick={navigateToPrevious}
          />
        </div>
      </div>
    </section>
  );
}
