import { useEffect } from 'react';

const useServiceFormChatbot = () => {
  useEffect(() => {
    const tD = new Date().toISOString().slice(0, 10);
    const sfpid = 1372985;
    const scriptUrl = `https://cdn.serviceform.com/serviceform-tools/pixel/sf-pixel.js?${tD}`;
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = scriptUrl;
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
};

export default useServiceFormChatbot;
