import { getBaseUrl } from '@/config/BaseUrl';
import { COUNTRY_CODE } from '@/constants/Constant';

export default async function getAllStatesDetailsByCountryApi(
  country_id?: number
) {
  try {
    const baseUrl = getBaseUrl();
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    };

    const finalCountryId = country_id ?? COUNTRY_CODE;

    const response = await fetch(
      `${baseUrl}web/getCountryData/${finalCountryId}`,
      requestOptions
    );
    const responseData = await response.json();

    if (!response.ok) {
      throw { status: response.status, data: responseData };
    }

    return responseData;
  } catch (error) {
    console.error('Error getting states, regions and suburb details: ', error);
    throw error;
  }
}
