import { CommonHero, MobileRegisterHero, RegisterForm } from '@/components';
import SeoOptimized from '@/components/reactHelmet/SeoOptimized';
import { getProductionUrl } from '@/config/getProductionUrl';
import useFetchAllSeoDetails from '@/hooks/useFetchAllSeoDetails';
import useResponsive from '@/hooks/useResponsive';
import { MobileLayout, PageLayout } from '@/layout';
import { useLocation } from 'react-router-dom';

export default function Register() {
  const CurLocation = useLocation();
  const { isMobile } = useResponsive();
  const productionUrl = getProductionUrl();
  const { seoData } = useFetchAllSeoDetails(
    `${productionUrl}${CurLocation.pathname}`
  );

  return (
    <>
      {seoData && (
        <SeoOptimized
          title={seoData?.title}
          description={seoData?.description}
          robots={seoData?.robots}
          twitterImage={seoData?.twitterImage || seoData?.image}
          ogTitle={seoData?.og_title}
          ogDescription={seoData?.og_description}
          ogImage={seoData?.og_image}
          ogUrl={seoData?.og_URL}
          ogSiteName={seoData?.og_site_name}
          ogLocale={seoData?.og_locale}
          ogType={seoData?.og_type}
          canonicalUrl={seoData?.canonical}
        />
      )}
      {isMobile ? (
        <>
          <MobileLayout>
            <MobileRegisterHero />
          </MobileLayout>
        </>
      ) : (
        <>
          <CommonHero />
          <PageLayout>
            <div className="h-[1200px] relative flex items-center justify-center">
              <div className="absolute top-[-220px]">
                <RegisterForm />
              </div>
            </div>
          </PageLayout>
        </>
      )}
    </>
  );
}
