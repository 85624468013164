import {
  BannerComponent,
  CommonMobileHero,
  DropDownSection,
  GratitudeComponent,
  HeroBackground,
  HeroBackground2,
  InsightComponent,
  JobSection,
  MainJobCard,
  MobileJobSection,
  Register,
} from '@/components';
import { MobileLayout, PageLayout } from '@/layout';
import React, { useEffect, useState } from 'react';
import useResponsive from '@/hooks/useResponsive';
import MainMobileJobCard from '@/components/mobile/cards/MainMobileJobCard';
import useFetchFilterJobDetails from '@/hooks/useFetchFilterJobsDetails';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LandingReachUs from '@/components/forms/reachUs/LandingReachUs';
import useFetchAllProfessions from '@/components/forms/selectors/hook/useFetchAllProfessions';
import { formatURLNameToNormal } from '@/utils/formatNameUtil';
import {
  AWS_BASE_ASSETS_SENIORITY,
  AWS_BASE_PROFESSIONAL_BANNERS,
} from '@/config/seviceApiConfig';
import PageLoadingComponent from '@/components/noData/PageLoadingComponent';
import { motion } from 'framer-motion';
import useFetchFilterData from '@/hooks/useFetchFilterMenuData';
import NewCardComponent from '@/components/home/newBanner/NewCardComponent';
import JobsLoadingComponent from '@/components/noData/JobsLoading';
import { getProductionUrl } from '@/config/getProductionUrl';
import useFetchAllSeoDetails from '@/hooks/useFetchAllSeoDetails';
import SeoOptimized from '@/components/reactHelmet/SeoOptimized';

export default function AllMedicalJobs() {
  const CurLocation = useLocation();
  const matches = CurLocation.pathname.match(
    /all-(permanent|locum)-([^/]+)-jobs-in-newzealand/
  );
  const jobType = matches ? matches[1] : '';
  const profession = matches ? matches[2] : '';
  const { filterMenuData, loading } = useFetchFilterData();
  const { isMobile, screenSize } = useResponsive();
  const [divisionId, setDivisionId] = useState<number>();
  const [description, setDescription] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [miniTitle, setMiniTitle] = useState<string>('');
  const [banner, setBanner] = useState<string>('');
  const [bannerAlt, setBannerAlt] = useState<string>('');
  const [stateJobs, setStateJobs] = useState({});
  const [loadingJobs, setLoadingJobs] = useState(true);

  const productionUrl = getProductionUrl();
  const { seoData } = useFetchAllSeoDetails(
    `${productionUrl}${CurLocation.pathname}`
  );

  useEffect(() => {
    if (profession === 'doctor') {
      setDivisionId(1);
      const divisionData = filterMenuData?.find(
        (div) => div.divisions_id === 1
      );
      console.log(divisionData);
      if (divisionData) {
        setDescription(divisionData.description);
        setMiniTitle(divisionData.mini_title);
        setTitle(divisionData.title);
        setBanner(`${AWS_BASE_ASSETS_SENIORITY}${divisionData.banner}`);
      }
    } else if (profession === 'nurses') {
      setDivisionId(2);
      const divisionData = filterMenuData?.find(
        (div) => div.divisions_id === 2
      );
      if (divisionData) {
        setDescription(divisionData.description);
        setMiniTitle(divisionData.mini_title);
        setTitle(divisionData.title);
        setBanner(`${AWS_BASE_ASSETS_SENIORITY}${divisionData.banner}`);
      }
    }
  }, [filterMenuData, profession]);

  const { filterData, FilterDataLoading, FilterDataError } =
    useFetchFilterJobDetails({
      divisionId,
    });
  const filteredData = filterData?.filter((job) => {
    if (jobType === 'permanent') {
      return job.is_permanent === '1';
    } else {
      return job.is_permanent !== '1';
    }
  });

  useEffect(() => {
    if (!FilterDataLoading) {
      const jobsByState = {};
      filteredData?.forEach((job) => {
        const stateName = job.state?.name;
        if (stateName) {
          if (!jobsByState[stateName]) {
            jobsByState[stateName] = [];
          }
          jobsByState[stateName].push(job);
        }
      });
      setStateJobs(jobsByState);
      setLoadingJobs(false);
    }
  }, [filteredData, FilterDataLoading]);

  const hasJobs = Object.keys(stateJobs).length > 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return <PageLoadingComponent />;
  }

  if (FilterDataError) {
    return <div>Error: {FilterDataError.message}</div>;
  }

  return (
    <>
      {seoData && (
        <SeoOptimized
          title={seoData?.title}
          description={seoData?.description}
          robots={seoData?.robots}
          twitterImage={seoData?.twitterImage || seoData?.image}
          ogTitle={seoData?.og_title}
          ogDescription={seoData?.og_description}
          ogImage={seoData?.og_image}
          ogUrl={seoData?.og_URL}
          ogSiteName={seoData?.og_site_name}
          ogLocale={seoData?.og_locale}
          ogType={seoData?.og_type}
          canonicalUrl={seoData?.canonical}
        />
      )}
      {isMobile ? (
        <>
          <CommonMobileHero
            MainTitle={'Elevate your medical career to unprecedented levels.'}
            SubTitle={'All Jobs'}
            Paragraph={
              'Navigate your way to a fulfilling medical career with Medfuture. Our specialized recruitment approach connects you with opportunities in your preferred location across Australia. Embrace a career path that aligns with your aspirations, supported by our commitment to excellence in medical recruitment.'
            }
          />
          <MobileLayout>
            <MobileJobSection />
            {/* <div className="flex flex-col gap-y-6">
                  {data.slice(0, visibleJobs).map((job, index) => (
                    <div key={index}>
                      {!screenSize.md ? (
                        <>
                          <MainMobileJobCard data={job} />
                        </>
                      ) : (
                        <>
                          <div className="grid grid-cols-2 gap-5">
                            <MainMobileJobCard data={job} />
                            {index < data.length - 1 && (
                              <MainMobileJobCard data={data[index + 1]} />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div> */}
            {/* <div className="text-center mt-10 mb-10">
                  {data.length > visibleJobs && (
                    <button
                      onClick={handleLoadMore}
                      className="text-blue-390 text-center text-[20px] leading-[23px]"
                    >
                      Load More...
                    </button>
                  )}
                </div> */}
          </MobileLayout>
        </>
      ) : (
        <>
          <HeroBackground2
            title={title}
            description={miniTitle}
            icon={''}
            banner={banner}
            bannerAlt={bannerAlt}
          />
          <BannerComponent />
          <PageLayout>
            <div className="px-4">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
                className="text-center font-sans font-normal text-[33px] lg:text-[24px] leading-[48px] lg:leading-[36px] text-blue-350 padding-container"
              >
                {description?.split('').map((char, index) => (
                  <motion.span
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 + index * 0.01 }}
                  >
                    {char}
                  </motion.span>
                ))}
              </motion.div>
            </div>
            {FilterDataLoading || loadingJobs ? (
              <div className="flex items-center justify-center">
                <JobsLoadingComponent />
              </div>
            ) : hasJobs ? (
              <JobSection hasJobs={hasJobs} stateJobs={stateJobs} />
            ) : (
              <div className="text-center font-normal font-sans text-[42px] leading-[88px] my-20">
                No jobs are currently available
              </div>
            )}
            <LandingReachUs />
            {/* <DropDownSection /> */}
            <NewCardComponent />
            {/* <InsightComponent /> */}
          </PageLayout>
        </>
      )}
    </>
  );
}
