import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';
import { LogoProps } from '../../../types/types';

export default function Logo({ height, width, handleClick }: LogoProps) {
  return (
    <header>
      <div className="w-[200px] md:w-[300px] h-[65px] lg:w-[300px] lg:h-[80px]">
        <img
          src={`${AWS_BASE_URL_ASSETS}/Medfuture-logo-white.png`}
          height={height}
          width={width}
          loading="eager"
          alt="Logo"
          className="cursor-pointer object-contain h-full w-full object-center"
          onClick={handleClick}
        />
      </div>
    </header>
  );
}
