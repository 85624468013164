import { getBaseUrl } from '@/config/BaseUrl';

interface ParameterProps {
  profession_id: number;
}

export default async function GetAllSpecialtyByProfession(
  profession_id: ParameterProps
) {
  let res: string = '';
  try {
    const baseUrl = getBaseUrl();
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    };
    await fetch(`${baseUrl}web/getSpeciality/${profession_id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.error('Error getting specialties: ', error);
        res = error;
      });
  } catch (error) {
    console.error(error);
  }

  return res;
}
