import { useEffect, useState, useMemo, useCallback } from 'react';
import GetAllJobDetailsApi from '@/api/GetAllJobDetailsApi';

interface FilterJobsData {}

interface FetchJobFilterData {
  filterData: FilterJobsData[] | null;
  FilterDataLoading: boolean;
  FilterDataError: Error | null;
}

interface FilterJobsParameterProps {
  divisionId?: number;
  professionId?: number;
  stateId?: number;
  specialtyId?: number;
  seniorityId?: number;
  regionId?: number;
  suburbId?: number;
}

export default function useFetchFilterJobDataForSpecialty({
  divisionId,
  professionId,
  stateId,
  specialtyId,
  seniorityId,
  regionId,
  suburbId,
}: FilterJobsParameterProps): FetchJobFilterData {
  console.log(divisionId);
  console.log(professionId);
  console.log(specialtyId);
  const [filterData, setFilterData] = useState<FilterJobsData[] | null>(null);
  const [FilterDataLoading, setLoading] = useState<boolean>(true);
  const [FilterDataError, setError] = useState<Error | null>(null);

  const memoizedParams = useMemo(
    () => ({
      divisionId,
      professionId,
      stateId,
      specialtyId,
      seniorityId,
      regionId,
      suburbId,
    }),
    [
      divisionId,
      professionId,
      stateId,
      specialtyId,
      seniorityId,
      regionId,
      suburbId,
    ]
  );

  const hasAtLeastOneParam = useMemo(
    () =>
      divisionId !== undefined ||
      professionId !== undefined ||
      stateId !== undefined ||
      specialtyId !== undefined ||
      seniorityId !== undefined ||
      regionId !== undefined ||
      suburbId !== undefined,
    [
      divisionId,
      professionId,
      stateId,
      specialtyId,
      seniorityId,
      regionId,
      suburbId,
    ]
  );

  const hasRequiredParams = useMemo(
    () =>
      divisionId !== undefined &&
      professionId !== undefined &&
      specialtyId !== undefined,
    [divisionId, professionId, specialtyId]
  );

  const fetchData = useCallback(async () => {
    if (!hasRequiredParams || !hasAtLeastOneParam) {
      setLoading(true);
      return;
    }

    try {
      setLoading(true);
      const response = await GetAllJobDetailsApi({
        divisionId: memoizedParams.divisionId ?? null,
        professionId: memoizedParams.professionId ?? null,
        stateId: memoizedParams.stateId ?? null,
        specialtyId: memoizedParams.specialtyId ?? null,
        seniorityId: memoizedParams.seniorityId ?? null,
        regionId: memoizedParams.regionId ?? null,
        suburbId: memoizedParams.suburbId ?? null,
      });
      setFilterData(response.data);
    } catch (error) {
      setError(error as Error);
      setFilterData(null);
    } finally {
      setLoading(false);
    }
  }, [memoizedParams, hasAtLeastOneParam, hasRequiredParams]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { filterData, FilterDataLoading, FilterDataError };
}
