import { useEffect, useState, useCallback } from 'react';
import GetAllStatesWithRegionsApi from '@/api/GetStatesWithRegionsApi';
import { useNavigate } from 'react-router-dom';

interface stateWithRegionsData {
  regions_id: number;
  state_id: number;
  country_id: number;
  name: string;
  description: string;
  status: number;
  image: string;
  image_alt: string;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

interface FetchStateWithRegions {
  stateWithRegions: stateWithRegionsData[] | null;
  loading: boolean;
  error: Error | null;
  fetchStatesWithRegions: () => void;
}

let cachedData: stateWithRegionsData[] | null = null;

export default function useFetchStatesWithRegions(): FetchStateWithRegions {
  const navigate = useNavigate();
  const [stateWithRegions, setStateWithRegions] = useState<
    stateWithRegionsData[] | null
  >(cachedData);
  const [loading, setLoading] = useState<boolean>(!cachedData);
  const [error, setError] = useState<Error | null>(null);

  const fetchStatesWithRegions = useCallback(async () => {
    if (cachedData) {
      setStateWithRegions(cachedData);
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const response = await GetAllStatesWithRegionsApi();
      cachedData = response.data[0].states;
      setStateWithRegions(cachedData);
    } catch (error: any) {
      if (error.status === 429) {
        navigate('/many-requests', {
          state: { retryAfter: error.data.retry_after },
        });
      } else {
        setError(error as Error);
      }
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    if (!stateWithRegions) {
      fetchStatesWithRegions();
    }
  }, [fetchStatesWithRegions, stateWithRegions]);

  return { stateWithRegions, loading, error, fetchStatesWithRegions };
}
