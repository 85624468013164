import data from '../../../../data/Offer.json';
import MobileLocumData from './MobileLocumData';
import { FC, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import MobileJobCard from '@/components/cards/mobileJobCard/MobileJobCard';
import { OfferDataProps } from './type';

export default function MobileLocum({ data }) {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const filteredJobData = data?.filter((job) => job.is_permanent !== '1');

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    rows: 3,
    slidesToScroll: 1,
    autoplay: false,
    touchMove: true,
    className: 'custom-setting',
    appendDots: (dots: JSX.Element[]) => (
      <ul className="slick-dots">
        {dots.slice(0, 3).map((dot, index) => (
          <li key={index} className="inline-block m-2 cursor-pointer">
            {dot}
          </li>
        ))}
      </ul>
    ),
    customPaging: (i: number) => (
      <div
        className={`w-[0.688rem] h-[0.688rem] lg:w-[0.813rem] lg:h-[0.813rem] rounded-full ${i === activeIndex ? 'bg-blue-400' : 'bg-gray-450'}`}
      />
    ),
    beforeChange: () => {
      const maxIndex = 2;
      //const maxIndex = Math.ceil(data.length / settings.slidesToShow) - 1;
      setActiveIndex((oldIndex) => {
        const newIndex = oldIndex + 1;
        return newIndex > maxIndex ? 0 : newIndex;
      });
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <section className="mobile-padding-container">
      <div className="flex items-center justify-between mb-5">
        <h2 className="section_header_text w-[60%]">Locum jobs</h2>
      </div>
      <div>
        <Slider {...settings}>
          {filteredJobData?.map((offer) => (
            <div key={offer.jobdetails_id} className="my-1">
              <MobileJobCard key={offer.id} offer={offer} />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
