export function validateUrl(url: string) {
  const urlPattern =
    /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?$/;

  if (!urlPattern.test(url)) {
    return 'Please enter a valid website URL (e.g., https://www.example.com)';
  }

  return '';
}
