import React from 'react';
import { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface MobileSlickSettingsProps {
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const MobileSlickSettings = ({
  activeIndex,
  setActiveIndex,
}: MobileSlickSettingsProps): Settings => {
  const handleBeforeChange = () => {
    const maxIndex = 2;
    setActiveIndex((oldIndex) => {
      const newIndex = oldIndex + 1;
      return newIndex > maxIndex ? 0 : newIndex;
    });
  };

  return {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: 1,
    swipe: true,
    autoplay: false,
    touchMove: true,
    appendDots: (dots: JSX.Element[]) => (
      <div className="mobile-slick-dots">
        {dots.slice(0, 3).map((dot, index) => (
          <div key={index} className="inline-block m-[2px] cursor-pointer">
            {dot}
          </div>
        ))}
      </div>
    ),
    customPaging: (i: number) => (
      <div
        className={`w-[0.625rem] h-[0.625rem] m-0 rounded-full ${i === activeIndex ? 'bg-blue-400' : 'bg-gray-450'}`}
      />
    ),
    beforeChange: handleBeforeChange,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };
};
