import { HeroBackground } from '@/components';
import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import useResponsive from '@/hooks/useResponsive';
import { PageLayout } from '@/layout';
import { Link } from 'react-router-dom';

export default function Compliance() {
  const { isMobile } = useResponsive();
  return (
    <>
      {isMobile ? (
        <>
          {/* <CommonMobileHero
            MainTitle={'General Practitioner '}
            SubTitle={'70% Private billing Mitchelton'}
            IconSrc={location}
            LocationName={'Port Macquarie, New South Wales'}
          />
          <MobileLayout></MobileLayout> */}
        </>
      ) : (
        <>
          <HeroBackground title="Compliance" />
          <PageLayout bgColor="gray-80">
            <div className="px-28 py-32 my-32 bg-white shadow">
              <h1 className="font-sans font-bold text-[60px] leading-[85px] text-center text-blue-550">
                Medfuture Pty Ltd
              </h1>
              <div className="font-sans font-normal text-[24px] leading-[24px] text-center text-black">
                <p className="my-4">NZBN - 9429046885838</p>
              </div>
              <div>
                <p className="terms-paragraph">
                  Medfuture Medical & Healthcare Recruitment (Medfuture Pty Ltd)
                  is a Corporate Member with RCSA as well as Full member with
                  APSCO New Zealand. The RCSA is one of the Representative
                  entities for Recruitment Industry in New Zealand and New
                  Zealand. The RCSA as a body, Their Primary Objective is to set
                  a Quality of Service & Best practices in Recruitment industry.
                  Similarly, APSCo New Zealand also one of the Entity which
                  pledge and commit for the Best practices of Recruitment
                  industry by introduce their services in Training, Coaching &
                  Sharing Best practice Service knowledge with their Registered
                  members.
                </p>
                <p className="terms-paragraph">
                  Medfuture as an Organization committed to provide its services
                  with quality by ensuring best practices and appropriate
                  conduct. Medfuture is a Corporate member with AMRANZ & ANRA
                  Which are sub group body of RCSA.
                </p>
                <h1 className="terms-title">
                  Copyright & Intellectual Property
                </h1>
                <p className="terms-paragraph">
                  Every contents and design (Other than Third-party trademarks
                  and contents) of this Websites are the property of Medfuture
                  Pty Ltd. You can access and view the contents but not
                  permitted to reproduce or use them without written permission
                  from Medfuture.
                </p>
                <p className="terms-paragraph">
                  Any Third Party Would like to use the contents or Job Board
                  must hold Written permission by the Medfuture pty Ltd.
                </p>
                <p className="terms-paragraph">
                  Any queries email to -{' '}
                  <Link
                    className="font-bold"
                    to={`mailto:helpdesk@medfuture.co.nz`}
                  >
                    helpdesk@medfuture.co.nz
                  </Link>
                </p>
              </div>
            </div>
          </PageLayout>
          <PageLayout>
            <section className="max-width-container">
              <div className="main-width-container">
                <div className="px-2">
                  <div className="flex items-center justify-between h-[9.5rem]">
                    <img
                      src={`${AWS_BASE_ASSETS_ICONS}Banner1.png`}
                      alt="image1"
                      width={192}
                      height={52}
                    />
                    <img
                      src={`${AWS_BASE_ASSETS_ICONS}Banner2.png`}
                      alt="image2"
                      width={192}
                      height={52}
                    />
                    <img
                      src={`${AWS_BASE_ASSETS_ICONS}Banner3.png`}
                      alt="image3"
                      width={192}
                      height={52}
                    />
                    <img
                      src={`${AWS_BASE_ASSETS_ICONS}Banner4.png`}
                      alt="image4"
                      width={192}
                      height={52}
                    />
                  </div>
                </div>
              </div>
            </section>
          </PageLayout>
        </>
      )}
    </>
  );
}
