import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import useResponsive from '@/hooks/useResponsive';
import { useLocation } from 'react-router-dom';
import useFetchSeniorityProfessionWise from './hook/useFetchAllSeniorityProfessionWise';

interface SenioritySelectorProps {
  onChange: (value: string) => void;
  error: string;
  professionId: number;
  resetSelector?: boolean;
  seniorityId?: string;
}

const SenioritySelector: React.FC<SenioritySelectorProps> = ({
  onChange,
  error,
  professionId,
  resetSelector = false,
  seniorityId,
}) => {
  const [selectedSeniority, setSelectedSeniority] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const { senioritiesProfessionWise } =
    useFetchSeniorityProfessionWise(professionId);
  const { isMobile } = useResponsive();
  const location = useLocation();
  const [defaultOptions, setDefaultOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const mapResponseToValuesAndLabels = (data: any) => ({
    value: data.seniorities_id.toString(),
    label: data.name,
  });

  useEffect(() => {
    if (resetSelector) {
      handleReset();
    }
  }, [resetSelector]);

  useEffect(() => {
    if (seniorityId && senioritiesProfessionWise) {
      const seniority = senioritiesProfessionWise.find(
        (p) => p.seniorities_id.toString() === seniorityId.toString()
      );
      if (seniority) {
        const mappedSeniority = mapResponseToValuesAndLabels(seniority);
        setSelectedSeniority(mappedSeniority);
      }
    }
  }, [seniorityId, senioritiesProfessionWise]);

  useEffect(() => {
    if (senioritiesProfessionWise) {
      const mappedOptions = senioritiesProfessionWise.map((seniority: any) => ({
        value: seniority.seniorities_id.toString(),
        label: seniority.name,
      }));
      setDefaultOptions(mappedOptions);
    }
  }, [senioritiesProfessionWise]);

  const handleReset = () => {
    setSelectedSeniority(null);
    onChange && onChange('');
  };

  const loadSeniorities = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    if (!senioritiesProfessionWise) {
      callback([]);
      return;
    }

    const filteredOptions = senioritiesProfessionWise
      .filter((seniority: any) =>
        seniority.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((seniority: any) => ({
        value: seniority.seniorities_id.toString(),
        label: seniority.name,
      }));

    callback(filteredOptions);
  };

  const handleChange = (selectedOption: any) => {
    setSelectedSeniority(selectedOption);
    onChange(selectedOption ? selectedOption.value : '');
  };

  const renderFormStyle = (hasError: boolean) => {
    const path = location.pathname;
    let baseClass = isMobile ? 'mobile-form-container' : 'form-container';

    if (path === '/premier-locum-general-practitioner') {
      baseClass = `locum-form-container ${hasError ? 'outline-red-300' : 'outline-blue-560'}`;
    } else if (
      path === '/premier-locum-doctor' ||
      path === '/allied-health-professional' ||
      path === '/nursing' ||
      path === '/international-medical-graduate-recruitment'
    ) {
      baseClass = `locum-form-container ${hasError ? 'outline-red-300' : 'outline-white'}`;
    } else {
      baseClass = `${baseClass} ${hasError ? 'outline-red-300' : 'outline-blue-250'}`;
    }

    return baseClass;
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      border: 'none',
      outline: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer', // Adding cursor pointer here
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? 'rgba(28, 155, 221, 1)'
        : state.isFocused
          ? '#ecf0f1'
          : null,
      color: state.isSelected || state.isFocused ? '#515869' : '#515869',
      animation: 'fadeInPositive 0.5s ease-in-out',
    }),
    menu: (provided) => ({
      ...provided,
      backdropFilter: 'blur(10px)',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      marginRight: '8px',
      marginBottom: '0px',
    }),
    menuList: (provided) => ({
      ...provided,
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: '0px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#A8a9ad',
        borderRadius: '0px',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#515869',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#515869',
    }),
  };

  return (
    <div className={`select-wrapper ${renderFormStyle(!!error)} form-text`}>
      <AsyncSelect
        id="seniority"
        value={selectedSeniority}
        onChange={handleChange}
        loadOptions={loadSeniorities}
        defaultOptions={defaultOptions}
        placeholder="Seniority"
        styles={customStyles}
        isClearable
        cacheOptions
        // isDisabled={!!seniorityId}
      />
      {/* {error && <div className="form-error-msg">{error}</div>} */}
    </div>
  );
};

export { SenioritySelector };
