import BannerData from '../../../home/banner/BannerData';
import data from '../../../../data/banner.json';

export default function Services() {
  return (
    <section className="mobile-padding-container">
      <h2 className="section_header_text">Enjoy our premier services</h2>
      <BannerData data={data} />
    </section>
  );
}
