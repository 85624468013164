import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';
import { images } from '@/constants/EmployerService';

export default function MedfutureRoundCarousel() {
  const radiusX = 600;
  const radiusY = 300;

  return (
    <section className="padding-container">
      <div className="relative flex justify-center items-center h-[700px]">
        <div className="relative w-full h-full flex justify-center items-center">
          <img
            src={`${AWS_BASE_URL_ASSETS}/medfuture/Medfuture-logo-2.png`}
            alt="Medfuture Logo"
            className="absolute w-[303px] h-[85px]"
          />

          {images.map((image, index) => {
            const angle = (index / images.length) * 2 * Math.PI;
            const x = radiusX * Math.cos(angle);
            const y = radiusY * Math.sin(angle);

            return (
              <div
                key={index}
                className="absolute"
                style={{
                  top: `calc(50% + ${y}px)`,
                  left: `calc(50% + ${x}px)`,
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="w-48 h-48 rounded-full object-cover object-center"
                />
                <p className="text-center text-sm leading-tight text-blue-350 mt-4">
                  {image.label}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
