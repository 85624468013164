import React from 'react';
import Button from '@/components/shared/button/Button';
import FilterIcon from '/icons/Filter.png';
import OffCanvas from '../MobileFilter/MobileFilterComponent';
import { useStateContext } from '@/context/ContextProvider';

const JobSection: React.FC = () => {
  const { isOffCanvasOpen, openOffCanvas } = useStateContext();

  return (
    <div className="py-[1.125rem] md:py-8">
      <div className="flex items-center justify-end">
        <Button
          type="button"
          title="Filter & Sort"
          variant="filter-btn"
          iconSrc={FilterIcon}
          handleClick={openOffCanvas}
        />
      </div>
      <OffCanvas isOpen={isOffCanvasOpen} />
    </div>
  );
};

export default JobSection;
