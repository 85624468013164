import FeedBackCarousel from '@/components/feedback/FeedbackCaresoul';

export default function MobileGratitude({ data }) {
  const GetTextColor = () => {
    const path = location.pathname;
    if (path === '/premier-locum-general-practitioner') {
      return 'text-gratitude-gold-gradient';
    } else {
      return 'text-silver-gradient';
    }
  };
  return (
    <section>
      <h1 className="font-sans font-normal text-[26px] leading-[76px] text-blue-520">
        Testimonials
      </h1>
      <h2 className="font-sans font-normal text-[20px] leading-[30px] text-gray-120">
        Best Reviews From{' '}
        <span className={`${GetTextColor()}`}>Our Clients</span>
      </h2>
      <FeedBackCarousel data={data} />
    </section>
  );
}
