import { HeroBackground } from '@/components';
import PrivacyPolicyComponent from '@/components/policyComponents/PrivacyPolicyComponent';
import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import useResponsive from '@/hooks/useResponsive';
import { PageLayout } from '@/layout';

export default function PrivacyPolicy() {
  const { isMobile } = useResponsive();
  return (
    <>
      {isMobile ? (
        <>
          {/* <CommonMobileHero
            MainTitle={'General Practitioner '}
            SubTitle={'70% Private billing Mitchelton'}
            IconSrc={location}
            LocationName={'Port Macquarie, New South Wales'}
          />
          <MobileLayout></MobileLayout> */}
        </>
      ) : (
        <>
          <HeroBackground title="Privacy Policy" />
          <PageLayout bgColor="gray-80">
            <div className="py-40">
              <PrivacyPolicyComponent />
            </div>
          </PageLayout>
          <PageLayout>
            <section className="max-width-container">
              <div className="main-width-container">
                <div className="px-2">
                  <div className="flex items-center justify-between h-[9.5rem]">
                    <img
                      src={`${AWS_BASE_ASSETS_ICONS}Banner1.png`}
                      alt="image1"
                      width={192}
                      height={52}
                    />
                    <img
                      src={`${AWS_BASE_ASSETS_ICONS}Banner2.png`}
                      alt="image2"
                      width={192}
                      height={52}
                    />
                    <img
                      src={`${AWS_BASE_ASSETS_ICONS}Banner3.png`}
                      alt="image3"
                      width={192}
                      height={52}
                    />
                    <img
                      src={`${AWS_BASE_ASSETS_ICONS}Banner4.png`}
                      alt="image4"
                      width={192}
                      height={52}
                    />
                  </div>
                </div>
              </div>
            </section>
          </PageLayout>
        </>
      )}
    </>
  );
}
