import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BannerDataProps, BannerItem } from './types'; // Assuming you have a type for BannerItem
import BlurryLoadingImage from '../../../hooks/useFetchImage';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useResponsive from '@/hooks/useResponsive';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';

const BannerData: React.FC<BannerDataProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { isMobile } = useResponsive();
  const location = useLocation();

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    touchMove: true,
    appendDots: (dots: JSX.Element[]) => (
      <div className="slick-dots">
        {dots.map((dot, index) => (
          <div key={index} className="inline-block m-[2px] cursor-pointer">
            {dot}
          </div>
        ))}
      </div>
    ),
    customPaging: (i: number) => (
      <div
        className={`w-[0.688rem] h-[0.688rem] lg:w-[0.813rem] lg:h-[0.813rem] rounded-full mt-7 ${i === activeIndex ? 'bg-blue-400' : 'bg-gray-450'}`}
      />
    ),
    beforeChange: () => {
      const maxIndex = 2;
      setActiveIndex((oldIndex) => {
        const newIndex = oldIndex + 1;
        return newIndex > maxIndex ? 0 : newIndex;
      });
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {!isMobile ? (
        <div className="grid grid-cols-3 gap-4 cursor-pointer">
          {data.map((item: BannerItem) => (
            <Link to={`${item.route}`} key={item.id}>
              <BlurryLoadingImage
                preview={`${AWS_BASE_URL_ASSETS}${item.url}`}
                image={`${AWS_BASE_URL_ASSETS}${item.url}`}
                alt={item.title}
                imageStyleClass=""
                divStyleClass="banner-container"
                bgColor={''}
              />
            </Link>
          ))}
        </div>
      ) : (
        <Slider {...settings}>
          {data.map((item: BannerItem) => (
            <Link key={item.id} to={item.route}>
              <BlurryLoadingImage
                preview={`${AWS_BASE_URL_ASSETS}${item.url}`}
                image={`${AWS_BASE_URL_ASSETS}${item.url}`}
                alt={item.title}
                imageStyleClass=""
                divStyleClass="banner-container"
                bgColor={''}
              />
            </Link>
          ))}
        </Slider>
      )}
    </>
  );
};

export default BannerData;
