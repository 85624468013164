import GetJobCountByState from '@/api/preferences/GetJobCountByStateApi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface AllJobData {
  // Define your data structure here if needed
}

interface FetchAllJobData {
  jobCountByState: AllJobData[] | null;
  loading: boolean;
  error: Error | null;
}

export default function useFetchJobCountForHomeByState(): FetchAllJobData {
  const navigate = useNavigate();
  const [jobCountByState, setJobCountByState] = useState<AllJobData[] | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetJobCountByState();
      setJobCountByState(response.data); // Assuming response structure has a data field
    } catch (error: any) {
      if (error.status === 429) {
        navigate('/many-requests', {
          state: { retryAfter: error.data.retry_after },
        });
      } else if (error.status === 500) {
        navigate('/server-error');
      } else {
        setError(error as Error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { jobCountByState, loading, error };
}
