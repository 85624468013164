import { HeroCommonProps } from '@/types/types';
import Button from '../shared/button/Button';
import { HeroStatus } from '..';
import BlurryLoadingImage from '@/hooks/useFetchImage';
import { motion } from 'framer-motion';

export default function HeroBackground2({
  title,
  description,
  banner,
  bannerAlt,
}: HeroCommonProps & { banner: string; bannerAlt: string }) {
  const navigateToRegister = () => {
    const registerForm = document.getElementById('register-form');
    if (registerForm) {
      registerForm.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const textVariant = {
    initial: {
      x: -500,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <section
      className={`max-width-container relative h-[42.5rem] w-screen flex items-center justify-center overflow-hidden`}
      aria-label={bannerAlt}
    >
      <BlurryLoadingImage
        preview={banner}
        image={banner}
        alt={bannerAlt}
        imageStyleClass="absolute inset-0 w-full h-full object-cover object-right-top"
        divStyleClass="absolute inset-0"
        bgColor="transparent"
      />
      <div className="absolute inset-0 custom-gradient" />
      <motion.div
        className="absolute 2xl:top-[90px] lg:top-[80px] left-[15%] transform -translate-x-[15%] inset-0 text-white z-6"
        variants={textVariant}
        initial="initial"
        animate="animate"
      >
        <motion.div className="w-[50rem]">
          <motion.div className="h-[230px]">
            <motion.h1 className="common-hero-main" variants={textVariant}>
              {title}
            </motion.h1>
            <motion.p className="common-hero-desc" variants={textVariant}>
              {description}
            </motion.p>
          </motion.div>
          <motion.div className="mt-24 w-[202px]" variants={textVariant}>
            <Button
              type="button"
              title="Register Now"
              variant="find-jobs-reg-btn"
              handleClick={navigateToRegister}
            />
          </motion.div>
        </motion.div>
        <motion.div
          className="hidden lg:flex items-center justify-between mt-10 w-[33.25rem]"
          variants={textVariant}
        >
          <HeroStatus />
        </motion.div>
      </motion.div>
    </section>
  );
}
