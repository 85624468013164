import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function LoadingComponent() {
  return (
    <div className="bg-white shadow-md max-w-[980px] h-[420px] xl:h-[390px] 2xl:h-[364px] p-4 border-[2px] border-gray-320 rounded-md relative cursor-pointer">
      <div className="bg-gray-80 p-3 rounded-[10px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-normal mb-1">
            <h3 className="card-text-main mr-4">
              <Skeleton width={100} />
            </h3>
            <h3 className="text-gray-480 font-semibold bg-gray-20 border rounded-md border-gray-320 px-[6px] text-sm">
              <Skeleton width={50} />
            </h3>
          </div>
          <div className="flex items-center gap-2">
            <h1 className=" text-black">
              <Skeleton width={80} />
            </h1>
            <Skeleton circle={true} height={25} width={25} />
          </div>
        </div>
        <div className="flex items-end justify-between">
          <div className="grid grid-cols-2 gap-x-20">
            {Array(4)
              .fill()
              .map((_, index) => (
                <div className="flex items-center my-1" key={index}>
                  <Skeleton circle={true} height={30} width={30} />
                  <p className="ml-2">
                    <Skeleton width={100} />
                  </p>
                </div>
              ))}
          </div>
          <Skeleton height={25} width={25} />
        </div>
      </div>
      <div className="p-3">
        <h3 className="font-sans font-normal text-[28px] leading-[34px] text-blue-700 4xl:min-w-[920px] line-clamp-1">
          <Skeleton width={`60%`} />
        </h3>
        <p className="font-satoshi text-base leading-[29px] font-normal text-gray-700 line-clamp-2 mt-2">
          <Skeleton count={2} />
        </p>
      </div>
      <div className="w-[182px] absolute right-4 bottom-4">
        <Skeleton height={40} width={140} />
      </div>
    </div>
  );
}
