import {
  CommonMobileHero,
  DropDownSection,
  GratitudeComponent,
  HeroBackground,
  InsightComponent,
  MainJobCard,
  MobileJobSection,
  Register,
} from '@/components';
import { MobileLayout, PageLayout } from '@/layout';
import React, { useEffect, useState } from 'react';
import useResponsive from '@/hooks/useResponsive';
import MainMobileJobCard from '@/components/mobile/cards/MainMobileJobCard';
import useFetchFilterJobDetails from '@/hooks/useFetchFilterJobsDetails';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useFetchAllProfessions from '@/components/forms/selectors/hook/useFetchAllProfessions';
import useFetchAllState from '@/components/forms/selectors/hook/useFetchAllStates';
import { formatURLNameToNormal } from '@/utils/formatNameUtil';
import PermanentJobFilter from '@/components/filter/PermanentJobFilter';
import LocumJobFilter from '@/components/filter/LocumJobFilter';
import SubmitFormModal from '@/components/models/SubmitFormModal';
import NoJobsCard from '@/components/noData/NoJobsCard';
import LoadingComponent from '@/components/noData/LoadComponent';
import { formatNameForURL } from '@/utils/formatNameForURL';
import useFetchAllStateDetailsByCountry from '@/hooks/useFetchAllStateDetailsByCountry';
import useFetchSpecialtyProfessionWise from '@/components/forms/selectors/hook/useFetchSpecialtyProfessionWise';
import useFetchSeniorityProfessionWise from '@/components/forms/selectors/hook/useFetchAllSeniorityProfessionWise';
import useFetchFilterData from '@/hooks/useFetchFilterMenuData';
import PageLoadingComponent from '@/components/noData/PageLoadingComponent';
import { motion } from 'framer-motion';
import ShareModal from '@/components/models/ShareModal';
import useFetchFilterCityJobDetails from '@/hooks/useFetchFilterCityJobDetails';
import NewCardComponent from '@/components/home/newBanner/NewCardComponent';

export default function FilterCityMain() {
  const location = useLocation();
  const { cityName } = useParams();

  const navigate = useNavigate();
  const { isMobile, screenSize } = useResponsive();
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [jobUrl, setJobUrl] = useState<string>('');
  const { allProfessionsData, professionDataLoading } =
    useFetchAllProfessions();
  const { allStatesDetails, allStateLoading } =
    useFetchAllStateDetailsByCountry();
  const [divisionId, setDivisionId] = useState<number>();
  const [professionId, setProfessionId] = useState<number>();
  const [specialtyId, setSpecialtyId] = useState<number>();
  const [seniorityId, setSeniorityId] = useState<number>();
  const [stateId, setStateId] = useState<number>();
  const [regionId, setRegionId] = useState<number>();
  const [suburbId, setSuburbId] = useState<number>();
  const { filterMenuData, loading } = useFetchFilterData();
  const { filterData, FilterDataLoading, FilterDataError } =
    useFetchFilterCityJobDetails({
      divisionId,
      professionId,
      stateId,
      specialtyId,
      seniorityId,
      regionId,
      suburbId,
    });

  const [visibleJobs, setVisibleJobs] = useState<number>(6);
  const [selectedTopic, setSelectedTopic] = useState('Permanent');
  const { specialtiesProfessionWise, specialtyLoading } =
    useFetchSpecialtyProfessionWise(professionId);
  const { senioritiesProfessionWise, seniorityLoading } =
    useFetchSeniorityProfessionWise(professionId);
  const [description, setDescription] = useState('');
  const [mainTitle, setMainTitle] = useState('');

  const handleShareClick = (url: string) => {
    setJobUrl(url);
    setIsShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setIsShareModalOpen(false);
  };

  useEffect(() => {
    if (cityName) {
      const formattedCityName = formatURLNameToNormal(cityName);

      const state = allStatesDetails?.find((state) =>
        state.regions?.some((region) =>
          region.suburb?.some((sub) => sub.name === formattedCityName)
        )
      );

      if (state) {
        const region = state.regions.find((region) =>
          region.suburb?.some((sub) => sub.name === formattedCityName)
        );

        if (region) {
          const suburb = region.suburb.find(
            (sub) => sub.name === formattedCityName
          );

          if (suburb) {
            setStateId(state.state_id);
            setRegionId(region.regions_id);
            setSuburbId(suburb.suberbs_id);
            setMainTitle(suburb.lp_title);
            setDescription(suburb.description);
          }
        }
      }
    }
  }, [cityName, allStatesDetails]);

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
    // updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  };

  const handleProfessionChange = (professionId) => {
    setProfessionId(professionId);
    // updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  };

  const handleStateChange = (stateId) => {
    setStateId(stateId);
    const stateName = allStatesDetails?.find(
      (state) => state.state_id === stateId
    )?.name;

    if (stateName) {
      const formattedStateName = formatNameForURL(stateName);
      navigate(`/permanent-jobs/${formattedStateName}`);
    }
  };

  const handleSpecialtyChange = (specialtyId) => {
    setSpecialtyId(specialtyId);
    // updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  };

  const handleSeniorityChange = (seniorityId) => {
    setSeniorityId(seniorityId);
    // updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  };

  const handleSuburbChange = (suburbId) => {
    setSuburbId(suburbId);
    const suburb = allStatesDetails
      ?.flatMap((state) => state.regions.flatMap((region) => region.suburb))
      ?.find((sub) => sub.suberbs_id === suburbId);

    if (suburb) {
      const formattedCityName = formatNameForURL(suburb.name);
      navigate(`/city/all-jobs/${formattedCityName}`);
    }
  };

  const handleDivisionChange = (divisionId) => {
    setDivisionId(divisionId);
    // updateUrl(divisionId, professionId, selectedTopic);
  };
  const handleRegionChange = (regionId) => {
    setRegionId(regionId);

    const region = allStatesDetails
      ?.flatMap((state) => state.regions)
      .find((reg) => reg.regions_id === regionId);

    if (region) {
      const formattedRegionName = formatNameForURL(region.name);
      navigate(`/region/permanent-jobs/${formattedRegionName}`);
    }
  };

  // useEffect(() => {
  //   if (professionId !== undefined) {
  //     updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  //   }
  // }, [professionId]);

  // useEffect(() => {
  //   if (specialtyId !== undefined) {
  //     updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  //     localStorage.setItem('stateChangeProId', professionId);
  //   }
  // }, [specialtyId]);

  // useEffect(() => {
  //   if (seniorityId !== undefined) {
  //     updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  //   }
  // }, [seniorityId]);

  const professionName = allProfessionsData?.find(
    (profession) => profession.profession_id === professionId
  )?.name;

  const specialtyName = specialtiesProfessionWise?.find(
    (specialty) => specialty.specialities_id === specialtyId
  )?.name;

  const seniorityName = senioritiesProfessionWise?.find(
    (seniority) => seniority.seniorities_id === seniorityId
  )?.name;

  const updateUrl = (
    professionId,
    stateId,
    specialtyId,
    seniorityId,
    topic
  ) => {
    const stateName = allStatesDetails?.find(
      (s) => s.state_id === stateId
    )?.name;

    let formattedName;
    let path = `/${selectedTopic.toLowerCase()}-jobs`;

    if (stateName) {
      path += `/${formatNameForURL(stateName)}`;
    }
    if (professionName) {
      formattedName = formatNameForURL(professionName);
    }
    if (specialtyName) {
      formattedName = formatNameForURL(specialtyName);
    }
    if (seniorityName) {
      formattedName = formatNameForURL(seniorityName);
    }

    if (formattedName) {
      path += `-${formattedName}-jobs`;
    }

    navigate(path);
  };

  const filteredData = filterData?.filter((job) => {
    if (selectedTopic === 'Permanent') {
      return job.is_permanent === '1';
    } else {
      return job.is_permanent !== '1';
    }
  });

  if (allStateLoading || loading || professionDataLoading) {
    return <PageLoadingComponent />;
  }

  if (FilterDataError) {
    return <div>Error: {FilterDataError.message}</div>;
  }

  const handleLoadMore = () => {
    setVisibleJobs((prevCount) => prevCount + 3);
  };

  const displayedJobs = filteredData?.slice(0, visibleJobs) || [];

  const jobCardVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 50, damping: 20, duration: 0.5 },
    },
  };

  return (
    <>
      {isMobile ? (
        <>
          <CommonMobileHero
            MainTitle={'Elevate your medical career to unprecedented levels.'}
            SubTitle={'All Jobs'}
            Paragraph={
              'Navigate your way to a fulfilling medical career with Medfuture. Our specialized recruitment approach connects you with opportunities in your preferred location across Australia. Embrace a career path that aligns with your aspirations, supported by our commitment to excellence in medical recruitment.'
            }
          />
          <MobileLayout>
            <MobileJobSection />
            {/* <div className="flex flex-col gap-y-6">
                {data.slice(0, visibleJobs).map((job, index) => (
                  <div key={index}>
                    {!screenSize.md ? (
                      <>
                        <MainMobileJobCard data={job} />
                      </>
                    ) : (
                      <>
                        <div className="grid grid-cols-2 gap-5">
                          <MainMobileJobCard data={job} />
                          {index < data.length - 1 && (
                            <MainMobileJobCard data={data[index + 1]} />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div> */}
            {/* <div className="text-center mt-10 mb-10">
                {data.length > visibleJobs && (
                  <button
                    onClick={handleLoadMore}
                    className="text-blue-390 text-center text-[20px] leading-[23px]"
                  >
                    Load More...
                  </button>
                )}
              </div> */}
          </MobileLayout>
        </>
      ) : (
        <>
          <HeroBackground
            title={mainTitle}
            description={`All Jobs in ${formatURLNameToNormal(cityName)}`}
            icon={''}
          />
          <PageLayout>
            <div className="px-28">
              <div className="text-center font-sans font-normal text-[33px] lg:text-[24px] leading-[48px] lg:leading-[36px] text-blue-350 padding-container">
                {description}
              </div>
            </div>
            <div className="flex items-start justify-between gap-x-5 padding-container">
              <>
                <div className="">
                  <div className="">
                    <div className="w-[370px] lg:w-[480px] flex items-center shadow-md font-sans text-[20px] leading-[23px] lg:text-[26px] lg:leading-[32px] font-normal">
                      <h3
                        className={`w-1/2 text-center py-[14px] lg:py-5 ${
                          selectedTopic === 'Permanent'
                            ? 'bg-white'
                            : 'bg-gray-420'
                        } cursor-pointer`}
                        onClick={() => handleTopicClick('Permanent')}
                      >
                        Permanent
                      </h3>
                      <h3
                        className={`w-1/2 text-center py-[14px] lg:py-5 ${
                          selectedTopic === 'Locum' ? 'bg-white' : 'bg-gray-420'
                        } cursor-pointer`}
                        onClick={() => handleTopicClick('Locum')}
                      >
                        Locum
                      </h3>
                    </div>
                    <div className="flex items-center justify-start">
                      {selectedTopic === 'Permanent' ? (
                        <PermanentJobFilter
                          data={filterMenuData}
                          allStates={allStatesDetails}
                          divisionId={divisionId}
                          professionId={professionId}
                          specialtyId={specialtyId}
                          seniorityId={seniorityId}
                          stateId={stateId}
                          regionId={regionId}
                          suburbId={suburbId}
                          onDivisionChange={handleDivisionChange}
                          onProfessionChange={handleProfessionChange}
                          onSpecialtyChange={handleSpecialtyChange}
                          onSeniorityChange={handleSeniorityChange}
                          onStateChange={handleStateChange}
                          onRegionChange={handleRegionChange}
                          onSuburbSelect={handleSuburbChange}
                        />
                      ) : (
                        <LocumJobFilter
                          data={filterMenuData}
                          allStates={allStatesDetails}
                          divisionId={divisionId}
                          professionId={professionId}
                          specialtyId={specialtyId}
                          seniorityId={seniorityId}
                          stateId={stateId}
                          regionId={regionId}
                          suburbId={suburbId}
                          onDivisionChange={handleDivisionChange}
                          onProfessionChange={handleProfessionChange}
                          onSpecialtyChange={handleSpecialtyChange}
                          onSeniorityChange={handleSeniorityChange}
                          onStateChange={handleStateChange}
                          onRegionChange={handleRegionChange}
                          onSuburbSelect={handleSuburbChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
              <div>
                {FilterDataLoading ? (
                  <div className="flex items-center justify-center">
                    <LoadingComponent />
                  </div>
                ) : (
                  <div className="flex items-start justify-between gap-x-5">
                    <div>
                      <div className="">
                        {filteredData?.length === 0 ? (
                          <div className="">
                            <NoJobsCard selectedTopic={selectedTopic} />
                          </div>
                        ) : (
                          <div className="flex flex-col gap-y-6">
                            {displayedJobs?.map((job, index) => (
                              <motion.div
                                key={index}
                                variants={jobCardVariants}
                                initial="hidden"
                                animate="visible"
                              >
                                <MainJobCard
                                  data={job}
                                  onShareClick={handleShareClick}
                                />
                              </motion.div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="text-center mt-10">
                        {filteredData?.length > visibleJobs && (
                          <button
                            onClick={handleLoadMore}
                            className="text-blue-390 text-center text-[20px] leading-[23px]"
                          >
                            Load More...
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <Register /> */}
            {/* <DropDownSection /> */}
          </PageLayout>
          <PageLayout>
            <GratitudeComponent mainTitle="Medfuture Global Doctor Community" />
            <NewCardComponent />
          </PageLayout>
          {/* <PageLayout>
            <InsightComponent />
          </PageLayout> */}
        </>
      )}
      <div className={`modal ${isShareModalOpen ? 'modal-open' : ''}`}>
        <ShareModal
          isOpen={isShareModalOpen}
          onClose={handleCloseShareModal}
          jobUrl={jobUrl}
        />
      </div>
    </>
  );
}
