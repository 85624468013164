import { useEffect, useRef, useState } from 'react';
import { ProfessionSelector } from '@/components/forms/selectors/ProfessionSelector';
import { StateSelector } from '@/components/forms/selectors/StateSelector';
import Button from '@/components/shared/button/Button';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { verifyEmailFormat } from '@/utils/EmailVerification';
import { isPhoneValid } from '@/utils/PhoneNumberVerification';
import { Link, useNavigate } from 'react-router-dom';
import { validatePassword } from '@/utils/PasswordValidationUtil';
import PopupNotification from '@/components/shared/notification/PopupNotification';
import CandidateRegistrationApi from '@/api/Registration/CandidateRegistrationApi';
import SocialMediaButton from '@/components/shared/button/SocialMediaBtn';
import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import { CountrySelector } from '../selectors/CountrySelector';
import { SocialMediaSelector } from '../selectors/SocialMediaSelector';
import pdfIcon from '/icons/pdfIcon.png';
import wordIcon from '/icons/wordIcon.png';
import { confirmPassword } from '@/utils/confirmPasswordUtil';
import OpenEye from '/icons/OpenEye.png';
import CloseEye from '/icons/CloseEye.png';
import { getBaseUrl } from '@/config/BaseUrl';
import { SpecialtySelector } from '../selectors/SpecialtySelector';
import SubmitButton from '@/components/shared/button/SubmitBtn';

export default function RegisterForm() {
  const navigate = useNavigate();
  const [selectedTopic, setSelectedTopic] = useState('Candidate');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    country: '',
    phoneNumber: '',
    state: '',
    profession: '',
    specialty: '',
    social: '',
    cv: '',
    email: '',
    password: '',
    confirmPassword: '',
    agree: false,
    subscribe: false,
  });
  const [submit, setSubmit] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    country: '',
    phoneNumber: '',
    state: '',
    profession: '',
    specialty: '',
    social: '',
    cv: '',
    email: '',
    fileUpload: '',
    password: '',
    confirmPassword: '',
    agree: false,
  });
  const [file, setFile] = useState<File | null>(null);
  const [notification, setNotification] = useState({
    show: false,
    title: '',
    message: '',
    type: 'success' as 'success' | 'error',
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const countryRef = useRef<HTMLSelectElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLSelectElement>(null);
  const professionRef = useRef<HTMLSelectElement>(null);
  const specialtyRef = useRef<HTMLSelectElement>(null);
  const socialRef = useRef<HTMLSelectElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const agreeRef = useRef<HTMLInputElement>(null);

  const allErrors =
    formErrors.firstName ||
    formErrors.lastName ||
    formErrors.country ||
    formErrors.phoneNumber ||
    formErrors.state ||
    formErrors.profession ||
    formErrors.email ||
    formErrors.social ||
    formErrors.password ||
    formErrors.confirmPassword ||
    formErrors.agree;

  const handleChange = (e: {
    target: { id: string; type: string; checked: boolean; value: string };
  }) => {
    const { id, type, checked, value } = e.target;
    setFormData({
      ...formData,
      [id]: type === 'checkbox' ? checked : value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const useUploadFile = e.target.files[0];
      const allowedFileTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];

      if (!allowedFileTypes.includes(useUploadFile.type)) {
        setFormErrors({
          ...formErrors,
          fileUpload: 'Please upload your CV in PDF or Word format',
        });
        setFile(null);
      } else {
        setFormErrors({
          ...formErrors,
          fileUpload: '',
        });
        setFile(e.target.files[0]);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const getIconForFileType = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (extension === 'pdf') {
      return (
        <img src={pdfIcon} alt="PDF Icon" className="w-[30px] h-[30px] mr-2" />
      );
    } else if (['txt', 'doc', 'docx'].includes(extension || '')) {
      return (
        <img
          src={wordIcon}
          alt="Text Icon"
          className="w-[30px] h-[30px] mr-2"
        />
      );
    } else {
      return null;
    }
  };

  const validateForm = () => {
    const newErrors = {
      firstName: !formData.firstName ? 'Please enter your first name' : '',
      lastName: !formData.lastName ? 'Please enter your last name' : '',
      email: !formData.email
        ? 'Please enter your email address'
        : !verifyEmailFormat(formData.email)
          ? 'Please enter a valid email address'
          : '',
      profession: !formData.profession ? 'Please select profession' : '',
      specialty: !formData.specialty ? 'Please select specialty' : '',
      state: !formData.state ? 'Please select state' : '',
      country: !formData.country ? 'Please select a Country' : '',
      phoneNumber: !formData.phoneNumber
        ? 'Please enter your Phone Number'
        : !isPhoneValid(formData.phoneNumber)
          ? 'Please enter a valid Phone Number'
          : '',
      social: !formData.social ? 'Please give your response' : '',
      agree: !formData.agree
        ? 'You must agree to the Terms and Conditions and Privacy Policy'
        : '',
      password: validatePassword(formData.password),
      confirmPassword: confirmPassword(
        formData.password,
        formData.confirmPassword
      ),
    };
    setFormErrors(newErrors);
    return newErrors;
  };

  useEffect(() => {
    if (click) {
      validateForm();
    }
  }, [click, formData]);

  const baseUrl = getBaseUrl();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setClick(true);
    const errors = validateForm();
    if (Object.values(errors).some((error) => error)) {
      const firstError = Object.keys(errors).find(
        (key) => errors[key as keyof typeof errors]
      );
      if (firstError) {
        const ref = {
          firstName: firstNameRef,
          lastName: lastNameRef,
          country: countryRef,
          phoneNumber: phoneNumberRef,
          state: stateRef,
          profession: professionRef,
          specialty: specialtyRef,
          social: socialRef,
          password: passwordRef,
          confirmPassword: confirmPasswordRef,
          agree: agreeRef,
        }[firstError];
        ref?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
      setIsSubmitting(false);
      return;
    }

    try {
      setIsSubmitting(true);
      if (file) {
        const randomNumber = Math.floor(Math.random() * 1000000);
        const fileData = new FormData();
        fileData.append('image', file);
        fileData.append('folder_path', 'desktop/candidate_resume');
        fileData.append('file_name', `${formData.firstName}_${randomNumber}`);
        const fileUploadResponse = await fetch(`${baseUrl}upload`, {
          method: 'POST',
          body: fileData,
        });
        const fileUploadResult = await fileUploadResponse.json();

        if (!fileUploadResult) {
          setNotification({
            show: true,
            title: 'Registration Failed',
            message: 'File upload failed',
            type: 'error',
          });
          setSubmit(false);
          return;
        } else {
          const apiResponse = await CandidateRegistrationApi(
            formData.firstName,
            formData.lastName,
            formData.email,
            formData.phoneNumber,
            formData.state,
            formData.country,
            formData.profession,
            formData.specialty,
            formData.social,
            fileUploadResult.fileName,
            formData.password
          );
          if (apiResponse && apiResponse.data.candidate_profiles_id) {
            setSubmit(true);
            setClick(false);
            setFormData({
              firstName: '',
              lastName: '',
              country: '',
              phoneNumber: '',
              state: '',
              profession: '',
              specialty: '',
              social: '',
              cv: '',
              email: '',
              password: '',
              confirmPassword: '',
              agree: false,
              subscribe: false,
            });
            setFile(null);
          }
        }
      } else {
        const apiResponse = await CandidateRegistrationApi(
          formData.firstName,
          formData.lastName,
          formData.email,
          formData.phoneNumber,
          formData.state,
          formData.country,
          formData.profession,
          formData.specialty,
          formData.social,
          file,
          formData.password
        );
        if (apiResponse && apiResponse.data.candidate_profiles_id) {
          setSubmit(true);
          setClick(false);
          setFormData({
            firstName: '',
            lastName: '',
            country: '',
            phoneNumber: '',
            state: '',
            profession: '',
            specialty: '',
            social: '',
            cv: '',
            email: '',
            password: '',
            confirmPassword: '',
            agree: false,
            subscribe: false,
          });
          setFile(null);
        } else if (apiResponse.errors) {
          const errorMessages = apiResponse.errors[0];
          setFormErrors({ ...formErrors, email: errorMessages });
          emailRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });

          setSubmit(false);
        } else {
          throw new Error(apiResponse.message || 'Unknown error occurred');
        }
      }
    } catch (error) {
      if (error.message.includes('The email has already been taken.')) {
        setFormErrors({
          ...formErrors,
          email: 'The email has already been taken.',
        });
        emailRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const NavigateToClientRegistration = () => {
    navigate('/employer-register');
  };

  const handleTopicClick = (selectedTopic) => {
    if (selectedTopic === 'Client') {
      NavigateToClientRegistration();
    } else {
      setSelectedTopic(selectedTopic);
    }
  };
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="lg:w-[900px] xl:w-[1054px] flex items-center shadow-md font-sans text-[20px] leading-[23px] lg:text-[26px] lg:leading-[32px] font-normal">
          <h2
            className={`w-1/2 text-center py-[14px] lg:py-5 ${
              selectedTopic === 'Candidate'
                ? 'bg-gradient-to-r from-blue-500 to-blue-150 rounded-tl-xl text-white'
                : 'bg-white'
            } cursor-pointer`}
            onClick={() => handleTopicClick('Candidate')}
          >
            Candidate
          </h2>
          <h2
            className={`w-1/2 text-center py-[14px] lg:py-5 ${
              selectedTopic === 'Client' ? 'bg-white' : 'bg-white rounded-tr-xl'
            } cursor-pointer`}
            onClick={() => handleTopicClick('Client')}
          >
            Employer
          </h2>
        </div>
      </div>
      <div className="relative flex items-center justify-center">
        <form
          id="register-form"
          onSubmit={handleSubmit}
          className="lg:p-[40px] xl:p-[70px] lg:w-[900px] xl:w-[1054px] h-[1150px] shadow-all-sides-except-top bg-white rounded-b-xl"
          noValidate
        >
          <div className="flex items-center justify-between mb-10">
            <div className="flex items-center gap-x-2">
              <div>
                <SocialMediaButton
                  type="button"
                  title="Register With Google"
                  variant="goggle-signIn-btn"
                  iconSrc={`${AWS_BASE_ASSETS_ICONS}google.png`}
                />
              </div>
              <div>
                <SocialMediaButton
                  type="button"
                  title=""
                  variant="other-signin-btn"
                  iconSrc={`${AWS_BASE_ASSETS_ICONS}Facebook.png`}
                />
              </div>
              <div>
                <SocialMediaButton
                  type="button"
                  title=""
                  variant="other-signin-btn"
                  iconSrc={`${AWS_BASE_ASSETS_ICONS}linkedin.png`}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type="text"
                id="firstName"
                ref={firstNameRef}
                value={formData.firstName}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.firstName ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="First Name"
              />
              {formErrors.firstName && (
                <div className="form-error-msg">{formErrors.firstName}</div>
              )}
            </div>
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type="text"
                id="lastName"
                ref={lastNameRef}
                value={formData.lastName}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.lastName ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="Last Name"
              />
              {formErrors.lastName && (
                <div className="form-error-msg">{formErrors.lastName}</div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between gap-6 mb-10">
            <div
              className="relative lg:min-w-[400px] xl:min-w-[442px]"
              ref={professionRef}
            >
              <ProfessionSelector
                onChange={(value) =>
                  setFormData({ ...formData, profession: value })
                }
                error={formErrors.profession}
                // professionRef={professionRef}
                resetSelector={submit}
              />
              {formErrors.profession && (
                <div className="form-error-msg">{formErrors.profession}</div>
              )}
            </div>
            <div className="relative lg:min-w-[400px] xl:min-w-[442px] z-[3]">
              <SpecialtySelector
                onChange={(value) =>
                  setFormData({ ...formData, specialty: value })
                }
                error={formErrors.specialty}
                professionId={parseInt(formData.profession)}
                specialtyRef={specialtyRef}
                resetSelector={submit}
              />
              {formErrors.specialty && (
                <div className="form-error-msg">{formErrors.specialty}</div>
              )}
            </div>
          </div>
          <div className="border-t-2 border-dashed h-[40px]"></div>
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <CountrySelector
                onChange={(value) =>
                  setFormData({ ...formData, country: value })
                }
                error={formErrors.country}
                resetSelector={submit}
              />
              {formErrors.country && (
                <div className="form-error-msg">{formErrors.country}</div>
              )}
            </div>
            <div className="relative lg:min-w-[400px] xl:min-w-[442px] z-[2]">
              <StateSelector
                onChange={(value) => setFormData({ ...formData, state: value })}
                error={formErrors.state}
                resetSelector={submit}
              />
              {formErrors.state && (
                <div className="form-error-msg">{formErrors.state}</div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type="email"
                id="email"
                ref={emailRef}
                value={formData.email}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.email ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="Email"
              />
              {formErrors.email && (
                <div className="form-error-msg">{formErrors.email}</div>
              )}
            </div>
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <PhoneInput
                forceDialCode={true}
                defaultCountry="nz"
                countrySelectorStyleProps={{
                  buttonStyle: {
                    zIndex: '1',
                    position: 'absolute',
                    left: '0px',
                    height: '36px',
                    width: '56px',
                    border: 'none',
                    backgroundColor: '#FFFFFF',
                  },
                }}
                onChange={(phone) =>
                  setFormData({ ...formData, phoneNumber: phone })
                }
                inputProps={{
                  id: 'phoneNumber',
                  autoComplete: 'off',
                  className:
                    'w-full relative pl-16 focus:outline-none input-placeholder',
                }}
                className={`form-container input-placeholder ${formErrors.phoneNumber ? 'outline-red-300' : 'outline-blue-250'}`}
                value={formData.phoneNumber}
                ref={phoneNumberRef}
              />
              {formErrors.phoneNumber && (
                <div className="text-red-100 absolute text-[11px] lg:text-sm mt-[10px]">
                  {formErrors.phoneNumber}
                </div>
              )}
            </div>
          </div>
          <div className="border-t-2 border-dashed h-[40px]"></div>
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type={passwordVisible ? 'text' : 'password'}
                id="password"
                ref={passwordRef}
                value={formData.password}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.password ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="Password"
              />
              <div
                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? (
                  <img src={OpenEye} className="w-[23px] h-[23px]" />
                ) : (
                  <img src={CloseEye} className="w-[23px] h-[23px]" />
                )}
              </div>
              {formErrors.password && (
                <div className="form-error-msg">{formErrors.password}</div>
              )}
            </div>
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type="password"
                id="confirmPassword"
                ref={confirmPasswordRef}
                value={formData.confirmPassword}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.confirmPassword ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="Confirm Password"
              />
              {formErrors.confirmPassword && (
                <div className="form-error-msg">
                  {formErrors.confirmPassword}
                </div>
              )}
            </div>
          </div>
          <div className="border-t-2 border-dashed h-[40px]"></div>
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <SocialMediaSelector
                onChange={(value) =>
                  setFormData({ ...formData, social: value })
                }
                error={formErrors.social}
                resetSelector={submit}
              />
              {formErrors.social && (
                <div className="form-error-msg">{formErrors.social}</div>
              )}
            </div>
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <div
                className={`form-container ${formErrors.fileUpload ? 'outline-red-300' : 'outline-blue-250'}`}
                onChange={handleFileChange}
              >
                <label htmlFor="fileUpload" className=" w-full cursor-pointer">
                  <div className=" ">
                    {file ? (
                      <div className="flex items-center text-nowrap justify-center">
                        <div>{getIconForFileType(file.name)}</div>
                        <h1 className="form-text">{file.name}</h1>
                      </div>
                    ) : (
                      <p className="form-text mt-[6px]">
                        <div className="flex items-center justify-between">
                          <span className="">Upload Your CV</span>
                          <img
                            src={`${AWS_BASE_ASSETS_ICONS}CloudUploadFill.png`}
                            width={23}
                            height={23}
                          />
                        </div>
                      </p>
                    )}
                  </div>
                  <input
                    id="fileUpload"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </label>
              </div>
              {formErrors.fileUpload && (
                <div className="form-error-msg">{formErrors.fileUpload}</div>
              )}
            </div>
          </div>
          <div className="mb-8 mt-10">
            <div className="mb-5 mt-10">
              <div className="relative">
                <input
                  type="checkbox"
                  id="agree"
                  ref={agreeRef}
                  checked={formData.agree}
                  onChange={handleChange}
                />
                <label
                  htmlFor="agree"
                  className={`relative text-sm text-gray-900 ${
                    formErrors.agree
                      ? 'after:text-red-100'
                      : 'after:text-gray-900'
                  } after:content-['*'] after:ml-1  absolute top-0 left-3`}
                >
                  I Agree to the{' '}
                  <Link to={'/terms-and-conditions'} className="text-blue-220">
                    Term and Condition
                  </Link>{' '}
                  and{' '}
                  <Link to={'/privacy-policy'} className="text-blue-220">
                    Privacy policy
                  </Link>
                </label>
                {formErrors.agree && (
                  <div className="absolute text-red-100 text-sm ml-5">
                    {formErrors.agree}
                  </div>
                )}
              </div>
            </div>
            <div>
              <input
                type="checkbox"
                id="subscribe"
                checked={formData.subscribe}
                onChange={handleChange}
                className=""
                required
              />
              <label htmlFor="subscribe" className="ml-2 text-sm text-gray-900">
                Subscribe for Job Alert
              </label>
            </div>
          </div>
          <div className="absolute bottom-[130px] right-[70px] flex flex-col items-center">
            <div className="w-[295px]">
              <SubmitButton
                type="submit"
                title="Register"
                variant="find-jobs-btn-candidate"
                isSubmitting={isSubmitting}
                disableVariant="find-jobs-btn-candidate-disabled"
                submitTitle="Registration in Progress"
              />
            </div>
            <p className="font-sans text-sm font-normal leading-[55px] text-gray-520">
              If You Have Account Please{' '}
              <Link to={'/sign-in'} className="text-blue-400">
                Login
              </Link>
            </p>
          </div>
          <div className="absolute bottom-[80px] right-[70px] left-[70px]">
            {allErrors && (
              <div className="w-full border p-3 rounded-[4px] text-center text-sm font-normal font-sans text-red-200 border-red-400 bg-red-500 bg-opacity-40">
                Please Enter Required Field above
              </div>
            )}
          </div>
        </form>
        {submit && (
          <PopupNotification
            title={'Registered Successfully'}
            message={
              'Thank you for registering with us. Please verify yourself by clicking on the verification link in your email.'
            }
            onClose={() => setSubmit(false)}
            type={'success'}
          />
        )}
        {notification.show && (
          <PopupNotification
            title={notification.title}
            message={notification.message}
            onClose={() => setNotification({ ...notification, show: false })}
            type={notification.type}
          />
        )}
      </div>
    </div>
  );
}
