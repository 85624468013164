import { getBaseUrl } from '@/config/BaseUrl';

export default async function CommonReachUsApi(
  first_name: string,
  last_name: string,
  profession: string,
  state: string,
  speciality: string,
  seniority: string,
  email: string,
  message: string,
  mobile: string,
  page_type: string,
  professional_status?: string,
  file_name?: string,
  country?: string,
  registration_type?: string
) {
  try {
    const requestBody = {
      first_name: first_name,
      last_name: last_name,
      profession: profession,
      state: state,
      speciality: speciality,
      seniority: seniority,
      email: email,
      message: message,
      mobile: mobile,
      page_type: page_type,
      professional_status: professional_status,
      file_name: file_name,
      country: country,
      registration_type: registration_type,
    };
    const baseUrl = getBaseUrl();
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(
      `${baseUrl}web/reach_us_register/save`,
      requestOptions
    );

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message);
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error('Error submitting information:', error);
    throw error;
  }
}
