import CountUp from 'react-countup';
import {
  CANDIDATE_COUNT,
  CANDIDATE_COUNT_TIME,
  CLIENT_COUNT,
  CLIENT_COUNT_TIME,
  JOBS_COUNT,
  JOBS_COUNT_TIME,
  YEAR_COUNT,
  YEAR_COUNT_TIME,
} from '@/constants/Constant';

export default function FetchHeroStatus() {
  return (
    <section className="flex items-center justify-between w-full">
      <div className="flex items-center w-1/4 justify-center">
        <div>
          <h2 className="hero-stat-main">
            <CountUp
              end={YEAR_COUNT}
              duration={YEAR_COUNT_TIME}
              delay={0.8}
              separator=","
              suffix="+"
            />
          </h2>
          <h4 className="hero-stat-sub">Years</h4>
        </div>
        <div className="hero-stat-line"></div>
      </div>
      <div className="flex items-center w-1/4 justify-center">
        <div>
          <h2 className="hero-stat-main">
            <CountUp
              end={CLIENT_COUNT}
              duration={CLIENT_COUNT_TIME}
              separator=","
              delay={0.8}
            />
          </h2>
          <h4 className="hero-stat-sub">Clients</h4>
        </div>
        <div className="hero-stat-line"></div>
      </div>
      <div className="flex items-center w-1/4 justify-center">
        <div>
          <h2 className="hero-stat-main">
            <CountUp end={JOBS_COUNT} duration={JOBS_COUNT_TIME} delay={0.8} />
          </h2>
          <h4 className="hero-stat-sub">Jobs</h4>
        </div>
        <div className="hero-stat-line"></div>
      </div>
      <div className="flex items-center w-1/4 justify-center">
        <div>
          <h2 className="hero-stat-main">
            <CountUp
              end={CANDIDATE_COUNT}
              duration={CANDIDATE_COUNT_TIME}
              delay={0.8}
            />
          </h2>
          <h4 className="hero-stat-sub">Candidates</h4>
        </div>
      </div>
    </section>
  );
}
