import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';

function BenefitCard({ benefit }) {
  return (
    <div className="flex flex-col items-start">
      <img
        src={`${AWS_BASE_URL_ASSETS}/${benefit.icon}`}
        alt={benefit.title}
        className="w-16 h-16 mb-8"
      />
      <h3 className="font-sans font-bold text-gray-800 text-lg mb-4">
        {benefit.title}
      </h3>
      <p className="font-sans text-gray-600">{benefit.description}</p>
    </div>
  );
}

export default BenefitCard;
