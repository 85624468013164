import {
  CommonMobileHero,
  DropDownSection,
  GratitudeComponent,
  HeroBackground,
  InsightComponent,
  MainJobCard,
  MobileJobSection,
  Register,
} from '@/components';
import { MobileLayout, PageLayout } from '@/layout';
import React, { useEffect, useState } from 'react';
import useResponsive from '@/hooks/useResponsive';
import MainMobileJobCard from '@/components/mobile/cards/MainMobileJobCard';
import useFetchFilterJobDetails from '@/hooks/useFetchFilterJobsDetails';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useFetchAllProfessions from '@/components/forms/selectors/hook/useFetchAllProfessions';
import useFetchAllState from '@/components/forms/selectors/hook/useFetchAllStates';
import { formatURLNameToNormal } from '@/utils/formatNameUtil';
import PermanentJobFilter from '@/components/filter/PermanentJobFilter';
import LocumJobFilter from '@/components/filter/LocumJobFilter';
import SubmitFormModal from '@/components/models/SubmitFormModal';
import NoJobsCard from '@/components/noData/NoJobsCard';
import LoadingComponent from '@/components/noData/LoadComponent';
import { formatNameForURL } from '@/utils/formatNameForURL';
import useFetchAllStateDetailsByCountry from '@/hooks/useFetchAllStateDetailsByCountry';
import useFetchSpecialtyProfessionWise from '@/components/forms/selectors/hook/useFetchSpecialtyProfessionWise';
import useFetchSeniorityProfessionWise from '@/components/forms/selectors/hook/useFetchAllSeniorityProfessionWise';
import useFetchFilterData from '@/hooks/useFetchFilterMenuData';
import PageLoadingComponent from '@/components/noData/PageLoadingComponent';
import { motion } from 'framer-motion';
import ShareModal from '@/components/models/ShareModal';
import NewCardComponent from '@/components/home/newBanner/NewCardComponent';
import { getProductionUrl } from '@/config/getProductionUrl';
import useFetchAllSeoDetails from '@/hooks/useFetchAllSeoDetails';
import SeoOptimized from '@/components/reactHelmet/SeoOptimized';

export default function JobBoardFilter() {
  const CurLocation = useLocation();

  const matches = CurLocation.pathname.match(/all-(.*)-jobs-in-(.*)/);
  const division = matches ? matches[1] : '';
  const location = matches ? matches[2] : '';

  const productionUrl = getProductionUrl();
  const { seoData } = useFetchAllSeoDetails(
    `${productionUrl}${CurLocation.pathname}`
  );

  const navigate = useNavigate();
  const { isMobile, screenSize } = useResponsive();
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [jobUrl, setJobUrl] = useState<string>('');
  const { allProfessionsData, professionDataLoading } =
    useFetchAllProfessions();
  const { allStates, stateLoading } = useFetchAllState();
  const { allStatesDetails, allStateLoading } =
    useFetchAllStateDetailsByCountry();
  const [professionId, setProfessionId] = useState<number>();
  const [specialtyId, setSpecialtyId] = useState<number>();
  const [seniorityId, setSeniorityId] = useState<number>();
  const [stateId, setStateId] = useState<number>();
  const [suburbId, setSuburbId] = useState<number>();
  const [divisionId, setDivisionId] = useState<number>();
  const [regionId, setRegionId] = useState<number>();
  const { filterData, FilterDataLoading, FilterDataError } =
    useFetchFilterJobDetails({
      divisionId,
      professionId,
      stateId,
      specialtyId,
      seniorityId,
      suburbId,
    });

  const [visibleJobs, setVisibleJobs] = useState<number>(6);
  const [selectedTopic, setSelectedTopic] = useState('Permanent');
  const { filterMenuData } = useFetchFilterData();
  const { specialtiesProfessionWise, specialtyLoading } =
    useFetchSpecialtyProfessionWise(professionId);
  const { senioritiesProfessionWise, seniorityLoading } =
    useFetchSeniorityProfessionWise(professionId);
  const [description, setDescription] = useState('');
  const [mainTitle, setMainTitle] = useState('');

  useEffect(() => {
    if (division) {
      const transformedDivision = division === 'doctor' ? 'Medical' : division;
      const divisionData = filterMenuData?.find(
        (div) => div.url === formatURLNameToNormal(transformedDivision)
      );
      const divisionID = divisionData?.divisions_id;
      if (divisionData) {
        setDivisionId(divisionID);
        setDescription(divisionData.description);
        setMainTitle(divisionData.title);
      }
    }
  }, [division, filterMenuData]);

  const handleShareClick = (url: string) => {
    setJobUrl(url);
    setIsShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setIsShareModalOpen(false);
  };

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
  };

  const handleProfessionChange = (professionId) => {
    setProfessionId(professionId);
    // updateUrl(professionId, stateId, selectedTopic);
  };

  const handleStateChange = (stateId) => {
    setStateId(stateId);
    // updateUrl(professionId, stateId, selectedTopic);
  };

  const handleSpecialtyChange = (specialtyId) => {
    setSpecialtyId(specialtyId);
    // updateUrl(specialtyId, stateId, selectedTopic);
  };

  const handleSeniorityChange = (seniorityId) => {
    setSeniorityId(seniorityId);
    // updateUrl(seniorityId, stateId, selectedTopic);
  };

  const handleSuburbChange = (suburbId) => {
    setSuburbId(suburbId);
  };

  const handleRegionChange = (regionId) => {
    setRegionId(regionId);
  };

  const handleDivisionChange = (divisionId) => {
    setDivisionId(divisionId);
    // updateUrl(divisionId, professionId, selectedTopic);
  };

  // useEffect(() => {
  //   if (specialtyId !== undefined) {
  //     updateUrl(specialtyId, stateId, selectedTopic);
  //   }
  // }, [specialtyId, stateId]);

  // useEffect(() => {
  //   if (seniorityId !== undefined) {
  //     updateUrl(seniorityId, stateId, selectedTopic);
  //   }
  // }, [seniorityId, stateId]);

  // useEffect(() => {
  //   if (divisionId !== undefined) {
  //     updateUrl(divisionId, stateId, selectedTopic);
  //   }
  // }, [divisionId, stateId]);

  const specialtyName = specialtiesProfessionWise?.find(
    (specialty) => specialty.specialities_id === specialtyId
  )?.name;

  const seniorityName = senioritiesProfessionWise?.find(
    (seniority) => seniority.seniorities_id === seniorityId
  )?.name;

  const divisionName = filterMenuData?.find(
    (division) => division.divisions_id === divisionId
  )?.name;

  const updateUrl = (professionId, stateId, topic) => {
    const professionName = allProfessionsData?.find(
      (p) => p.profession_id === professionId
    )?.name;
    const stateName = allStates?.find((s) => s.state_id === stateId)?.name;
    let path = `/all-`;
    let formattedName;
    if (divisionName) {
      formattedName = formatNameForURL(divisionName);
    }
    // if (professionName) {
    //   path += `-${formatNameForURL(professionName)}`;
    // } else if (specialtyName) {
    //   path += `-${formatNameForURL(specialtyName)}`;
    // }
    // if (seniorityName) {
    //   path += `-${formatNameForURL(seniorityName)}`;
    // }
    if (formattedName) {
      path += `${formattedName}`;
    }
    if (stateName) {
      path += `-jobs-in-${formatNameForURL(stateName)}`;
    }
    navigate(path);
  };

  // useEffect(() => {
  //   if (division) {
  //     const divisionData = filterMenuData?.find(
  //       (div) => div.url === formatURLNameToNormal(division)
  //     );
  //     const divisionID = divisionData?.divisions_id;
  //     if (divisionData) {
  //       setDivisionId(divisionID);
  //     }
  //   }
  // }, [division, filterMenuData]);

  // useEffect(() => {
  //   if (location) {
  //     const stateData = allStates?.find(
  //       (st) => st.name === formatURLNameToNormal(location)
  //     );
  //     const stateID = stateData?.state_id;
  //     if (stateData) {
  //       setStateId(stateID);
  //     }
  //   }
  // }, [location, allStates]);

  const filteredData = filterData?.filter((job) => {
    if (selectedTopic === 'Permanent') {
      return job.is_permanent === '1';
    } else {
      return job.is_permanent !== '1';
    }
  });

  if (professionDataLoading || allStateLoading) {
    return <PageLoadingComponent />;
  }

  if (FilterDataError) {
    return <div>Error: {FilterDataError.message}</div>;
  }

  const handleLoadMore = () => {
    setVisibleJobs((prevCount) => prevCount + 3);
  };

  const displayedJobs = filteredData?.slice(0, visibleJobs) || [];

  const jobCardVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 50, damping: 20, duration: 0.5 },
    },
  };

  return (
    <>
      {seoData && (
        <SeoOptimized
          title={seoData?.title}
          description={seoData?.description}
          robots={seoData?.robots}
          twitterImage={seoData?.twitterImage || seoData?.image}
          ogTitle={seoData?.og_title}
          ogDescription={seoData?.og_description}
          ogImage={seoData?.og_image}
          ogUrl={seoData?.og_URL}
          ogSiteName={seoData?.og_site_name}
          ogLocale={seoData?.og_locale}
          ogType={seoData?.og_type}
          canonicalUrl={seoData?.canonical}
        />
      )}
      {isMobile ? (
        <>
          <CommonMobileHero
            MainTitle={'Elevate your medical career to unprecedented levels.'}
            SubTitle={'All Jobs'}
            Paragraph={
              'Navigate your way to a fulfilling medical career with Medfuture. Our specialized recruitment approach connects you with opportunities in your preferred location across Australia. Embrace a career path that aligns with your aspirations, supported by our commitment to excellence in medical recruitment.'
            }
          />
          <MobileLayout>
            <MobileJobSection />
            {/* <div className="flex flex-col gap-y-6">
                {data.slice(0, visibleJobs).map((job, index) => (
                  <div key={index}>
                    {!screenSize.md ? (
                      <>
                        <MainMobileJobCard data={job} />
                      </>
                    ) : (
                      <>
                        <div className="grid grid-cols-2 gap-5">
                          <MainMobileJobCard data={job} />
                          {index < data.length - 1 && (
                            <MainMobileJobCard data={data[index + 1]} />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div> */}
            {/* <div className="text-center mt-10 mb-10">
                {data.length > visibleJobs && (
                  <button
                    onClick={handleLoadMore}
                    className="text-blue-390 text-center text-[20px] leading-[23px]"
                  >
                    Load More...
                  </button>
                )}
              </div> */}
          </MobileLayout>
        </>
      ) : (
        <>
          <HeroBackground
            title={mainTitle}
            description={`All ${formatURLNameToNormal(division)} Jobs in ${formatURLNameToNormal(location)}`}
            icon={''}
          />
          <PageLayout>
            <div className="px-28">
              <div className="text-center font-sans font-normal text-[33px] lg:text-[24px] leading-[48px] lg:leading-[36px] text-blue-350 padding-container">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.5 }}
                  className="text-center font-sans font-normal text-[33px] lg:text-[24px] leading-[48px] lg:leading-[36px] text-blue-350 padding-container"
                >
                  {description?.split('').map((char, index) => (
                    <motion.span
                      key={index}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3 + index * 0.01 }}
                    >
                      {char}
                    </motion.span>
                  ))}
                </motion.div>
              </div>
            </div>
            <div className="flex items-start justify-between gap-x-5 padding-container">
              <>
                <div className="">
                  <div className="">
                    <div className="w-[370px] lg:w-[480px] flex items-center shadow-md font-sans text-[20px] leading-[23px] lg:text-[26px] lg:leading-[32px] font-normal">
                      <h3
                        className={`w-1/2 text-center py-[14px] lg:py-5 ${
                          selectedTopic === 'Permanent'
                            ? 'bg-white'
                            : 'bg-gray-420'
                        } cursor-pointer`}
                        onClick={() => handleTopicClick('Permanent')}
                      >
                        Permanent
                      </h3>
                      <h3
                        className={`w-1/2 text-center py-[14px] lg:py-5 ${
                          selectedTopic === 'Locum' ? 'bg-white' : 'bg-gray-420'
                        } cursor-pointer`}
                        onClick={() => handleTopicClick('Locum')}
                      >
                        Locum
                      </h3>
                    </div>
                    <div className="flex items-center justify-start">
                      {selectedTopic === 'Permanent' ? (
                        <PermanentJobFilter
                          data={filterMenuData}
                          allStates={allStatesDetails}
                          professionId={professionId}
                          stateId={stateId}
                          specialtyId={specialtyId}
                          seniorityId={seniorityId}
                          onProfessionChange={handleProfessionChange}
                          onSpecialtyChange={handleSpecialtyChange}
                          onSeniorityChange={handleSeniorityChange}
                          onStateChange={handleStateChange}
                          onSuburbSelect={handleSuburbChange}
                          suburbId={suburbId}
                          regionId={regionId}
                          divisionId={divisionId}
                          onDivisionChange={handleDivisionChange}
                          onRegionChange={handleRegionChange}
                        />
                      ) : (
                        <LocumJobFilter
                          data={filterMenuData}
                          allStates={allStatesDetails}
                          professionId={professionId}
                          stateId={stateId}
                          specialtyId={specialtyId}
                          seniorityId={seniorityId}
                          onProfessionChange={handleProfessionChange}
                          onSpecialtyChange={handleSpecialtyChange}
                          onSeniorityChange={handleSeniorityChange}
                          onStateChange={handleStateChange}
                          onSuburbSelect={handleSuburbChange}
                          suburbId={suburbId}
                          regionId={regionId}
                          divisionId={divisionId}
                          onDivisionChange={handleDivisionChange}
                          onRegionChange={handleRegionChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
              <div>
                {stateLoading ||
                FilterDataLoading ||
                professionDataLoading ||
                allStateLoading ? (
                  <div className="flex items-center justify-center">
                    <LoadingComponent />
                  </div>
                ) : (
                  <div className="flex items-start justify-between gap-x-5">
                    <div>
                      <div className="">
                        {filteredData?.length === 0 ? (
                          <div className="">
                            <NoJobsCard selectedTopic={selectedTopic} />
                          </div>
                        ) : (
                          <div className="flex flex-col gap-y-6">
                            {displayedJobs?.map((job, index) => (
                              <motion.div
                                key={index}
                                variants={jobCardVariants}
                                initial="hidden"
                                animate="visible"
                              >
                                <MainJobCard
                                  data={job}
                                  onShareClick={handleShareClick}
                                />
                              </motion.div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="text-center mt-10">
                        {filteredData?.length > visibleJobs && (
                          <button
                            onClick={handleLoadMore}
                            className="text-blue-390 text-center text-[20px] leading-[23px]"
                          >
                            Load More...
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <Register /> */}
            {/* <DropDownSection /> */}
          </PageLayout>
          <PageLayout>
            <GratitudeComponent
              mainTitle="Refer and Earn"
              subTitle="2500 AUD$"
            />
            <NewCardComponent />
          </PageLayout>
          {/* <PageLayout>
            <InsightComponent />
          </PageLayout> */}
        </>
      )}
      <div className={`modal ${isShareModalOpen ? 'modal-open' : ''}`}>
        <ShareModal
          isOpen={isShareModalOpen}
          onClose={handleCloseShareModal}
          jobUrl={jobUrl}
        />
      </div>
    </>
  );
}
