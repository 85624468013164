import { ButtonProps } from '@/types/types';

export default function SocialMediaButton({
  type,
  title,
  variant,
  handleClick,
  iconSrc,
}: ButtonProps) {
  return (
    <button
      className={`border-none rounded-md lg:rounded-[9px] cursor-pointer flex items-center justify-center ${variant}`}
      type={type}
      onClick={handleClick}
    >
      {iconSrc && (
        <img
          src={iconSrc}
          alt="Icon"
          className={!title ? 'w-[26px] h-[26px]' : 'mr-2 w-[26px] h-[26px]'}
        />
      )}
      {title && (
        <span className="text-nowrap text-sm lg:text-base font-poppins font-normal">
          {title}
        </span>
      )}
    </button>
  );
}
