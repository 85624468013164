import { useEffect, useState, useMemo, useCallback } from 'react';
import GetAllJobDetailsApi from '@/api/GetAllJobDetailsApi';

interface FilterJobsData {}

interface FetchJobFilterData {
  filterData: FilterJobsData[] | null;
  FilterDataLoading: boolean;
  FilterDataError: Error | null;
}

interface FilterJobsParameterProps {
  divisionId?: number;
  professionId?: number;
  stateId?: number;
  specialtyId?: number;
  seniorityId?: number;
  regionId?: number;
  suburbId?: number;
}

export default function useFetchFilterAllJobsDataDetails({
  divisionId,
  professionId,
  stateId,
  specialtyId,
  seniorityId,
  regionId,
  suburbId,
}: FilterJobsParameterProps): FetchJobFilterData {
  console.log(divisionId);
  console.log(professionId);
  const [filterData, setFilterData] = useState<FilterJobsData[] | null>(null);
  const [FilterDataLoading, setLoading] = useState<boolean>(true);
  const [FilterDataError, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetAllJobDetailsApi({
        divisionId,
        professionId,
        stateId,
        specialtyId,
        seniorityId,
        regionId,
        suburbId,
      });
      setFilterData(response.data);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    professionId,
    stateId,
    specialtyId,
    seniorityId,
    regionId,
    suburbId,
    divisionId,
  ]);

  return { filterData, FilterDataLoading, FilterDataError };
}
