import { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import debounce from 'lodash.debounce';

const useTopMegaMenu = () => {
  const [selectedLink, setSelectedLink] = useState(null);
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const [isPolygonVisible, setIsPolygonVisible] = useState(false);
  const [isEmployerMenuOpen, setIsEmployerMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const megaMenuRef = useRef(null);
  const employerMenuRef = useRef(null);
  const polygonRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        megaMenuRef.current &&
        !megaMenuRef.current.contains(event.target) &&
        polygonRef.current &&
        !polygonRef.current.contains(event.target)
      ) {
        setSelectedLink(null);
        setIsMegaMenuOpen(false);
        setIsPolygonVisible(false);
      }
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        employerMenuRef.current &&
        !employerMenuRef.current.contains(event.target) &&
        polygonRef.current &&
        !polygonRef.current.contains(event.target)
      ) {
        setSelectedLink(null);
        setIsEmployerMenuOpen(false);
        setIsPolygonVisible(false);
      }
    };

    const handleScroll = () => {
      setSelectedLink(null);
      setIsMegaMenuOpen(false);
      setIsPolygonVisible(false);
      setIsEmployerMenuOpen(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Close menus when location changes
  useEffect(() => {
    setSelectedLink(null);
    setIsMegaMenuOpen(false);
    setIsPolygonVisible(false);
    setIsEmployerMenuOpen(false);
  }, [location]);

  const handleLinkClick = (link, navigate) => {
    setSelectedLink(link);
    setIsMegaMenuOpen(link === 'browserJobs');
    setIsPolygonVisible(true);
    if (link !== 'browserJobs') {
      setSelectedLink(null);
      setIsMegaMenuOpen(false);
      setIsPolygonVisible(false);
      navigate(link);
    }
  };

  const handleNavigation = () => {
    setSelectedLink(null);
    setIsMegaMenuOpen(false);
    setIsPolygonVisible(false);
    setIsEmployerMenuOpen(false);
  };

  const debouncedHandleMouseLeave = useCallback(
    debounce(() => {
      setSelectedLink(null);
      setIsMegaMenuOpen(false);
      setIsEmployerMenuOpen(false);
      setIsPolygonVisible(false);
    }, 300),
    []
  );

  const handleMouseEnter = (link) => {
    debouncedHandleMouseLeave.cancel();
    setSelectedLink(link);
    if (link === 'browserJobs') {
      setIsMegaMenuOpen(true);
      setIsEmployerMenuOpen(false);
    } else if (link === 'employers') {
      setIsMegaMenuOpen(false);
      setIsEmployerMenuOpen(true);
    }
    setIsPolygonVisible(true);
  };

  const handleMouseLeave = () => {
    debouncedHandleMouseLeave();
  };

  const handleMenuMouseEnter = () => {
    debouncedHandleMouseLeave.cancel();
  };

  const handleMenuMouseLeave = () => {
    debouncedHandleMouseLeave();
  };

  const handlePolygonMouseEnter = () => {
    debouncedHandleMouseLeave.cancel();
  };

  const handlePolygonMouseLeave = () => {
    debouncedHandleMouseLeave();
  };

  return {
    selectedLink,
    isMegaMenuOpen,
    isPolygonVisible,
    isEmployerMenuOpen,
    handleLinkClick,
    handleMouseEnter,
    handleMouseLeave,
    handleMenuMouseEnter,
    handleMenuMouseLeave,
    handlePolygonMouseEnter,
    handlePolygonMouseLeave,
    menuRef,
    megaMenuRef,
    employerMenuRef,
    polygonRef,
    handleNavigation,
  };
};

export default useTopMegaMenu;
