import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';

export const images = [
  {
    src: `${AWS_BASE_URL_ASSETS}/medfuture/Optometry (2).jpeg`,
    alt: 'Optometry',
    label: 'Optometry',
  },
  {
    src: `${AWS_BASE_URL_ASSETS}/medfuture/Occupational.jpeg`,
    alt: 'Occupational Therapy',
    label: 'Occupational Therapy',
  },
  {
    src: `${AWS_BASE_URL_ASSETS}/medfuture/Hospital.jpeg`,
    alt: 'Hospital',
    label: 'Hospital',
  },
  {
    src: `${AWS_BASE_URL_ASSETS}/medfuture/General.jpeg`,
    alt: 'General Practice',
    label: 'General Practice',
  },
  {
    src: `${AWS_BASE_URL_ASSETS}/medfuture/Occupational.jpeg`,
    alt: 'Optometry',
    label: 'Optometry',
  },
  {
    src: `${AWS_BASE_URL_ASSETS}/medfuture/Audiological.jpeg`,
    alt: 'Audiological Service',
    label: 'Audiological Service',
  },
  {
    src: `${AWS_BASE_URL_ASSETS}/medfuture/Emergency.jpeg`,
    alt: 'Emergency Department',
    label: 'Emergency Department',
  },
  {
    src: `${AWS_BASE_URL_ASSETS}/medfuture/GeneralDental.jpeg`,
    alt: 'General Dental Practice',
    label: 'General Dental Practice',
  },
];
