import { getBaseUrl } from '@/config/BaseUrl';

export default async function GetJobCountByState() {
  try {
    const baseUrl = getBaseUrl();
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    };
    const response = await fetch(
      `${baseUrl}web/getStatesJobCount`,
      requestOptions
    );

    if (response.status === 429) {
      throw {
        status: 429,
        message: 'Too Many Requests',
        data: { retry_after: 60 },
      };
    }

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error getting job count by state: ', error);
    throw error;
  }
}
