import { ReactNode } from 'react';

interface MobilePageLayoutProps {
  children: ReactNode;
  bgColor?: string; // Optional background color
}

export default function MobileLayout({
  children,
  bgColor,
}: MobilePageLayoutProps) {
  return (
    <section
      className={`max-width-container ${bgColor ? `bg-${bgColor}` : ''}`}
    >
      <div className="mobile-container">{children}</div>
    </section>
  );
}
