import React, { useState } from 'react';
import Slider from 'react-slick';
import UpArrow from '@/assets/icons/upArrow.png';
import DownArrow from '@/assets/icons/downArrow.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MobileSlickSettings } from '@/lib/Caresoul/CaresoulMobileSettings';
import { AWS_MOBILE_DIVISION_URL } from '@/config/seviceApiConfig';

interface MobileDropDownItemProps {
  id: number;
  key: number;
  label: string;
  data: { title: string; url: string }[];
}

const MobileDropDownItem: React.FC<MobileDropDownItemProps> = ({
  label,
  data,
  id,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(id === 1);
  const [activeIndex, setActiveIndex] = useState(0);
  const settings = MobileSlickSettings({ activeIndex, setActiveIndex });

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const specialties: Specialty[] = [];
  const seniorities: Seniority[] = [];

  data.forEach((profession) => {
    specialties.push(...profession.specilities);
    seniorities.push(...profession.seniorities);
  });

  return (
    <div>
      <div
        className="flex items-center justify-between bg-white w-full drop-shadow-lg p-4 mb-2 mt-2 "
        onClick={toggleMenu}
      >
        <h2 className="dropDown-topic">{label} </h2>
        <span>
          {isOpen ? (
            <img src={UpArrow} alt="Up Arrow" />
          ) : (
            <img src={DownArrow} alt="arrows" />
          )}
        </span>
      </div>

      {isOpen && (
        <Slider {...settings}>
          {specialties.map((specialty, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-md w-[10.75rem] p-3 mb-4"
            >
              <div className="gap-x-3 flex items-center justify-normal">
                <div className="w-[20px] h-[20px]">
                  <img
                    src={`${AWS_MOBILE_DIVISION_URL}${specialty.menu_icon}`}
                    alt={specialty.name}
                    className="w-full h-full object-cover object-center"
                  />
                </div>
                <p className="dropDown-sub-topics">{specialty.name}</p>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default MobileDropDownItem;
