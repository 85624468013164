import useFetchBanner from './hook/useFetchBanner';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { BannerItem } from './types'; // Assuming you have a type for BannerItem
import BlurryLoadingImage from '../../../hooks/useFetchImage';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useResponsive from '@/hooks/useResponsive';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';

export default function Banner() {
  const { data, loading, error } = useFetchBanner();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { isMobile } = useResponsive();

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    touchMove: true,
    appendDots: (dots: JSX.Element[]) => (
      <div className="slick-dots">
        {dots.map((dot, index) => (
          <div key={index} className="inline-block m-[2px] cursor-pointer">
            {dot}
          </div>
        ))}
      </div>
    ),
    customPaging: (i: number) => (
      <div
        className={`w-[0.688rem] h-[0.688rem] lg:w-[0.813rem] lg:h-[0.813rem] rounded-full mt-7 ${i === activeIndex ? 'bg-blue-400' : 'bg-gray-450'}`}
      />
    ),
    beforeChange: () => {
      const maxIndex = 2;
      setActiveIndex((oldIndex) => {
        const newIndex = oldIndex + 1;
        return newIndex > maxIndex ? 0 : newIndex;
      });
    },
  };

  const Variant = {
    initial: {
      scale: 0.8,
      opacity: 0.5,
    },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.2,
      },
    },
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <motion.section
      className="padding-container"
      variants={Variant}
      initial="initial"
      whileInView="animate"
    >
      {!isMobile ? (
        <motion.div
          className="grid grid-cols-4 xl:gap-3 cursor-pointer"
          variants={Variant}
        >
          {data.map((item: BannerItem) => (
            <motion.div>
              <Link to={`${item.route}`} key={item.id}>
                <BlurryLoadingImage
                  preview={`${AWS_BASE_URL_ASSETS}${item.url}`}
                  image={`${AWS_BASE_URL_ASSETS}${item.url}`}
                  alt={item.title}
                  imageStyleClass=""
                  divStyleClass="banner-container"
                  bgColor={''}
                />
              </Link>
            </motion.div>
          ))}
        </motion.div>
      ) : (
        <Slider {...settings}>
          {data.map((item: BannerItem) => (
            <Link key={item.id} to={item.route}>
              <BlurryLoadingImage
                preview={`${AWS_BASE_URL_ASSETS}${item.url}`}
                image={`${AWS_BASE_URL_ASSETS}${item.url}`}
                alt={item.title}
                imageStyleClass=""
                divStyleClass="banner-container"
                bgColor={''}
              />
            </Link>
          ))}
        </Slider>
      )}
    </motion.section>
  );
}
