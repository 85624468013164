// utils/ConfirmPasswordUtil.ts
export function confirmPassword(
  password: string,
  confirmPassword: string
): string {
  if (!password && !confirmPassword) {
    return 'Please enter your password to confirm';
  } else if (!password) {
    return 'Please provide your password.';
  } else if (!confirmPassword) {
    return 'Please confirm your password.';
  } else if (password !== confirmPassword) {
    return 'Password does not match with original';
  }
  return '';
}
