import { useState } from 'react';
import { ProfessionSelector } from '@/components/forms/selectors/ProfessionSelector';
import { StateSelector } from '@/components/forms/selectors/StateSelector';
import Button from '@/components/shared/button/Button';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { verifyEmailFormat } from '@/utils/EmailVerification';
import { isPhoneValid } from '@/utils/PhoneNumberVerification';
import { Link } from 'react-router-dom';
import { SenioritySelector } from '../selectors/SenioritySelector';
import { SpecialtySelector } from '../selectors/SpecialtySelector';
import PopupNotification from '@/components/shared/notification/PopupNotification';
import { FORM_ERROR_TIME } from '@/constants/Constant';
import CommonReachUsApi from '@/api/Registration/ReachUsReagisterApi';

export default function LandingReachUs() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    profession: '',
    state: '',
    seniority: '',
    specialty: '',
    phoneNumber: '',
    agree: false,
    subscribe: false,
  });
  const [submit, setSubmit] = useState<boolean>(false);
  const [agreeError, setAgreeError] = useState<string>('');
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    profession: '',
    state: '',
    seniority: '',
    specialty: '',
  });
  const [PhoneError, setPhoneError] = useState<string>('');
  const [notification, setNotification] = useState({
    show: false,
    title: '',
    message: '',
    type: 'success' as 'success' | 'error',
  });

  const handleChange = (e: {
    target: { id: string; type: string; checked: boolean; value: string };
  }) => {
    const { id, type, checked, value } = e.target;
    setFormData({
      ...formData,
      [id]: type === 'checkbox' ? checked : value,
    });
  };

  const PageType = 'Landing Page';
  const ProStatus = 'No Value';
  const fileName = 'No File';
  const Note = 'No Note';

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.profession ||
      !formData.state ||
      !formData.phoneNumber ||
      !formData.agree
    ) {
      setSubmit(false);
      const newErrors = {
        firstName: !formData.firstName ? 'Please enter your first name' : '',
        lastName: !formData.lastName ? 'Please enter your last name' : '',
        email: !formData.email
          ? 'Please enter your email address'
          : !verifyEmailFormat(formData.email)
            ? 'Please enter a valid email address'
            : '',
        profession: !formData.profession ? 'Please select profession' : '',
        state: !formData.state ? 'Please select state' : '',
        seniority: !formData.seniority ? 'Please select seniority level' : '',
        specialty: !formData.specialty ? 'Please select specialty level' : '',
      };
      setFormErrors(newErrors);
      setTimeout(
        () =>
          setFormErrors({
            firstName: '',
            lastName: '',
            email: '',
            profession: '',
            state: '',
            seniority: '',
            specialty: '',
          }),
        FORM_ERROR_TIME
      );
    }
    if (!isPhoneValid(formData.phoneNumber)) {
      setPhoneError('Please enter a valid phone number.');
      setTimeout(() => setPhoneError(''), 3000);
      setSubmit(false);
    }

    if (!formData.agree) {
      setSubmit(false);
      setAgreeError(
        'You must agree to the Terms and Conditions and Privacy Policy.'
      );
      setTimeout(() => setAgreeError(''), 3000);
      return;
    }
    try {
      const apiResponse = await CommonReachUsApi(
        formData.firstName,
        formData.lastName,
        formData.profession,
        formData.state,
        formData.specialty,
        formData.seniority,
        formData.email,
        Note,
        formData.phoneNumber,
        PageType,
        ProStatus,
        fileName
      );
      if (apiResponse) {
        setSubmit(true);
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          profession: '',
          seniority: '',
          specialty: '',
          state: '',
          phoneNumber: '',
          agree: false,
          subscribe: false,
        });
      } else {
        console.error('Message Submission Failed ', apiResponse.error);
        setNotification({
          show: true,
          title: 'Message Submission Failed',
          message: apiResponse.error,
          type: 'error',
        });
      }
    } catch (error) {
      console.error('Error during registration: ', error);
      setNotification({
        show: false,
        title: 'Message Submission Failed',
        message:
          'An error occurred during registration. Please try again later.',
        type: 'error',
      });
    }
  };

  return (
    <div
      className="relative padding-container flex items-center justify-center"
      id="register-form"
    >
      <form
        onSubmit={handleSubmit}
        className="p-[70px] w-[1054px] max-h-[800px] drop-shadow-2xl bg-white rounded-lg"
        noValidate
      >
        <div className="text-center font-sans font-normal text-[42px] leading-[76px] mb-6">
          Reach Us
        </div>
        <div className="flex items-center justify-between gap-6 mb-10">
          <div className="relative min-w-[442px]">
            <input
              type="text"
              id="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className={`form-container input-placeholder ${formErrors.firstName ? 'outline-red-300' : 'outline-blue-250'}`}
              autoComplete="off"
              placeholder="First Name"
            />
            {formErrors.firstName && (
              <div className="form-error-msg">{formErrors.firstName}</div>
            )}
          </div>
          <div className="relative min-w-[442px]">
            <input
              type="text"
              id="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className={`form-container input-placeholder ${formErrors.lastName ? 'outline-red-300' : 'outline-blue-250'}`}
              autoComplete="off"
              placeholder="Last Name"
            />
            {formErrors.lastName && (
              <div className="form-error-msg">{formErrors.lastName}</div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between gap-6 mb-10">
          <div className="relative min-w-[442px]">
            <ProfessionSelector
              onChange={(value) =>
                setFormData({ ...formData, profession: value })
              }
              error={formErrors.profession}
            />
            {formErrors.profession && (
              <div className="form-error-msg">{formErrors.profession}</div>
            )}
          </div>
          <div className="relative min-w-[442px] z-[3]">
            <StateSelector
              onChange={(value) => setFormData({ ...formData, state: value })}
              error={formErrors.state}
            />
            {formErrors.state && (
              <div className="form-error-msg">{formErrors.state}</div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between gap-6 mb-10">
          <div className="relative min-w-[442px]">
            <SpecialtySelector
              onChange={(value) =>
                setFormData({ ...formData, specialty: value })
              }
              error={formErrors.specialty}
              professionId={parseInt(formData.profession)}
            />
            {formErrors.specialty && (
              <div className="form-error-msg">{formErrors.specialty}</div>
            )}
          </div>
          <div className="relative min-w-[442px] z-[2]">
            <SenioritySelector
              onChange={(value) =>
                setFormData({ ...formData, seniority: value })
              }
              error={formErrors.seniority}
              professionId={parseInt(formData.profession)}
            />
            {formErrors.seniority && (
              <div className="form-error-msg">{formErrors.seniority}</div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between gap-6 mb-10">
          <div className="relative min-w-[442px]">
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className={`form-container input-placeholder ${formErrors.email ? 'outline-red-300' : 'outline-blue-250'}`}
              autoComplete="off"
              placeholder="Email"
            />
            {formErrors.email && (
              <div className="form-error-msg">{formErrors.email}</div>
            )}
          </div>
          <div className="flex items-center justify-between relative w-[442px]">
            <PhoneInput
              forceDialCode={true}
              defaultCountry="nz"
              countrySelectorStyleProps={{
                buttonStyle: {
                  zIndex: '1',
                  position: 'absolute',
                  left: '0px',
                  height: '36px',
                  width: '56px',
                  border: 'none',
                  backgroundColor: '#FFFFFF',
                },
              }}
              onChange={(phone) =>
                setFormData({ ...formData, phoneNumber: phone })
              }
              inputProps={{
                id: 'phoneNumber',
                autoComplete: 'off',
                className:
                  'w-full relative pl-16 focus:outline-none input-placeholder',
              }}
              className={`form-container input-placeholder ${PhoneError ? 'outline-red-300' : 'outline-blue-250'}`}
              value={formData.phoneNumber}
            />
            {PhoneError && (
              <div className="text-red-100 absolute text-sm mt-[76px]">
                {PhoneError}
              </div>
            )}
          </div>
        </div>
        <div className="mb-14 mt-10">
          <div className="mb-5 mt-10">
            <div className="relative">
              <input
                type="checkbox"
                id="agree"
                checked={formData.agree}
                onChange={handleChange}
              />
              <label
                htmlFor="agree"
                className={`relative ${
                  agreeError ? 'after:text-red-100' : 'after:text-gray-900'
                } after:content-['*'] after:ml-1  absolute top-0 left-3`}
              >
                I Agree to the{' '}
                <Link to={'/terms-and-conditions'} className="text-blue-220">
                  Term and Condition
                </Link>{' '}
                and{' '}
                <Link to={'/privacy-policy'} className="text-blue-220">
                  Privacy policy
                </Link>
              </label>
              {agreeError && (
                <div className="absolute text-red-100 text-sm ml-5">
                  {agreeError}
                </div>
              )}
            </div>
          </div>

          <div>
            <input
              type="checkbox"
              id="subscribe"
              checked={formData.subscribe}
              onChange={handleChange}
              className=""
              required
            />
            <label htmlFor="subscribe" className="ml-2">
              Subscribe for Job Alert
            </label>
          </div>
        </div>
        <div className="absolute bottom-[20px] right-[70px] flex flex-col items-center">
          <div className="w-[237px]">
            <Button type="submit" title="Submit" variant="find-jobs-btn" />
          </div>
          <div className="py-5">
            <Link to={''} className="text-blue-400 text-sm">
              Do you need to Register?
            </Link>
          </div>
        </div>
      </form>
      {submit && (
        <PopupNotification
          title={'Message Submitted'}
          message={'We will contact you with in 24 hours'}
          onClose={() => setSubmit(false)}
          type={'success'}
        />
      )}
      {notification.show && (
        <PopupNotification
          title={notification.title}
          message={notification.message}
          onClose={() => setNotification({ ...notification, show: false })}
          type={notification.type}
        />
      )}
    </div>
  );
}
