import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import useFetchIndustryTypes from './hook/useFetchIndustryType';
import useResponsive from '@/hooks/useResponsive';
import { useLocation } from 'react-router-dom';

interface StateSelectorProps {
  onChange: (value: string) => void;
  error: string;
  title: string;
  resetSelector?: boolean;
}

const IndustryTypeSelector: React.FC<StateSelectorProps> = ({
  onChange,
  error,
  title,
  resetSelector = false,
}) => {
  const { isMobile } = useResponsive();
  const { allIndustryTypes } = useFetchIndustryTypes();
  const location = useLocation();
  const [selectedIndustry, setSelectedIndustry] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [defaultOptions, setDefaultOptions] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    if (allIndustryTypes) {
      const mappedOptions = allIndustryTypes.map((industry) => ({
        value: industry.industry_type_id.toString(),
        label: industry.name,
      }));
      setDefaultOptions(mappedOptions);
    }
  }, [allIndustryTypes]);

  useEffect(() => {
    if (resetSelector) {
      handleReset();
    }
  }, [resetSelector]);

  const handleReset = () => {
    setSelectedIndustry(null);
    onChange('');
  };

  const loadIndustryTypes = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    if (!allIndustryTypes) {
      callback([]);
      return;
    }

    const filteredOptions = allIndustryTypes
      .filter((industry) =>
        industry.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((industry) => ({
        value: industry.industry_type_id.toString(),
        label: industry.name,
      }));

    callback(filteredOptions);
  };

  const handleChange = (selectedOption: any) => {
    setSelectedIndustry(selectedOption);
    onChange(selectedOption ? selectedOption.value : '');
  };

  const renderFormStyle = () => {
    const path = location.pathname;
    let baseClass = 'form-container';
    if (isMobile) {
      baseClass = 'mobile-form-container';
    } else if (path === '/premier-locum-general-practitioner') {
      baseClass = 'locum-form-container1';
    } else if (path === '/premier-locum-doctor' || path === '/spot-workforce') {
      baseClass = 'locum-form-container2';
    }
    return `${baseClass} ${error ? 'outline-red-300' : 'outline-blue-250'}`;
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      border: 'none',
      outline: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer', // Adding cursor pointer here
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? 'rgba(13, 26, 62, 1)'
        : state.isFocused
          ? 'rgba(28, 155, 221, 1)'
          : null,
      color: state.isSelected || state.isFocused ? '#FFFFFF' : '#515869',
      animation: 'fadeInPositive 0.5s ease-in-out',
    }),
    menu: (provided) => ({
      ...provided,
      backdropFilter: 'blur(10px)',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      marginRight: '8px',
      marginBottom: '4px',
    }),
    menuList: (provided) => ({
      ...provided,
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: '0px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#A8a9ad',
        borderRadius: '0px',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#515869',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#515869',
    }),
  };

  return (
    <div className={`select-wrapper ${renderFormStyle()} form-text`}>
      <AsyncSelect
        id="industryType"
        value={selectedIndustry}
        onChange={handleChange}
        loadOptions={loadIndustryTypes}
        defaultOptions={defaultOptions}
        placeholder={title}
        styles={customStyles}
        isClearable
        cacheOptions
      />
      {/* {error && <div className="form-error-msg">{error}</div>} */}
    </div>
  );
};

export { IndustryTypeSelector };
