import Button from '@/components/shared/button/Button';

export default function MobileContactBanner() {
  return (
    <section className="max-width-container bg-blue-240">
      <div className="mobile-container py-[1.125rem] md:py-8">
        <h2 className="font-normal text-[22px] text-center leading-[30px]">
          Partner with us to find your next opportunity
        </h2>
        <div className="py-8">
          <Button
            type={'button'}
            title={'Register Now'}
            variant="find-jobs-btn"
          />
        </div>
      </div>
    </section>
  );
}
