import Logo from '../shared/logo/Logo';

export default function PageLoadingComponent() {
  return (
    <div className="loading-container">
      <div className="absolute top-[40%] transform -translate-y-[40%]">
        <div className="w-[200px] h-[180px] lg:w-[300px] lg:h-[200px]">
          <Logo height={200} width={300} />
        </div>
      </div>
      <div className="loading-dots absolute top-[42%] transform -translate-y-[42%]">
        <div className="dots dot1"></div>
        <div className="dots dot2"></div>
        <div className="dots dot3"></div>
      </div>
    </div>
  );
}
