import { getBaseUrl } from '@/config/BaseUrl';

export default async function ClientReachRegisterApi(
  client_name: string,
  trade_name: string,
  abn: string,
  website: string,
  name: string,
  designation: string,
  email: string,
  mobile: string,
  industry_type: string,
  message: string,
  profession_id: string,
  placement_type: string,
  job_description: string,
  candidate_alert: boolean,
  user_agreements: boolean,
  file_name
) {
  try {
    const requestBody = {
      client_name: client_name,
      trade_name: trade_name,
      abn: abn,
      website: website,
      name: name,
      designation: designation,
      email: email,
      mobile: mobile,
      industry_type: industry_type,
      profession_id: profession_id,
      placement_type: placement_type,
      message: message,
      job_description: job_description,
      candidate_alert: candidate_alert,
      user_agreements: user_agreements,
      status: true,
      file_name: file_name,
    };
    const baseUrl = getBaseUrl();
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(
      `${baseUrl}web/client_reach_us/save`,
      requestOptions
    );

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message);
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error('Error submitting information:', error);
    throw error;
  }
}
