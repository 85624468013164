import { FC } from 'react';
import Button from '../shared/button/Button';
import { CardProps } from './type';
import shareImg from '@/assets/icons/share.png';
import { useNavigate } from 'react-router-dom';
import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import { formatJobNameForURL } from '@/utils/formatJobNameforURL';

interface CommonJobCardProps extends CardProps {
  onShareClick: (jobUrl: string) => void;
  onApplyClick: () => void;
}

const CommonJobCard: FC<CommonJobCardProps> = ({ offer, onShareClick }) => {
  const navigate = useNavigate();

  const handleNavigateAdv = (event: React.MouseEvent) => {
    // localStorage.setItem('jobData', JSON.stringify(offer));
    event.stopPropagation();
    const jobUrl = formatJobNameForURL(offer.job_title);
    if (offer.is_permanent === '1') {
      navigate(`/apply-now/permanent/${jobUrl}/${offer.jobdetails_id}`, {
        state: { jobData: offer },
      });
    } else {
      navigate(`/apply-now/locum/${jobUrl}/${offer.jobdetails_id}`, {
        state: { jobData: offer },
      });
    }
    window.scrollTo(0, 0);
  };

  const handleShareButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    const jobUrl = formatJobNameForURL(offer.job_title);
    const shareUrl =
      offer.is_permanent === '1'
        ? `${window.location.origin}/apply-now/permanent/${jobUrl}/${offer.jobdetails_id}`
        : `${window.location.origin}/apply-now/locum/${jobUrl}/${offer.jobdetails_id}`;
    onShareClick(shareUrl);
  };

  const getDisplayText = (suburb, state) => {
    if (suburb && state) return `${suburb}, ${state}`;
    if (suburb) return suburb;
    if (state) return state;
    return '';
  };
  return (
    <div
      key={offer.jobdetails_id}
      className="max-w-[480px] max-h-[590px] shadow-md rounded-md mr-3 cursor-pointer my-[2px]"
      onClick={handleNavigateAdv}
    >
      <div>
        <div className="flex flex-col card-text-items">
          <div className="bg-gray-200 p-5 relative">
            <div className="flex items-center justify-between relative">
              <div className="flex items-center justify-normal mb-1">
                <h3 className="card-text-main mr-4">
                  {offer.profession?.name}
                </h3>
                <h3 className="text-gray-480 font-semibold bg-gray-20 border rounded-md border-gray-120 px-[6px] text-sm">
                  {offer.job_id}
                </h3>
              </div>
              <div className="absolute top-0 right-2">
                <img
                  src={`${AWS_BASE_ASSETS_ICONS}NZIcon.png`}
                  width={43}
                  height={80}
                  alt="aus"
                />
              </div>
            </div>
            <div className="flex items-center mb-1">
              <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
                <img
                  src={`${AWS_BASE_ASSETS_ICONS}CurrencyIcon.png`}
                  alt="Salary Icon"
                  className="w-[14.8px] h-[14.8px] object-contain"
                />
              </div>
              {offer.billing_share && offer.hourly_fee ? (
                <p className="line-clamp-1">{`${offer.hourly_fee} or ${offer.billing_share}`}</p>
              ) : (
                <p className="line-clamp-1">
                  {offer.hourly_fee || offer.billing_share}
                </p>
              )}
            </div>
            <div className="flex items-center  my-1">
              <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
                <img
                  src={`${AWS_BASE_ASSETS_ICONS}LocationIcon.png`}
                  alt="Salary Icon"
                  className="w-[14.8px] h-[15.3px] object-contain"
                />
              </div>
              <p className="line-clamp-1">
                {getDisplayText(offer.suburb?.name, offer.state?.name)}
              </p>
            </div>
            <div className="flex items-center  my-1">
              <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
                <img
                  src={`${AWS_BASE_ASSETS_ICONS}ClockIcon.png`}
                  alt="Salary Icon"
                  className="w-[14.8px] h-[14.8px] object-contain "
                />
              </div>
              <p>{offer.engagement_type?.name}</p>
            </div>
            <div className="flex items-center  my-1">
              <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
                <img
                  src={`${AWS_BASE_ASSETS_ICONS}JobTypeIcon.png`}
                  alt="Salary Icon"
                  className="w-[13.34px] h-[12.13px] object-contain"
                />
              </div>
              <p>{offer.is_permanent === '1' ? 'Permanent' : 'Locum'}</p>
            </div>
            <div className="absolute bottom-4 right-4 font-normal font-sans text-sm leading-9">
              Post Date: {offer.commencement_date}
            </div>
          </div>
          <div className="p-5">
            <h2 className="font-sans font-normal text-[26px] leading-[34px] text-blue-700 h-[73px] line-clamp-2">
              {offer.job_title}
            </h2>
            <div className="h-[97px]">
              <p className="font-satoshi text-base leading-[26px] font-normal text-gray-700 line-clamp-3 mt-5">
                {offer.job_brief}
              </p>
            </div>
            <div className="mt-10 flex items-center justify-between gap-x-2">
              <Button
                type="button"
                title="Share"
                variant={'all-jobs-share-btn'}
                iconSrc={shareImg}
                handleClick={handleShareButtonClick}
              />
              <Button
                type="button"
                title="View More"
                variant="apply-now-btn"
                handleClick={handleNavigateAdv}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonJobCard;
