import Button from '../shared/button/Button';

export default function ServiceComponent() {
  return (
    <section className="padding-container">
      <h2 className="font-sans font-bold text-center text-gray-800 text-[32px] leading-[44px] mb-16">
        Our Solution
      </h2>
      <div className="grid grid-cols-2 gap-5 ">
        <div className="group relative cursor-pointer bg-gray-510 h-[331px] p-10">
          <div className="relative z-[4]">
            <h2 className="font-sans font-bold text-gray-800 group-hover:text-white text-[26px] leading-[35px]">
              GP Locum Services
            </h2>
            <p className="font-sans font-normal text-lg leading-[34px] text-blue-350 group-hover:text-white mt-10">
              Needs Assessment We begin by conducting a thorough assessment of
              your staffing needs,
            </p>
            <div className="w-[202px] mt-10">
              <Button
                type="button"
                title="Discover More "
                variant="find-jobs-btn"
              />
            </div>
          </div>
          <div className="absolute inset-0 bg-blue-150 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700 ease-in-out"></div>
        </div>
        <div className="group cursor-pointer relative bg-gray-510 h-[331px] p-10">
          <div className="relative z-[4]">
            <h2 className="font-sans font-bold text-gray-800 group-hover:text-white text-[26px] leading-[35px]">
              Nursing Locum Services
            </h2>
            <p className="font-sans font-normal text-lg leading-[34px] text-blue-350 group-hover:text-white  mt-10">
              Needs Assessment We begin by conducting a thorough assessment of
              your staffing needs,
            </p>
            <div className="w-[202px] mt-10">
              <Button
                type="button"
                title="Discover More "
                variant="find-jobs-btn"
              />
            </div>
          </div>
          <div className="absolute inset-0 bg-blue-150 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700 ease-in-out"></div>
        </div>
        <div className="group cursor-pointer relative bg-gray-510 h-[331px] p-10">
          <div className="relative z-[4]">
            <h2 className="font-sans font-bold text-gray-800 group-hover:text-white text-[26px] leading-[35px]">
              Emergency Doctor Services
            </h2>
            <p className="font-sans font-normal text-lg leading-[34px] text-blue-350 group-hover:text-white  mt-10">
              Needs Assessment We begin by conducting a thorough assessment of
              your staffing needs,
            </p>
            <div className="w-[202px] mt-10">
              <Button
                type="button"
                title="Discover More "
                variant="find-jobs-btn"
              />
            </div>
          </div>
          <div className="absolute inset-0 bg-blue-150 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700 ease-in-out"></div>
        </div>
        <div className="group cursor-pointer relative bg-gray-510 h-[331px] p-10">
          <div className="relative z-[4]">
            <h2 className="font-sans font-bold text-gray-800 group-hover:text-white text-[26px] leading-[35px]">
              Allied Health Professional
            </h2>
            <p className="font-sans font-normal text-lg leading-[34px] text-blue-350 group-hover:text-white  mt-10">
              Needs Assessment We begin by conducting a thorough assessment of
              your staffing needs,
            </p>
            <div className="w-[202px] mt-10">
              <Button
                type="button"
                title="Discover More "
                variant="find-jobs-btn"
              />
            </div>
          </div>
          <div className="absolute inset-0 bg-blue-150 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700 ease-in-out"></div>
        </div>
      </div>
    </section>
  );
}
