export function formatNameForURL(name: string): string {
  return name
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/\//g, '-')
    .replace(/\|/g, '-')
    .replace(/-+/g, '-');
}

// export function formatNameForURL(name: string) {
//   return name
//     .trim()
//     .toLowerCase()
//     .replace(/ /g, '-')
//     .replace(/[^a-z0-9\\-]/g, '');
// }
