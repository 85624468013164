import MegaMenu from '@/components/mainMenu/MegaMenu';
import useFetchBottomMenuData from '../hook/useBottomNavBar';
import useMegaMenu from '@/hooks/useScrollMenuHiding';
import { motion } from 'framer-motion';

const BottomNavbar = () => {
  const { bottomMenuData } = useFetchBottomMenuData();
  const { selectedLink, isMegaMenuOpen, isPolygonVisible, handleLinkClick } =
    useMegaMenu();

  return (
    <section className="bottom-nav-container relative border-t-[0.01px] border-gray-600">
      <div>
        <ul className="flex lg:gap-[13px] xl:gap-[0px] 2xl:gap-[20.6px] justify-center items-center relative">
          {bottomMenuData?.map((link) => (
            <li key={link.divisions_id} className="relative">
              <button
                className={`relative lg:h-[50px] xl:h-[65px] ${
                  selectedLink === link && isPolygonVisible
                    ? 'bottom-nav-link-active'
                    : 'bottom-nav-link'
                }`}
                onClick={() => handleLinkClick(link)}
              >
                <motion.span
                  className="relative z-[8]"
                  whileHover={{ scale: 1.1 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  {link.name}
                </motion.span>
                {selectedLink === link && (
                  <motion.span
                    layoutId="pill-tab"
                    transition={{ type: 'spring', duration: 0.5 }}
                    className={`absolute inset-0 ${
                      isMegaMenuOpen
                        ? 'bg-gradient-to-r from-white to-gray-200'
                        : ''
                    } rounded-t-lg`}
                  ></motion.span>
                )}
              </button>
            </li>
          ))}
        </ul>
        {selectedLink && isMegaMenuOpen && (
          <MegaMenu link={selectedLink} isOpen={isMegaMenuOpen} />
        )}
      </div>
    </section>
  );
};

export default BottomNavbar;
