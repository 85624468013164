import { useEffect, useRef, useState } from 'react';
import Button from '@/components/shared/button/Button';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { verifyEmailFormat } from '@/utils/EmailVerification';
import { isPhoneValid } from '@/utils/PhoneNumberVerification';
import { Link, useNavigate } from 'react-router-dom';
import { validatePassword } from '@/utils/PasswordValidationUtil';
import PopupNotification from '@/components/shared/notification/PopupNotification';
import { IndustryTypeSelector } from '../selectors/IndustryTypesSelector';
import SocialMediaButton from '@/components/shared/button/SocialMediaBtn';
import OpenEye from '/icons/OpenEye.png';
import CloseEye from '/icons/CloseEye.png';
import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import { SocialMediaSelector } from '../selectors/SocialMediaSelector';
import pdfIcon from '/icons/pdfIcon.png';
import wordIcon from '/icons/wordIcon.png';
import { confirmPassword } from '@/utils/confirmPasswordUtil';
import { getBaseUrl } from '@/config/BaseUrl';
import ClientReachRegisterApi from '@/api/Registration/ClientReachRegisterApi';
import ClientRegistrationApi from '@/api/Registration/ClientRegistrationApi';
import { validateUrl } from '@/utils/validationWebsiteUrl';
import SubmitButton from '@/components/shared/button/SubmitBtn';

export default function EmployerRegisterForm() {
  const navigate = useNavigate();
  const [selectedTopic, setSelectedTopic] = useState('Client');
  const [formData, setFormData] = useState({
    companyName: '',
    tradeName: '',
    industryType: '',
    website: '',
    contactPerson: '',
    designation: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    fileUpload: '',
    social: '',
    agree: false,
    subscribe: false,
  });
  const [submit, setSubmit] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [notification, setNotification] = useState({
    show: false,
    title: '',
    message: '',
    type: 'success' as 'success' | 'error',
  });
  const [formErrors, setFormErrors] = useState({
    companyName: '',
    tradeName: '',
    industryType: '',
    website: '',
    contactPerson: '',
    designation: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    fileUpload: '',
    social: '',
    agree: false,
  });
  const companyNameRef = useRef<HTMLInputElement>(null);
  const businessTypeRef = useRef<HTMLInputElement>(null);
  const contactPersonRef = useRef<HTMLSelectElement>(null);
  const designationRef = useRef<HTMLSelectElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const agreeRef = useRef<HTMLInputElement>(null);

  const allErrors =
    formErrors.companyName ||
    formErrors.industryType ||
    formErrors.contactPerson ||
    formErrors.designation ||
    formErrors.email ||
    formErrors.phoneNumber ||
    formErrors.password ||
    formErrors.confirmPassword ||
    formErrors.agree;

  const handleChange = (e: {
    target: { id: string; type: string; checked: boolean; value: string };
  }) => {
    const { id, type, checked, value } = e.target;
    setFormData({
      ...formData,
      [id]: type === 'checkbox' ? checked : value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const getIconForFileType = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (extension === 'pdf') {
      return <img src={pdfIcon} alt="PDF Icon" className="w-10 h-10 mr-2" />;
    } else if (['txt', 'doc', 'docx'].includes(extension || '')) {
      return <img src={wordIcon} alt="Text Icon" className="w-10 h-10 mr-2" />;
    } else {
      return null;
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validateForm = () => {
    const newErrors = {
      companyName: !formData.companyName ? 'Please enter Company Name' : '',
      tradeName: !formData.tradeName
        ? 'Please enter your Trade Name, If differs from Registered name'
        : '',
      industryType: !formData.industryType ? 'Please select Industry Type' : '',
      contactPerson: !formData.contactPerson
        ? 'Please enter contact person name'
        : '',
      phoneNumber: !formData.phoneNumber
        ? 'Please enter your Phone Number'
        : !isPhoneValid(formData.phoneNumber)
          ? 'Please enter a valid Phone Number'
          : '',
      designation: !formData.designation ? 'Please enter your Designation' : '',
      email: !formData.email
        ? 'Please enter your email address'
        : !verifyEmailFormat(formData.email)
          ? 'Please enter a valid email address'
          : '',
      agree: !formData.agree
        ? 'You must agree to the Terms and Conditions and Privacy Policy'
        : '',
      website: validateUrl(formData.website),
      password: validatePassword(formData.password),
      confirmPassword: confirmPassword(
        formData.password,
        formData.confirmPassword
      ),
    };

    setFormErrors(newErrors);
    return newErrors;
  };

  useEffect(() => {
    if (click) {
      validateForm();
    }
  }, [click, formData]);

  const baseUrl = getBaseUrl();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setClick(true);
    console.log(formData);
    const errors = validateForm();
    if (Object.values(errors).some((error) => error)) {
      const firstError = Object.keys(errors).find(
        (key) => errors[key as keyof typeof errors]
      );
      if (firstError) {
        const ref = {
          companyName: companyNameRef,
          industryType: businessTypeRef,
          contactPerson: contactPersonRef,
          phoneNumber: phoneNumberRef,
          designation: designationRef,
          email: emailRef,
          password: passwordRef,
          confirmPassword: confirmPasswordRef,
          agree: agreeRef,
        }[firstError];
        ref?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
      setIsSubmitting(false);
      return;
    }
    try {
      setIsSubmitting(true);
      if (file) {
        const randomNumber = Math.floor(Math.random() * 1000000);
        const fileData = new FormData();
        fileData.append('image', file);
        fileData.append('folder_path', 'desktop/vacancy_info');
        fileData.append('file_name', `${formData.fileUpload}_${randomNumber}`);
        const fileUploadResponse = await fetch(`${baseUrl}upload`, {
          method: 'POST',
          body: fileData,
        });
        const fileUploadResult = await fileUploadResponse.json();

        if (!fileUploadResult) {
          throw new Error('File upload failed');
        } else {
          const apiResponse = await ClientRegistrationApi(
            formData.companyName,
            formData.tradeName,
            parseInt(formData.industryType),
            formData.website,
            formData.contactPerson,
            formData.designation,
            formData.email,
            formData.phoneNumber,
            formData.password,
            fileUploadResult.fileName,
            formData.social,
            formData.agree
          );
          if (apiResponse && apiResponse.data.client_profiles_id) {
            setSubmit(true);
            setClick(false);
            setFormData({
              companyName: '',
              tradeName: '',
              industryType: '',
              website: '',
              contactPerson: '',
              designation: '',
              email: '',
              phoneNumber: '',
              password: '',
              confirmPassword: '',
              fileUpload: '',
              social: '',
              agree: false,
              subscribe: false,
            });
            setFile(null);
          }
        }
      } else {
        const apiResponse = await ClientRegistrationApi(
          formData.companyName,
          formData.tradeName,
          parseInt(formData.industryType),
          formData.website,
          formData.contactPerson,
          formData.designation,
          formData.email,
          formData.phoneNumber,
          formData.password,
          formData.fileUpload,
          formData.social
        );
        if (apiResponse && apiResponse.data.client_profiles_id) {
          setSubmit(true);
          setClick(false);
          setFormData({
            companyName: '',
            tradeName: '',
            industryType: '',
            website: '',
            contactPerson: '',
            designation: '',
            email: '',
            phoneNumber: '',
            password: '',
            confirmPassword: '',
            fileUpload: '',
            social: '',
            agree: false,
            subscribe: false,
          });
        } else {
          console.error('Registration failed: ', apiResponse.errors);
          setSubmit(false);
          setNotification({
            show: true,
            title: 'Registration Failed',
            message: apiResponse.errors,
            type: 'error',
          });
        }
      }
    } catch (error) {
      if (error.message.includes('The email has already been taken.')) {
        setFormErrors({
          ...formErrors,
          email: 'The email has already been taken.',
        });
        emailRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const NavigateToCandidateRegistration = () => {
    navigate('/register');
  };

  const handleTopicClick = (selectedTopic) => {
    if (selectedTopic === 'Candidate') {
      NavigateToCandidateRegistration();
    } else {
      setSelectedTopic(selectedTopic);
    }
  };

  return (
    <div>
      <div className="lg:w-[900px] xl:w-[1054px] flex items-center shadow-md font-sans text-[20px] leading-[23px] lg:text-[26px] lg:leading-[32px] font-normal">
        <h1
          className={`w-1/2 text-center py-[14px] lg:py-5 ${
            selectedTopic === 'Candidate'
              ? 'bg-white '
              : 'bg-white rounded-tl-xl'
          } cursor-pointer`}
          onClick={() => handleTopicClick('Candidate')}
        >
          Candidate
        </h1>
        <h1
          className={`w-1/2 text-center py-[14px] lg:py-5 ${
            selectedTopic === 'Client'
              ? 'bg-gradient-to-r from-blue-500 to-blue-150 rounded-tr-xl text-white'
              : 'bg-white'
          } cursor-pointer`}
          onClick={() => handleTopicClick('Client')}
        >
          Employer
        </h1>
      </div>
      <div className="relative flex items-center justify-center">
        <form
          onSubmit={handleSubmit}
          className="lg:p-[40px] xl:p-[70px] lg:w-[900px] xl:w-[1054px] h-[1100px] shadow-all-sides-except-top bg-white rounded-b-xl"
          noValidate
        >
          {/* <div className="flex items-center justify-between mb-10">
            <div className="flex items-center gap-x-2 ">
              <div>
                <SocialMediaButton
                  type="button"
                  title="Register With Google"
                  variant="goggle-signIn-btn"
                  iconSrc={`${AWS_BASE_ASSETS_ICONS}google.png`}
                />
              </div>
              <div>
                <SocialMediaButton
                  type="button"
                  title=""
                  variant="other-signin-btn"
                  iconSrc={`${AWS_BASE_ASSETS_ICONS}Facebook.png`}
                />
              </div>
              <div>
                <SocialMediaButton
                  type="button"
                  title=""
                  variant="other-signin-btn"
                  iconSrc={`${AWS_BASE_ASSETS_ICONS}linkedin.png`}
                />
              </div>
            </div>
          </div> */}
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type="text"
                id="companyName"
                ref={companyNameRef}
                value={formData.companyName}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.companyName ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="Registered Company Name"
              />
              {formErrors.companyName && (
                <div className="form-error-msg">{formErrors.companyName}</div>
              )}
            </div>
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type="text"
                id="tradeName"
                value={formData.tradeName}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.tradeName ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="Trade Name"
              />
              {formErrors.tradeName && (
                <div className="form-error-msg">{formErrors.tradeName}</div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <IndustryTypeSelector
                onChange={(value) =>
                  setFormData({ ...formData, industryType: value })
                }
                error={formErrors.tradeName}
                title="Industry Type"
                resetSelector={submit}
              />
              {formErrors.industryType && (
                <div className="form-error-msg">{formErrors.industryType}</div>
              )}
            </div>
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type="text"
                id="website"
                value={formData.website}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.website ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="Website"
              />
              {formErrors.website && (
                <div className="form-error-msg">{formErrors.website}</div>
              )}
            </div>
            {/* <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type="text"
                id="businessNumber"
                value={formData.businessNumber}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.businessNumber ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="ABN"
              />
              {formErrors.businessNumber && (
                <div className="form-error-msg">
                  {formErrors.businessNumber}
                </div>
              )}
            </div> */}
          </div>
          <div className="border-t-2 border-dashed h-[40px]"></div>
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type="text"
                id="contactPerson"
                ref={contactPersonRef}
                value={formData.contactPerson}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.contactPerson ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="Official Contact Person"
              />
              {formErrors.contactPerson && (
                <div className="form-error-msg">{formErrors.contactPerson}</div>
              )}
            </div>
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type="text"
                id="designation"
                ref={designationRef}
                value={formData.designation}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.designation ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="Designation"
              />
              {formErrors.designation && (
                <div className="form-error-msg">{formErrors.designation}</div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type="email"
                id="email"
                ref={emailRef}
                value={formData.email}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.email ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="Email"
              />
              {formErrors.email && (
                <div className="form-error-msg">{formErrors.email}</div>
              )}
            </div>
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <PhoneInput
                forceDialCode={true}
                defaultCountry="nz"
                countrySelectorStyleProps={{
                  buttonStyle: {
                    zIndex: '9',
                    position: 'absolute',
                    left: '0px',
                    height: '38px',
                    width: '56px',
                    border: 'none',
                    backgroundColor: '#FFFFFF',
                  },
                }}
                onChange={(phone) =>
                  setFormData({ ...formData, phoneNumber: phone })
                }
                inputProps={{
                  id: 'phoneNumber',
                  autoComplete: 'off',
                  className: 'w-full relative pl-16 focus:outline-none',
                }}
                className={`form-container input-placeholder ${formErrors.phoneNumber ? 'outline-red-300' : 'outline-blue-250'}`}
                value={formData.phoneNumber}
              />
              {formErrors.phoneNumber && (
                <div className="form-error-msg absolute ">
                  {formErrors.phoneNumber}
                </div>
              )}
            </div>
          </div>
          <div className="border-t-2 border-dashed h-[40px]"></div>
          {/* <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type="text"
                id="website"
                value={formData.website}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.website ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="Website"
              />
              {formErrors.website && (
                <div className="form-error-msg">{formErrors.website}</div>
              )}
            </div>
          </div> */}
          <div className="flex items-center justify-between gap-6 mb-10">
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type={passwordVisible ? 'text' : 'password'}
                id="password"
                ref={passwordRef}
                value={formData.password}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.password ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="Password"
              />
              <div
                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? (
                  <img src={OpenEye} className="w-[23px] h-[23px]" />
                ) : (
                  <img src={CloseEye} className="w-[23px] h-[23px]" />
                )}
              </div>
              {formErrors.password && (
                <div className="form-error-msg">{formErrors.password}</div>
              )}
            </div>
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <input
                type="password"
                id="confirmPassword"
                ref={confirmPasswordRef}
                value={formData.confirmPassword}
                onChange={handleChange}
                className={`form-container input-placeholder ${formErrors.confirmPassword ? 'outline-red-300' : 'outline-blue-250'}`}
                autoComplete="off"
                placeholder="Confirm Password"
              />
              {formErrors.confirmPassword && (
                <div className="form-error-msg">
                  {formErrors.confirmPassword}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between mb-10">
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <div
                className="form-container outline-blue-250"
                onChange={handleFileChange}
              >
                <label
                  htmlFor="vacancyProfile"
                  className=" w-full cursor-pointer"
                >
                  <div className=" ">
                    {file ? (
                      <div className="flex items-center text-nowrap justify-center">
                        <div>{getIconForFileType(file.name)}</div>
                        <h1 className="form-text">{file.name}</h1>
                      </div>
                    ) : (
                      <p className="form-text mt-[6px]">
                        <div className="flex items-center justify-between">
                          <span className="">Upload Vacancy Profile</span>
                          <img
                            src={`${AWS_BASE_ASSETS_ICONS}CloudUploadFill.png`}
                            width={23}
                            height={23}
                          />
                        </div>
                      </p>
                    )}
                  </div>
                  <input
                    id="vacancyProfile"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </label>
              </div>
            </div>
            <div className="relative lg:min-w-[400px] xl:min-w-[442px]">
              <SocialMediaSelector
                onChange={(value) =>
                  setFormData({ ...formData, social: value })
                }
                resetSelector={submit}
              />
            </div>
          </div>

          <div className="mb-14 mt-16">
            <div className="mb-5 mt-10">
              <div className="relative">
                <input
                  type="checkbox"
                  id="agree"
                  checked={formData.agree}
                  onChange={handleChange}
                />
                <label
                  htmlFor="agree"
                  className={`relative ${
                    formErrors.agree
                      ? 'after:text-red-100'
                      : 'after:text-gray-900'
                  } after:content-['*'] after:ml-1  absolute top-0 left-3`}
                >
                  I Agree to the{' '}
                  <Link to={'/terms-and-conditions'} className="text-blue-220">
                    Term and Condition
                  </Link>{' '}
                  and{' '}
                  <Link to={'/privacy-policy'} className="text-blue-220">
                    Privacy policy
                  </Link>
                </label>
                {formErrors.agree && (
                  <div className="absolute text-red-100 text-sm ml-5">
                    {formErrors.agree}
                  </div>
                )}
              </div>
            </div>

            <div>
              <input
                type="checkbox"
                id="subscribe"
                ref={agreeRef}
                checked={formData.subscribe}
                onChange={handleChange}
                className=""
                required
              />
              <label htmlFor="subscribe" className="ml-2">
                Subscribe to Candidate alert
              </label>
            </div>
          </div>
          <div className="absolute bottom-[130px] right-[70px] flex flex-col items-center">
            <div className="w-[295px]">
              <SubmitButton
                type="submit"
                title="Register"
                variant="find-jobs-btn-candidate"
                isSubmitting={isSubmitting}
                disableVariant="find-jobs-btn-candidate-disabled"
                submitTitle="Registration in Progress"
              />
            </div>
            <p className="font-sans text-sm font-normal leading-[55px] text-gray-520">
              If You Have Account Please{' '}
              <Link to={'/client-sign-in'} className="text-blue-400">
                Login
              </Link>
            </p>
          </div>
          <div className="absolute bottom-[50px] right-[70px] left-[70px]">
            {allErrors && (
              <div className="w-full border p-3 rounded-[4px] text-center text-sm font-normal font-sans text-red-200 border-red-400 bg-red-500 bg-opacity-40">
                Please Enter Required Field above
              </div>
            )}
          </div>
        </form>
        {submit && (
          <div>
            <PopupNotification
              title={'Registered Successful'}
              message={
                'Thank you for registering with us. Please verify yourself by clicking on verification link on your mail.'
              }
              onClose={() => setSubmit(false)}
              type={'success'}
            />
          </div>
        )}
        {notification.show && (
          <PopupNotification
            title={notification.title}
            message={notification.message}
            onClose={() => setNotification({ ...notification, show: false })}
            type={notification.type}
          />
        )}
      </div>
    </div>
  );
}
