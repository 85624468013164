import { Button } from '@/components';
import applyNow from '@/assets/icons/ApplyNow.png';
import { useNavigate } from 'react-router-dom';

export default function PageNotFound() {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate('/');
  };
  return (
    <section className="padding-container my-20">
      <div className="text-center text-blue-550">
        <p className="font-sans font-[900] text-[200px] leading-[74px]">404</p>
        <p className="font-sans font-semibold text-[40px] leading-[74px] mt-20">
          Page Not Found
        </p>
        <p className="font-sans font-normal text-[24px] leading-[34px]">
          It seems that page you are looking for no longer exists.
        </p>
        <div className="w-[191px] mx-auto mt-10">
          <Button
            type={'button'}
            title={'Back Homepage'}
            variant={'apply-now-btn'}
            iconSrc={applyNow}
            handleClick={navigateToHome}
          />
        </div>
      </div>
    </section>
  );
}
