import { ReactNode } from 'react';

interface PageLayoutProps {
  children: ReactNode;
  bgColor?: string; // Optional background color
}

export default function PageLayout({ children, bgColor }: PageLayoutProps) {
  return (
    <section
      className={`max-width-container ${bgColor ? `bg-${bgColor}` : ''}`}
    >
      <div className="main-width-container">{children}</div>
    </section>
  );
}
