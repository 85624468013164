import { getBaseUrl } from '@/config/BaseUrl';

export default async function GetAllProfessionsApi() {
  try {
    const baseUrl = getBaseUrl();
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    };

    const response = await fetch(
      `${baseUrl}web/profession/get-all`,
      requestOptions
    );
    const responseData = await response.json();

    if (!response.ok) {
      throw { status: response.status, data: responseData };
    }

    return responseData;
  } catch (error) {
    console.error('Error getting professions: ', error);
    throw error;
  }
}
