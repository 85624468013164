import Button from '@/components/shared/button/Button';
import { AWS_BASE_URL_BLOG } from '@/config/seviceApiConfig';
import data from '@/data/blogs.json';
import { motion } from 'framer-motion';

export default function Blogs() {
  const blogsData = data;

  const handleNavigateToBlog = (url: string) => {
    window.open(url, '_blank');
  };

  const textVariant = {
    initial: {
      x: -80,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        staggerChildren: 0.2,
      },
    },
  };

  const bounceVariant = {
    initial: {
      y: 100,
      opacity: 0,
    },
    animate: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        delay: i * 0.2,
        type: 'spring',
        stiffness: 100,
      },
    }),
  };

  return (
    <motion.section className="padding-container">
      <motion.div
        variants={textVariant}
        initial="initial"
        whileInView="animate"
      >
        <motion.h2 className="section_header_text" variants={textVariant}>
          Latest Blogs
        </motion.h2>
      </motion.div>
      <div className="flex items-start gap-5">
        {blogsData.slice(0, 3).map((blog, index) => (
          <motion.div
            key={blog.title}
            className={
              index === 0
                ? 'w-1/2 relative cursor-pointer'
                : 'w-1/4 cursor-pointer'
            }
            custom={index}
            initial="initial"
            whileInView="animate"
            variants={bounceVariant}
            onClick={() => handleNavigateToBlog(blog.url)}
          >
            <div
              className={
                index === 0
                  ? 'lg:w-[auto] lg:h-[18.75rem] xl:h-[22.5rem] 2xl:h-[27.5rem] 4xl:w-[44.813rem] 4xl:h-[31.688rem]'
                  : '4xl:w-[22.063rem] 4xl:h-[17.313rem]'
              }
            >
              <img
                src={`${AWS_BASE_URL_BLOG}${blog.src}`}
                alt={`blog${index + 1}`}
                className="w-full h-full object-cover object-center rounded-lg"
                loading="lazy"
              />
            </div>
            <div
              className={
                index === 0
                  ? 'absolute lg:bottom-7 lg:left-5 2xl:bottom-20 2xl:left-10'
                  : 'w-5/6'
              }
            >
              <h3
                className={index === 0 ? 'main-blog-title' : 'sub-blog-title'}
              >
                {blog.title}
              </h3>
              <Button
                type="button"
                title="Read More"
                variant={index === 0 ? 'read-more-main' : 'read-more-sub'}
              />
            </div>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
}
