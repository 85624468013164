import { useState, useEffect } from 'react';
import GetAllIndustryTypesApi from '@/api/GetAllIndustryTypeApi';

interface IndustryTypesData {
  industry_type_id: number;
  name: string;
  description: string | null;
  status: number;
  created_by: string | null;
  updated_by: string | null;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
}

interface FetchIndustryTypes {
  allIndustryTypes: IndustryTypesData[] | null;
  IndustryTypesLoading: boolean;
  error: Error | null;
}

export default function useFetchIndustryTypes(): FetchIndustryTypes {
  const [allIndustryTypes, setAllIndustryTypes] = useState<
    IndustryTypesData[] | null
  >(null);
  const [IndustryTypesLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetAllIndustryTypesApi();
      setAllIndustryTypes(response);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { allIndustryTypes, IndustryTypesLoading, error };
}
