import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';
import { FIND_LOCATION_IMAGE } from '@/constants/findUs';
import BlurryLoadingImage from '../../../hooks/useFetchImage';

export default function FindUsComponent() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const location = useLocation();

  const settings: Settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: false,
    touchMove: true,
    appendDots: (dots: JSX.Element[]) => (
      <div className="slick-dots">
        {dots.map((dot, index) => (
          <div key={index} className="inline-block m-[2px] cursor-pointer">
            {dot}
          </div>
        ))}
      </div>
    ),
    customPaging: (i: number) => (
      <div
        className={`w-[0.688rem] h-[0.688rem] lg:w-[0.813rem] lg:h-[0.813rem] rounded-full mt-7 ${
          i === activeIndex ? 'bg-blue-400' : 'bg-gray-450'
        }`}
      />
    ),
    beforeChange: () => {
      const maxIndex = 2;
      setActiveIndex((oldIndex) => {
        const newIndex = oldIndex + 1;
        return newIndex > maxIndex ? 0 : newIndex;
      });
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <section className="mobile-padding-container">
      <Slider {...settings}>
        {FIND_LOCATION_IMAGE.map((item) => (
          <div key={item.id} className="">
            <BlurryLoadingImage
              preview={`${AWS_BASE_URL_ASSETS}/${item.image}`}
              image={`${AWS_BASE_URL_ASSETS}/${item.image}`}
              alt={`Location ${item.id}`}
              imageStyleClass="w-full h-auto"
              divStyleClass="banner-container-location"
              bgColor=""
            />
            <p className="mt-4 text-center text-base text-gray-620 w-[240px]">
              {item.address}
            </p>
          </div>
        ))}
      </Slider>
    </section>
  );
}
