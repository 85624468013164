import { Button, DropDownSection, HeroBackground2 } from '@/components';
import BenifitsOfRefer from '@/components/employerService/BenifitsOfRefer';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';
import useResponsive from '@/hooks/useResponsive';
import { MobileLayout, PageLayout } from '@/layout';
import { motion, useScroll, useTransform } from 'framer-motion';
import ReferBannerImg from '@/assets/images/Refer.jpeg';
import { useRef } from 'react';

export default function ReferAndEarn() {
  const { isMobile } = useResponsive();
  const ref1 = useRef();
  const { scrollYProgress: scrollYProgress1 } = useScroll({ target: ref1 });
  const y1 = useTransform(scrollYProgress1, [0, 1], [-100, 100]);
  return (
    <>
      {isMobile ? (
        <>
          <MobileLayout>
            {/* <DynamicMobileHero /> */}
            {/* <MobileDescription />
        <MobileAwardBanner />
        <DynamicJobPortal data={JobsData} />
        <MobileOfferDropDown data={OfferData} />
        <MobileRegister />
        <MobileInsight data={InsightData} /> */}
          </MobileLayout>
        </>
      ) : (
        <>
          <HeroBackground2
            title={'Refer and Earn with Medfuture'}
            description={
              'Unlock Rewards by Referring Talented Professionals to Medfuture'
            }
            icon={''}
            banner={`${AWS_BASE_URL_ASSETS}/International.jpeg`}
            bannerAlt={'bannerAlt'}
          />
          <PageLayout>
            <BenifitsOfRefer />
            <section className="padding-container">
              <div className="flex items-center justify-between gap-5">
                <div className="w-[728px] h-[438px]" ref={ref1}>
                  <img
                    src={ReferBannerImg}
                    className="object-cover"
                    alt="Permanent Recruitment Services"
                  />
                </div>
                <motion.div className="w-[607px]" style={{ y: y1 }}>
                  <h6 className="font-sans font-bold text-gray-800 text-[32px] leading-[44px] mb-6">
                    Why Refer to medfuture
                  </h6>
                  <p className="font-sans font-normal text-lg text-blue-350 mb-4">
                    <h1 className="mb-6">
                      <span className="font-bold">Competitive Rewards: </span>
                      Earn substantial rewards successful referrals.
                    </h1>
                    <h1 className="mb-6">
                      <span className="font-bold">Supportive Community:</span>{' '}
                      Help build a strong, talented team talented team and
                      contribute to a positive work environment.
                    </h1>
                    <h1 className="mb-6">
                      <span className="font-bold">Growth Opportunities:</span>{' '}
                      Be part of an organization that values professional growth
                      and innovation.
                    </h1>
                  </p>
                  <div className="w-[191px]">
                    <Button
                      type="button"
                      title="Refer a Friend"
                      variant="find-jobs-btn"
                    />
                  </div>
                </motion.div>
              </div>
            </section>
            <DropDownSection />
          </PageLayout>
        </>
      )}
    </>
  );
}
