export default function MobileFacility({ facility, index, userRegistered }) {
  const isAccessible = userRegistered || index < 2;
  return (
    <div
      className={`bg-white drop-shadow-lg text-center p-4 ${!isAccessible && 'blur'}`}
    >
      <div className="w-[55px] h-[55px] m-auto rounded-full my-4">
        {isAccessible && (
          <img
            src={facility.url}
            className="w-full h-full object-contain object-center"
          />
        )}
      </div>
      <h1
        className={`font-sans text-lg ${isAccessible ? 'text-blue-460' : 'text-gray-400'}`}
      >
        {facility.title}
      </h1>
    </div>
  );
}
