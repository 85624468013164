import JobFinder from '../../home/findJobs/JobFinder';

export default function MobileHero() {
  return (
    <section className="max-width-container bg-blue-500">
      <div className="mobile-container py-[1.125rem] md:py-8">
        <div className="mobile-padding-container">
          <h1 className="hero-text-main">
            Find your perfect job in the healthcare industry
          </h1>
          <div className="mt-6">
            <JobFinder />
          </div>
        </div>
      </div>
    </section>
  );
}
