import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GetAllProfessionsApi from '@/api/selectors/GetProfessionApi';

interface ProfessionData {
  banner_image: string;
  banner_image_alt: string;
  created_at: string;
  created_by: string;
  deleted_at: null;
  description: string;
  image_alt: string;
  name: string;
  pro_code: string;
  profession_id: number;
  status: number;
  updated_at: string;
  updated_by: string;
}

interface FetchAllProfessions {
  allProfessionsData: ProfessionData[] | null;
  professionDataLoading: boolean;
  error: Error | null;
}

export default function useFetchAllProfessions(): FetchAllProfessions {
  const navigate = useNavigate();
  const [allProfessionsData, setAllProfessionsData] = useState<
    ProfessionData[] | null
  >(null);
  const [professionDataLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetAllProfessionsApi();
      setAllProfessionsData(response);
      setError(null);
    } catch (error: any) {
      if (error.status === 429) {
        navigate('/many-requests', {
          state: { retryAfter: error.data.retry_after },
        });
      } else {
        setError(error as Error);
        setAllProfessionsData(null);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { allProfessionsData, professionDataLoading, error };
}
