import React, { FC, useState, useEffect } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import FeedbackComponent from './FeedbackComponent';
import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from 'swiper/modules';
import { Link } from 'react-router-dom';

SwiperCore.use([Navigation, Pagination, EffectCoverflow, Autoplay]);

interface FeedBackProps {
  data: { id: number; comment: string; user: string; rating: number }[];
}

const FeedBackCarousel: FC<FeedBackProps> = ({ data }) => {
  const slideOptions = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    loop: true,
    slidesPerView: 2.7,
    slidesPerGroup: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoplay: {
      delay: 6000,
    },
    coverflowEffect: {
      rotate: 15,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: false,
    },
    onSlideChange: (swiper: SwiperCore) => {
      const slides = document.querySelectorAll('.swiper-slide');
      slides.forEach((slide) =>
        slide.classList.remove('swiper-slide-centered')
      );
      if (swiper.slides[swiper.activeIndex]) {
        swiper.slides[swiper.activeIndex].classList.add(
          'swiper-slide-centered'
        );
      }
    },
    onSlideChangeTransitionEnd: (swiper: SwiperCore) => {
      swiper.update();
    },
  };

  useEffect(() => {
    const slides = document.querySelectorAll('.swiper-slide');
    if (slides[1]) {
      slides[1].classList.add('swiper-slide-centered');
    }
  }, []);

  return (
    <div className="my-5">
      <Swiper {...slideOptions} className="swiper_container">
        {data.map((feedback) => (
          <SwiperSlide key={feedback.id}>
            <FeedbackComponent feedback={feedback} />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <Link
        to={''}
        className="flex items-center justify-center mt-5 text-blue-400 text-lg"
      >
        View More...
      </Link> */}
    </div>
  );
};

export default FeedBackCarousel;
