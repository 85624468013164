import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Facebook from '/icons/fbIcon.png';
import MailIcon from '/icons/MailIcon.png';
import WhatsappIcon from '/icons/whatsappIcon.png';
import InstaIcon from '/icons/instaIcon.png';
import TwitterIcon from '/icons/TwitterIcon.png';
import shareIcon from '/icons/shareIcon.png';
import linkedinIcon from '/icons/linkedinIcon.png';

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  jobUrl: string;
}

const ShareModal: React.FC<ShareModalProps> = ({ isOpen, onClose, jobUrl }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(jobUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const encodedJobUrl = encodeURIComponent(jobUrl);
  const encodedMessage = encodeURIComponent('Check this out: ');

  const socialMediaLinks = [
    {
      name: 'Email',
      icon: MailIcon,
      url: `mailto:?body=${encodedMessage}${encodedJobUrl}`,
    },
    {
      name: 'WhatsApp',
      icon: WhatsappIcon,
      url: `https://api.whatsapp.com/send?text=${encodedMessage}${encodedJobUrl}`,
    },
    {
      name: 'Facebook',
      icon: Facebook,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodedJobUrl}`,
    },
    {
      name: 'Instagram',
      icon: InstaIcon,
      url: `https://www.instagram.com/?url=${encodedJobUrl}`,
    },
    {
      name: 'Twitter',
      icon: TwitterIcon,
      url: `https://twitter.com/intent/tweet?url=${encodedJobUrl}&text=${encodedMessage}`,
    },
    {
      name: 'LinkedIn',
      icon: linkedinIcon,
      url: `https://www.linkedin.com/shareArticle?url=${encodedJobUrl}`,
    },
    {
      name: 'Telegram',
      icon: shareIcon,
      url: `https://telegram.me/share/url?url=${encodedJobUrl}&text=${encodedMessage}`,
    },
  ];

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 z-20 flex items-center justify-center"
          onClick={onClose}
        >
          <div
            className="bg-white  border-2 border-white shadow-2xl p-8 rounded-md h-[210px] relative"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex items-center justify-between mb-2">
              <h2 className="text-xl font-bold">Share on Social Media</h2>
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  onClose();
                }}
                className="text-gray-500 hover:text-black text-2xl font-bold"
              >
                &times;
              </button>
            </div>
            <div className="mb-4 flex items-center justify-center gap-x-5">
              <input
                type="text"
                value={jobUrl}
                readOnly
                className="w-full p-2 border border-gray-300 rounded-md"
              />
              <button
                onClick={handleCopy}
                className="p-2 bg-blue-500 text-white rounded-md text-nowrap"
              >
                {copied ? 'Copied!' : 'Copy URL'}
              </button>
            </div>
            <div className="flex items-center justify-center gap-x-5 mb-5">
              {socialMediaLinks.map((link, index) => (
                <Link
                  to={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  className="social-icon w-12 h-12 rounded-full bg-white bg-opacity-50 flex items-center justify-center backdrop-blur-xl"
                >
                  <span className="flex items-center">
                    <img
                      src={link.icon}
                      alt={`${link.name} icon`}
                      className="w-8 h-8"
                    />
                  </span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareModal;
