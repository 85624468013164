import MobileDropDownItem from './MobileDropDownItem';

const MobileDropDown = ({ bottomMenuData }) => {
  return (
    <section className="mobile-padding-container">
      <h1 className="section_header_text">Jobs by Division</h1>

      <div>
        {bottomMenuData?.map((item, index) => (
          <MobileDropDownItem
            key={index}
            id={item.divisions_id}
            label={item.name}
            data={item.professions}
          />
        ))}
      </div>
    </section>
  );
};

export default MobileDropDown;
