import { Button } from '@/components';
import { useNavigate } from 'react-router-dom';

export default function ApiServerError() {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate('/');
  };
  return (
    <section className="padding-container my-20">
      <div className="text-center text-blue-500">
        <p className="font-sans font-[900] text-[200px] leading-[74px]">500</p>
        <p className="font-sans font-semibold text-[40px] leading-[74px] mt-20">
          Server error
        </p>
        <p className="font-sans font-normal text-[24px] leading-[34px]">
          The server encountered an internal error or misconfiguration and was
          unable to complete your request
        </p>
        <div className="w-[191px] mx-auto mt-10">
          <Button
            type={'button'}
            title={'Back Homepage'}
            variant={'apply-now-btn'}
            handleClick={navigateToHome}
          />
        </div>
      </div>
    </section>
  );
}
