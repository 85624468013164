export function validatePassword(password: string) {
  if (!password.trim()) {
    return 'Please enter your password';
  }

  const capitalRegex = /[A-Z]/;
  const symbolRegex = /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/;
  const numericRegex = /[0-9]/;

  if (password.length < 8 || password.length > 12) {
    return 'Password must be between 8 and 12 characters.';
  } else if (!capitalRegex.test(password)) {
    return 'Password must contain at least one capital letter.';
  } else if (!symbolRegex.test(password)) {
    return 'Password must contain at least one special character (!@#$%^&*()_+-=[]{};\\:\'\\"|,.<>\\/?).';
  } else if (!numericRegex.test(password)) {
    return 'Password must contain at least one numeric character.';
  }

  return '';
}
