import { useNavigate } from 'react-router-dom';

export default function MobileRegister() {
  const navigate = useNavigate();

  const navigateToRegister = () => {
    navigate('/register');
    window.scrollTo(0, 0);
  };
  return (
    <section className="mobile-padding-container my-8" id="register">
      <div className="flex items-center justify-center">
        <button className="register-btn-mobile" onClick={navigateToRegister}>
          <span>REGISTER</span>
        </button>
      </div>
    </section>
  );
}
