import { useAnimation } from 'framer-motion';
import { useEffect, useRef } from 'react';

const useScaleInView = (initialScale = 0.8, finalScale = 1, duration = 1.2) => {
  const controls = useAnimation();
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls.start({ scale: finalScale, transition: { duration } });
        } else {
          controls.start({ scale: initialScale });
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [controls, initialScale, finalScale, duration]);

  return [controls, ref];
};

export default useScaleInView;
