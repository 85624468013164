export const QUESTIONS = [
  {
    id: 1,
    question:
      'What types of medical and healthcare professionals can medfuture recruit for us?',
    answer:
      'From Hospitals to both public and private medical centres. General Practitioners, Allied Health Professionals – Physiotherapists, Occupational Therapists, Speech Pathologists, Psychologists, and Psychiatrists, Rural Nursing and Practice Nurses and even Specialists across various medical fields.',
  },
  {
    id: 2,
    question:
      'What are the medical professionals that medfuture is recruiting? ',
    answer:
      'Medfuture specializes in recruiting Hospital Doctors, General Practitioners and Specialists.',
  },
  {
    id: 3,
    question:
      'What are the allied health professionals that medfuture is recruiting?',
    answer:
      'Even though we mainly target Occupational Therapists, Speech Pathologists, Physiotherapists and Podiatrists, we also target Exercise Physiologists, Dieticians, and other professions that falls under the AHP category.  ',
  },
  {
    id: 4,
    question:
      'What are the nursing professionals that medfuture is recruiting? ',
    answer:
      'Medfuture recruits Rural Nursing Practitioners, Practice Nurses, Aged Care Nurses, Community Health Nurses.',
  },
  {
    id: 5,
    question: 'Does Medfuture provide temporary staffing solutions?',
    answer:
      'Yes, medfuture provides temporary staffing solutions with flexible options to meet all of your short-term staffing needs.',
  },
  {
    id: 6,
    question: 'How does the temporary staffing solution work? ',
    answer:
      'It works in two (2) ways. Employees can select and hire from our pre-screened pool of healthcare professionals or employers can provide specific requirements, and our team will then match candidates from our extensive database to meet those requirements of yours. ',
  },
];
