import {
  BannerComponent,
  DropDownSection,
  DynamicMobileHero,
  HeroBackground2,
  InsightComponent,
} from '@/components';
import EmployerBlogCards from '@/components/employerService/EmployerBlogCards';
import MedfutureBenefits from '@/components/employerService/MedfutureBenefits';
import EmployerServiceRegistration from '@/components/forms/employerService/EmployerServiceRegistration';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';
import useResponsive from '@/hooks/useResponsive';
import { MobileLayout, PageLayout } from '@/layout';

export default function EmployerService() {
  const { isMobile } = useResponsive();

  return (
    <>
      {isMobile ? (
        <>
          <MobileLayout>
            {/* <DynamicMobileHero /> */}
            {/* <MobileDescription />
        <MobileAwardBanner />
        <DynamicJobPortal data={JobsData} />
        <MobileOfferDropDown data={OfferData} />
        <MobileRegister />
        <MobileInsight data={InsightData} /> */}
          </MobileLayout>
        </>
      ) : (
        <>
          <HeroBackground2
            title={'Empowering Healthcare Employers'}
            description={
              'Discover unparalleled medical recruitment solutions tailored to meet the unique needs of healthcare employers.'
            }
            icon={''}
            banner={`${AWS_BASE_URL_ASSETS}/EmployerService.png`}
            bannerAlt={'bannerAlt'}
          />
          <BannerComponent />
          <PageLayout>
            <div className="text-center font-sans font-normal text-[33px] lg:text-[24px] leading-[48px] lg:leading-[36px] text-blue-350 padding-container">
              Medfuture offers specialised medical recruitment services for
              healthcare employers, including permanent, temporary, and
              international staffing solutions. Optimise your workforce with our
              dedicated recruitment expertise.
            </div>
            <EmployerBlogCards
              title1={'Permanent Recruitment Services'}
              paragraph1={
                'Secure the best talent for your organization with our permanent recruitment services. We specialize in placing highly qualified professionals across multiple industries, ensuring long-term success and stability for your healthcare facility.'
              }
              url1={'/permanent-recruitment'}
              banner1={'EMPBlog1.png'}
              title2={'Temporary Staffing Solutions'}
              paragraph2={
                'Manage your workforce effectively with our temporary staffing solutions. From healthcare talent pool management to urgent staffing needs, we provide flexible and reliable solutions to keep your operations running smoothly.'
              }
              url2={'/temporary-recruitment'}
              banner2={'EMPBlog2.png'}
              title3={'International Medical Recruitment'}
              paragraph3={
                'Expand your talent search globally with our international medical recruitment services. We assist in recruiting international medical graduates (IMGs) and specialist medical graduates (SIMGs) to bring diverse expertise and skills to your healthcare team.'
              }
              banner3={'EMPBlog3.png'}
              url3={'/international-recruitment'}
            />
            <EmployerServiceRegistration />
            <MedfutureBenefits />
            {/* <DropDownSection /> */}
            {/* <InsightComponent /> */}
          </PageLayout>
        </>
      )}
    </>
  );
}
