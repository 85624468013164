import { HeroBackground } from '@/components';
import UserSignIn from '@/components/mainMenu/UserSignIn';
import { PageLayout } from '@/layout';

export default function CandidateSignIn() {
  return (
    <>
      <HeroBackground title={'Login to your candidate profile'} />
      <PageLayout>
        <div className="flex items-center justify-center h-[900px]">
          <UserSignIn />
        </div>
      </PageLayout>
    </>
  );
}
