import MainFilterComponent from '@/components/filter/MainFilterComponent';
import useFetchAllState from '@/components/forms/selectors/hook/useFetchAllStates';
import useFetchBottomMenuData from '@/components/navbar/hook/useBottomNavBar';
import useFetchAllStateDetailsByCountry from '@/hooks/useFetchAllStateDetailsByCountry';
import { useState } from 'react';

interface OffCanvasProp {
  isOpen: boolean;
}

export default function OffCanvas({ isOpen, filterMenuData }: OffCanvasProp) {
  const [selectedTopic, setSelectedTopic] = useState<Topic>('Permanent');
  const { bottomMenuData, loading, error } = useFetchBottomMenuData();
  const { allStates } = useFetchAllState();
  const { allStatesDetails, allStateLoading } =
    useFetchAllStateDetailsByCountry();

  const handleTopicClick = (topic: Topic) => {
    setSelectedTopic(topic);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div
      className={`absolute inset-0 z-[8] bg-gray-900 bg-opacity-50 ${isOpen ? 'block' : 'hidden'}`}
    >
      <div>
        <div className="flex items-center justify-between">
          <div className="w-[370px] lg:w-[480px] flex items-center shadow-md font-sans text-[20px] leading-[23px] lg:text-[26px] lg:leading-[32px] font-normal">
            <h1
              className={`w-1/2 text-center py-[14px] lg:py-5 ${
                selectedTopic === 'Permanent' ? 'bg-white' : 'bg-gray-420'
              } cursor-pointer`}
              onClick={() => handleTopicClick('Permanent')}
            >
              Permanent
            </h1>
            <h1
              className={`w-1/2 text-center py-[14px] lg:py-5 ${
                selectedTopic === 'Locum' ? 'bg-white' : 'bg-gray-420'
              } cursor-pointer`}
              onClick={() => handleTopicClick('Locum')}
            >
              Locum
            </h1>
          </div>
        </div>
        <div className="flex items-center justify-start">
          {selectedTopic === 'Permanent' ? (
            <PermanentJobFilter
              data={filterMenuData}
              allStates={allStatesDetails}
              // professionId={professionId}
              // stateId={stateId}
              // specialtyId={specialtyId}
              // seniorityId={seniorityId}
              // onProfessionChange={handleProfessionChange}
              // onSpecialtyChange={handleSpecialtyChange}
              // onSeniorityChange={handleSeniorityChange}
              // onStateChange={handleStateChange}
              // onSuburbSelect={handleSuburbChange}
              // suburbId={suburbId}
              // regionId={regionId}
              // divisionId={divisionId}
              // onDivisionChange={handleDivisionChange}
              // onRegionChange={handleRegionChange}
            />
          ) : (
            <LocumJobFilter
              data={filterMenuData}
              allStates={allStatesDetails}
              // professionId={professionId}
              // stateId={stateId}
              // specialtyId={specialtyId}
              // seniorityId={seniorityId}
              // onProfessionChange={handleProfessionChange}
              // onSpecialtyChange={handleSpecialtyChange}
              // onSeniorityChange={handleSeniorityChange}
              // onStateChange={handleStateChange}
              // onSuburbSelect={handleSuburbChange}
              // suburbId={suburbId}
              // regionId={regionId}
              // divisionId={divisionId}
              // onDivisionChange={handleDivisionChange}
              // onRegionChange={handleRegionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}
