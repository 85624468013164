import { MobileNavbar } from '@/components';

export default function MobileHeaderLayout() {
  return (
    <section className="sticky top-0 z-10">
      <nav className="max-width-container bg-blue-500">
        <div className="mobile-container">
          <MobileNavbar />
        </div>
      </nav>
    </section>
  );
}
