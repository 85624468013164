import { Footer } from '@/components';
import useFooterBackgroundColor from '@/hooks/useBackGroundColor';

export default function FooterLayout() {
  const footerBackgroundColor = useFooterBackgroundColor();

  return (
    <section className={`max-width-container ${footerBackgroundColor}`}>
      <div className="main-width-container">
        <Footer />
      </div>
    </section>
  );
}
