import SocialMediaButton from '../shared/button/SocialMediaBtn';
import Button from '../shared/button/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { verifyEmailFormat } from '@/utils/EmailVerification';
import { validatePassword } from '@/utils/PasswordValidationUtil';
import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import { ProfessionSelector } from '../forms/selectors/ProfessionSelector';
import CandidateLoginApi from '@/api/Registration/CandidateLoginApi';

export default function UserSignIn() {
  const navigate = useNavigate();
  const [submit, setSubmit] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e: { target: { id: string; value: string } }) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const allErrors = formErrors.email || formErrors.password;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
    if (!formData.email || !formData.password) {
      const newErrors = {
        email: !formData.email
          ? 'Please enter your email address'
          : !verifyEmailFormat(formData.email)
            ? 'Please enter a valid email address'
            : '',
        password: !formData.password
          ? 'Please enter your password'
          : validatePassword(formData.password),
      };
      setFormErrors(newErrors);
      setTimeout(
        () =>
          setFormErrors({
            email: '',
            password: '',
          }),
        3000
      );
    }
    try {
      const apiResponse = await CandidateLoginApi(
        formData.email,
        formData.password
      );
      console.log(apiResponse);
      if (apiResponse) {
        setSubmit(true);
        setFormData({
          email: '',
          password: '',
        });
      }
    } catch (error) {
      console.error('Error during registration: ', error);
    }
  };

  const handleNavigateToClient = () => {
    navigate('/client-sign-in');
  };

  return (
    <div className="absolute top-[670px] ">
      <form
        onSubmit={handleSubmit}
        className="relative bg-white grid grid-cols-1 w-[629px] h-auto rounded-md  drop-shadow-lg p-16"
        noValidate
      >
        <div
          className="flex items-center justify-between mb-10 cursor-pointer"
          onClick={handleNavigateToClient}
        >
          <h1 className="font-sans font-normal text-[38px] leading-[44px] text-black text-start">
            Login
          </h1>
          <p className="p-2 border border-blue-400 rounded-full px-6 bg-blue-85 text-blue-400 font-medium">
            Login as Client
          </p>
        </div>
        <div className="flex items-center gap-x-3 mb-6">
          <div>
            <SocialMediaButton
              type="button"
              title="Sign in with Google"
              variant="goggle-signIn-btn"
              iconSrc={`${AWS_BASE_ASSETS_ICONS}google.png`}
            />
          </div>
          <div>
            <SocialMediaButton
              type="button"
              title=""
              variant="other-signin-btn"
              iconSrc={`${AWS_BASE_ASSETS_ICONS}Facebook.png`}
            />
          </div>
          <div>
            <SocialMediaButton
              type="button"
              title=""
              variant="other-signin-btn"
              iconSrc={`${AWS_BASE_ASSETS_ICONS}linkedin.png`}
            />
          </div>
        </div>
        <p className="text-gray-900 text-base font-sans font-normal">
          Profession
        </p>
        <div className="relative mb-6 mt-4">
          <ProfessionSelector
            onChange={(value) =>
              setFormData({ ...formData, profession: value })
            }
          />
        </div>
        <p className="text-gray-900 text-base font-sans font-normal">
          Enter your username or email address
        </p>
        <div className="relative mb-6 mt-2">
          <input
            autoComplete="off"
            type="text"
            id="email"
            value={formData.email}
            name="username"
            className={`signIn-form-container ${formErrors.email ? 'outline-red-300' : 'outline-blue-250'}`}
            onChange={handleChange}
          />
          {formErrors.email && (
            <div className="form-error-msg">{formErrors.email}</div>
          )}
        </div>
        <p className="text-gray-900 text-base font-sans font-normal">
          Enter your Password
        </p>
        <div className="relative mb-6 mt-2">
          <input
            type="password"
            id="password"
            value={formData.password}
            name="password"
            className={`signIn-form-container ${formErrors.password ? 'outline-red-300' : 'outline-blue-250'}`}
            onChange={handleChange}
          />
          {formErrors.password && (
            <div className="form-error-msg">{formErrors.password}</div>
          )}
          <Link
            to={''}
            className="absolute bottom-[-28px] text-nowrap right-0 font-sans font-normal text-[13px] leading-[18px] text-blue-620 "
          >
            Forget Password
          </Link>
        </div>

        <div className="drop-shadow-lg mt-6">
          <Button type="submit" title="Login" variant="find-jobs-btn" />
          <p className="font-sans text-[13px] text-center font-normal leading-[55px] text-gray-520">
            No Account?{' '}
            <Link to={'/register'} className="text-blue-400">
              Register
            </Link>
          </p>
        </div>
        <div className="absolute bottom-[30px] right-[70px] left-[70px]">
          {allErrors && (
            <div className="w-full border p-3 rounded-[4px] text-center text-sm font-normal font-sans text-red-200 border-red-400 bg-red-500 bg-opacity-40">
              Please Enter Required Field above
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
