import { useEffect } from 'react';
import JobFinder from '@/components/home/findJobs/JobFinder';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';
import { motion, useAnimation } from 'framer-motion';

const Hero = () => {
  const controls = useAnimation();

  const textVariant = {
    initial: {
      x: -500,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.2,
      },
    },
  };

  useEffect(() => {
    controls.start({
      scale: [0.8, 1.5],
      transition: {
        repeat: Infinity,
        repeatType: 'mirror',
        duration: 8,
        ease: 'easeInOut',
      },
    });
  }, [controls]);

  return (
    <section className="hero-section max-width-container bg-blue-500 relative overflow-hidden">
      <div className="main-width-container">
        <div className="flex items-center justify-between h-[600px]">
          <motion.div
            variants={textVariant}
            initial="initial"
            animate="animate"
          >
            <motion.h1
              className="hero-text-main w-[600px] 2xl:w-[700px] 4xl:w-[750px] font-sans"
              variants={textVariant}
            >
              Find your perfect job{' '}
              <motion.span
                className="hero-text-main w-full"
                variants={textVariant}
              >
                in the healthcare industry
              </motion.span>
            </motion.h1>
            <motion.div className="mt-5 xl:mt-10" variants={textVariant}>
              <JobFinder />
            </motion.div>
            <div className="hidden lg:flex items-center justify-between w-[600px] h-[105px]">
              {/* <HeroStatus /> */}
            </div>
          </motion.div>
          <div className="relative">
            <motion.div
              className="hero-img-container relative"
              initial={{ scale: 0.8 }}
              animate={controls}
            >
              <img
                src={`${AWS_BASE_URL_ASSETS}/award.png`}
                width={260}
                height={220}
                alt="medfuture award 1"
                loading="lazy"
                className="rounded-full object-cover object-center"
              />
            </motion.div>
            <div className=" h-[105px]">{/* <HeroStatus /> */}</div>
          </div>
        </div>
      </div>
      {/* <div className="waves"></div> */}
    </section>
  );
};

export default Hero;
