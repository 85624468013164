import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';

export default function HeroBackground3() {
  const controls = useAnimation();

  useEffect(() => {
    const animation = controls.start({
      scale: [1, 1.08, 1],
      transition: {
        duration: 10,
        ease: 'easeInOut',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    });
  }, [controls]);

  return (
    <section className="max-width-container bg-background-image bg-cover bg-center  lg:h-[600px] 2xl:h-[700px] 4xl:h-[747px] w-screen">
      <div className="absolute top-[144px] inset-0 bg-gradient-to-br from-[#07121E]  to-[#010d18] opacity-70 pointer-events-none lg:h-[600px] 2xl:h-[700px] 4xl:h-[747px]"></div>
      <div className="relative  w-[800px] h-[500px] mt-3 xl-w-[900px] xl:h-[500px] 2xl:w-[1150px] 2xl:h-[600px] xl:mt-6">
        <div className="h-[535px]">
          <motion.img
            src={`${AWS_BASE_URL_ASSETS}/Map.png`}
            alt="background"
            className="absolute inset-0 object-contain w-full h-full"
            animate={controls}
            initial={{ scale: 1 }}
          />
        </div>
        <h1 className="absolute common-hero-main bottom-[-70px] left-[20%] text-nowrap">
          We’d Love to Hear from You
        </h1>
      </div>
    </section>
  );
}
