import { useState, useEffect } from 'react';
import BannerData from '@/data/banner.json';

interface FilterData {}

interface FetchAllJobs {
  data: FilterData | null;
  loading: boolean;
  error: Error | null;
}

export default function useFetchBanner(): FetchAllJobs {
  const [data, setData] = useState<FilterData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      // const response = await fetch('');
      // const data = await response.json();
      setData(BannerData);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error };
}
