import { PERMANENT_CONTACT_INFORMATION } from '@/constants/ContactInformation';
import { useState } from 'react';
import Slider, { Settings } from 'react-slick';

export default function HealthcareProfessional() {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const settings: Settings = {
    dots: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    touchMove: true,
    variableWidth: true,
    appendDots: (dots: JSX.Element[]) => (
      <ul className="slick-dots">
        {dots.slice(0, 3).map((dot, index) => (
          <li key={index} className="inline-block m-2 cursor-pointer">
            {dot}
          </li>
        ))}
      </ul>
    ),
    customPaging: (i: number) => (
      <div
        className={`w-[0.688rem] h-[0.688rem] lg:w-[0.813rem] lg:h-[0.813rem] rounded-full mt-7 ${i === activeIndex ? 'bg-blue-400' : 'bg-gray-450'}`}
      />
    ),
    beforeChange: () => {
      const maxIndex = 2;
      //const maxIndex = Math.ceil(data.length / settings.slidesToShow) - 1;
      setActiveIndex((oldIndex) => {
        const newIndex = oldIndex + 1;
        return newIndex > maxIndex ? 0 : newIndex;
      });
    },
  };

  return (
    <div className="my-10">
      <Slider {...settings}>
        {PERMANENT_CONTACT_INFORMATION.map((company) => (
          <div className="group cursor-pointer w-[290px] bg-white relative overflow-hidden shadow-lg rounded-[4px] transition duration-500 ease-in-out">
            <div className="p-3 relative z-[4]">
              <h3 className="font-sans font-normal text-base leading-[26px] text-blue-700 mb-3 group-hover:text-white transition duration-300 ease-in-out">
                {company.name}
              </h3>
              <p className="font-sans font-normal text-base group-hover:text-white transition duration-300 ease-in-out">
                <strong>Call:</strong> {company.call}
              </p>
              <p className="font-sans font-normal  group-hover:text-white transition duration-300 ease-in-out">
                <strong>Email:</strong>{' '}
                <a
                  href={`mailto:${company.email}`}
                  className="text-blue-700 group-hover:text-white transition duration-300 ease-in-out"
                >
                  {company.email}
                </a>
              </p>
              <a
                href={company.link}
                className="text-blue-400 text-xs group-hover:text-white transition duration-300 ease-in-out"
              >
                Website link
              </a>
            </div>
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-blue-150 origin-left transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700 ease-in-out"></div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
